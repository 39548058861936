.manage_content {
  position: relative;
  width: 6.74rem;
  height: auto;
  min-height: 8rem;
  padding: .4rem .44rem;
  margin: .4rem auto;
  box-sizing: border-box;
  background-color: var(--white);
  border-radius: .08rem;

  .title1>span {
    font-size: .26rem;
    line-height: .5rem;
    color: var(--black4);
    font-family: "Black";
    text-align: left;
  }

  .title1>div {
    margin: .2rem auto .2rem;
  }

  .date_select {
    position: relative;
    display: flex;
    align-items: center;

    &.date_select_between {
      justify-content: space-between;
    }

    >span {
      font-size: .3rem;
      margin: 0 .1rem;
    }

    >div {
      width: 1.5rem;
      font-size: 0.18rem;
    }

    :global {
      .ant-picker-input>input {
        font-size: 0.18rem;
      }

      .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector::after,
      .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item,
      .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-placeholder {
        line-height: 1.56;
      }

      .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
        // height: 0.57rem;
        padding: 4px 11px;
      }

      .ant-select-single.ant-select-lg:not(.ant-select-customize-input):not(.ant-select-customize-input) .ant-select-selection-search-input {
        height: 0.46rem;
      }

      .ant-select-single.ant-select-show-arrow .ant-select-selection-search {
        height: 0.46rem;
      }

      .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
        height: auto;
      }

      .ant-select-clear {
        opacity: 0;
        z-index: 9;
        width: 0.24rem;
        height: 0.24rem;
        font-size: 0.24rem;
        margin-top: -0.12rem;
      }
    }

    .clear {
      position: absolute;
      right: 0;
      top: 0;
      margin: 0;
      height: 100%;
      width: 0.48rem;

      > * {
        opacity: 1;
      }
    }
  }

  .content_data {
    position: relative;
    width: 100%;
    height: auto;

    .data_list {
      width: 100%;
      height: auto;
      margin-top: 0.2rem;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      li {
        position: relative;
        width: 49%;
        height: auto;
        padding-left: .2rem;
        box-sizing: border-box;

        &:nth-child(2n - 1)::after {
          content: "";
          position: absolute;
          right: 0;
          top: 50%;
          width: 1px;
          height: 1.12rem;
          margin-top: -.6rem;
          background-color: var(--gray21);
        }
      }

      .content_subtitle {
        display: inline-block;
        height: 0.56rem;
        font-size: .22rem;
        line-height: .28rem;
        color: var(--black4);
        font-family: "Black";
        text-transform: uppercase;
      }

      .num {
        font-size: .5rem;
        line-height: .56rem;
        color: var(--yellow13);
        font-family: "Bold";
      }

      .bite {
        display: inline-block;
        font-size: .18rem;
        color: var(--red4);
        line-height: .28rem;
        font-family: "Bold";
        padding: 0 .18rem;
        box-sizing: border-box;
        background-color: var(--gray22);
        border-radius: .28rem;
        vertical-align: top;
        margin-top: .18rem;
      }
    }
  }

  .query {
    position: relative;
    width: 100%;

    input {
      display: block;
      width: 100%;
      height: .46rem;
      line-height: .46rem;
      font-size: .18rem;
      color: var(--black3);
      font-family: "Regular";
      padding: 0 .56rem 0 .2rem;
      box-sizing: border-box;
      border-radius: .46rem;
      border: 1px #737373 solid;
      background: none;
    }

    i {
      position: absolute;
      width: .23rem;
      height: .23rem;
      right: .20rem;
      top: 50%;
      margin-top: -.11rem;
      cursor: pointer;
      background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAXCAMAAADX9CSSAAAApVBMVEUAAAA7Ozs9PT08PDw8PDw8PDw7Ozs9PT09PT08PDwyMjI6Ojo/Pz89PT09PT0+Pj48PDw5OTk8PDw9PT09PT01NTU8PDw6Ojo+Pj4+Pj45OTk8PDw9PT06OjpAQEA8PDw9PT06OjpEREQ8PDw+Pj46Ojo5OTksLCwzMzMuLi45OTlBQUE+Pj4+Pj43Nzc4ODg+Pj4+Pj42NjY9PT0+Pj5AQEBEREROboehAAAAM3RSTlMAG/z36scY2dTDEAjoq6aak4Ytt4t8V0RANCQiFQv75uLVz8y/s7OqnJSQjHVtZFNPPSQtBXkYAAAA1UlEQVQoz3WPV3LDMBBDWUR1R8VxSWyn954A1P2PlhGlKKTHxgex80AOscJJLoyGzt5iEeiUBNAfjUfzG8t6tS3iVQXe5RNPOiPH8Tzl7R+ubOY9Tu3LeIUXhcelwvD5U9eEHezCuWZY7ozXznkpQinsekO2xyNKx5M9rpk7ziLAP4ycz/kR8HdbO1+jDHiJdhhM9+zhx+5+nDbKvk64tvp/E8X0q++wXSYEqinYGBJplgCYr68wE5OWDyCpZp9CyNIPxK79bodF4ggn4pDk8YBHAtP8AuK3E9qVr3m9AAAAAElFTkSuQmCC) no-repeat center/contain;
    }
  }

  .media_list {
    position: relative;
    white-space: nowrap;
  }

  .update {
    position: absolute;
    right: 0.18rem;
    top: 0.05rem;
    width: .35rem;
    height: .35rem;
    background-color: var(--gray19);
    cursor: pointer;
    border-radius: .05rem;

    &::after {
      content: '';
      position: absolute;
      width: .19rem;
      height: .19rem;
      top: 50%;
      left: 50%;
      margin-top: -.09rem;
      margin-left: -.09rem;
      background: url(~@/assets/images/update.png) no-repeat center/contain;
    }

    &.on::after {
      animation: router_update 2s linear infinite;
    }
  }

  .manage_list {
    width: 100%;
    height: auto;
    margin-top: .24rem;
    padding-bottom: .50rem;
    box-sizing: border-box;
    text-align: left;

    li {
      position: relative;
      width: 100%;
      height: 1.82rem;
      padding: .48rem 0 0.24rem;
      box-sizing: border-box;
      border-bottom: 1px var(--gray19) solid;
    }

    .img_box {
      float: left;
      height: 1.1rem;
      width: 2.07rem;
      margin-right: .38rem;
      border-radius: .1rem;
      overflow: hidden;
      border: 1px var(--black1) solid;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .title,
    .pushTime {
      display: inline-block;
      width: 3.31rem;
      height: .4rem;
      height: auto;
      font-size: .18rem;
      line-height: .20rem;
      color: var(--black3);
      font-family: "Regular";
      margin-bottom: .1rem;
      box-sizing: border-box;
    }

    .select_box {
      position: absolute;
      right: 0.18rem;
      top: 0.4rem;

      >div {
        font-size: .16rem;
      }

      :global {


        .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector::after,
        .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item,
        .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-placeholder {
          line-height: 1.56;
        }

        .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
          // height: 0.57rem;
          padding: 4px 11px;
        }

        .ant-select-single.ant-select-lg:not(.ant-select-customize-input):not(.ant-select-customize-input) .ant-select-selection-search-input {
          height: 0.46rem;
        }

        .ant-select-single.ant-select-show-arrow .ant-select-selection-search {
          height: 0.46rem;
        }

        .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
          height: auto;
        }
      }
    }

    .title {
      position: absolute;
      top: 0.08rem;
      left: 0;
      font-size: .24rem;
      line-height: .32rem;
      width: 100%;
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .pushTime {
      color: var(--gray11);
      font-size: .18rem;
    }

    .desc {
      font-size: .16rem;
      line-height: .2rem;
      margin-top: 0.15rem;
      color: var(--gray11);
      font-family: "Regular";

      span {
        display: inline-block;
        min-width: 1rem;
        margin-right: .10rem;
      }

      .createTime {
        color: var(--black3);
        font-size: .2rem;
      }

      >span:nth-child(1) {
        position: absolute;
        left: 2.45rem;
        bottom: .24rem;
      }

      .time {
        color: var(--dark3);
      }

      i {
        display: inline-block;
        margin-right: .06rem;
      }

      .view {
        width: .22rem;
        height: .20rem;
        vertical-align: bottom;
        background: url(~@/assets/images/eyes.png) no-repeat bottom/cover;
      }

      .youtube_view {
        display: none;
      }

      .like {
        width: .21rem;
        height: .17rem;
        vertical-align: top;
        background: url(~@/assets/images/like.png) no-repeat center/contain;
      }
      
      .youtube_like {
        width: .21rem;
        height: .17rem;
        vertical-align: top;
        background: url(~@/assets/images/youtube_like.svg) no-repeat center/contain;
        background-size: .60rem auto;
      }

      .comm {
        width: .21rem;
        height: .19rem;
        vertical-align: top;
        background: url(~@/assets/images/comm.png) no-repeat center/contain;
      }
    }

    .update {
      position: absolute;
      right: .75rem;
      bottom: .15rem;
      width: .35rem;
      height: .35rem;
      background: url(~@/assets/images/update.png) no-repeat center/contain;
      cursor: pointer;
    }

    .more {
      position: absolute;
      right: .18rem;
      bottom: .15rem;
      width: .35rem;
      height: .35rem;
      background: url(~@/assets/images/more.png) no-repeat center/contain;
      cursor: pointer;

      &:hover,
      :focus {
        .more_item {
          display: block;
        }
      }
    }

    .more_item {
      display: none;
      position: absolute;
      left: 0;
      top: .30rem;
      padding-top: .10rem;
      box-sizing: border-box;

      >div {
        width: .85rem;
        height: .34rem;
        font-size: .12rem;
        line-height: .34rem;
        color: var(--white);
        font-family: "Black";
        text-align: center;
        border-radius: .05rem;
        cursor: pointer;
        background-color: var(--gray19);
      }
    }
  }

  .page {
    position: relative;
    width: 100%;
    text-align: center;
    font-size: 0;
  }

  .upLoad_tip {
    width: 4.57rem;
    height: 5rem;
    margin: 0 auto;
    cursor: pointer;
    background: url(~@/assets/images/upload_tip.png) no-repeat center/contain;
    background-size: 4rem auto;
  }

  .load_box {
    width: 100%;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.detail_box {
  width: 6.74rem;
}

@keyframes router_update {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}