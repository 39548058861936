.admin_nav {
  position: sticky;
  width: 100%;
  height: 1.11rem;
  left: 0;
  bottom: 0;
  background-color: var(--white);
  z-index: 99;

  ul {
    width: 100%;
    height: auto;
    display: flex;
  }

  li {
    position: relative;
    flex: 1;
    height: 1.11rem;
    text-align: center;

    a {
      display: block;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: .2rem;
      color: var(--gray17);
      font-family: "Black";
    }

    i {
      position: absolute;
      width: 0.08rem;
      height: 0.08rem;
      top: 0.12rem;
      right: 0.12rem;
      border-radius: 50%;
      background-color: #F00;
    }
  }

  li.active a {
    color: var(--white);
    background-color: var(--yellow3);
  }

  li:nth-child(1) {
    border-right: 1px #d8d8d8 solid;
  }

  li:last-child {
    border-left: 1px #d8d8d8 solid;
  }

  li.more {
    position: relative;
    background-color: var(--gray5);
    
    &::before, &::after {
      content: "";
      position: absolute;
      width: .06rem;
      height: .58rem;
      top: 50%;
      left: 50%;
      margin-top: -.29rem;
      margin-left: -.03rem;
      border-radius: .06rem;
      background-color: var(--gray18);
    }

    &::after {
      transform: rotate(90deg);
    }
  }
}