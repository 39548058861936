.information_content {
  position: relative;
  width: 100%;
  height: auto;
  padding-bottom: 200px;
  box-sizing: border-box;
  min-height: calc(100vh - 100px);
  background-color: var(--gray16);
  // overflow: hidden;
  text-align: center;

  .title {
    width: 1350px;
    font-size: 56px;
    color: var(--black4);
    line-height: 56px;
    font-family: "Black";
    margin: 80px auto 60px;
    text-transform: uppercase;
  }

  .content_1 {
    width: 1350px;
    height: auto;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    border-radius: 8px;
    overflow: hidden;
    background-color: var(--gray5);
    text-align: left;
  }

  .base_information {
    width: 956px;
    min-height: 570px;
    height: auto;
    padding: 74px 70px;
    box-sizing: border-box;
    background-color: var(--white);
  }

  .sub_title {
    font-size: 26px;
    color: var(--black4);
    margin-bottom: 36px;
    font-family: "Black";
    text-transform: uppercase;
  }

  .tip {
    display: inline-block;
    width: 10px;
    height: 10px;
    background-color: var(--red1);
    border-radius: 100%;
    vertical-align: top;
  }

  .edit {
    display: inline-block;
    width: 22px;
    height: 22px;
    cursor: pointer;
    margin-left: 20px;
    background: url(~@/assets/images/xiugai.png) no-repeat top/cover;
  }

  .input_box {
    position: relative;
    width: 100%;
    height: 64px;
    margin-bottom: 25px;
    border-radius: 8px;
    background: var(--gray5);
    overflow: hidden;

    input {
      width: 100%;
      height: 64px;
      font-size: 20px;
      color: var(--dark1);
      line-height: 64px;
      padding: 0 40px;
      box-sizing: border-box;
      border: none;
      border-radius: 8px;
      background: none;
      font-family: "Regular";
      cursor: auto;
    }

    .next {
      position: absolute;
      width: 80px;
      height: 100%;
      top: 0;
      right: 0;
      cursor: pointer;

      &::after {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        right: 35px;
        top: 50%;
        margin-top: -07px;
        border-top: 07px transparent solid;
        border-bottom: 07px transparent solid;
        border-left: 13px var(--gray10) solid;
      }
    }
  }

  .input_box2 {
    font-size: 20px;
    line-height: 64px;
    color: var(--dark1);
    font-family: "Regular";
    padding: 0 40px;
    box-sizing: border-box;

    .name {
      float: right;
    }
  }

  i.youtube {
    display: inline-block;
    width: 41px;
    height: 29px;
    vertical-align: middle;
    margin-right: 10px;
    background: url(~@/assets/images/video_logo.png) no-repeat top/cover;
  }

  i.tiktok {
    display: inline-block;
    width: 45px;
    height: 40px;
    vertical-align: middle;
    margin-right: 10px;
    background: url(~@/assets/images/video_logo.png) no-repeat top/cover;
    background-position-y: -35px;
  }

  i.twitch {
    display: inline-block;
    width: 45px;
    height: 40px;
    vertical-align: middle;
    margin-right: 10px;
    background: url(~@/assets/images/video_logo.png) no-repeat top/cover;
    background-position-y: -75px;
  }

  .mediaList, .gameList {
    margin-top: -20px;
  }

  hr {
    margin-bottom: 20px;

    &:last-child {
      display: none;
    }
  }

  .subTitle {
    font-size: 22px;
    line-height: 34px;
    color: var(--black4);
    margin-bottom: 3px;
    font-family: "Bold";
    text-transform: uppercase;
    text-indent: 5px;

    .edit {
      margin-bottom: -3px;
    }
  }

  .level_box {
    position: relative;
    width: 394px;
    flex: 1;
    height: 400px;

    .level {
      position: absolute;
      width: 228px;
      height: 228px;
      top: 78px;
      left: 50%;
      margin-left: -114px;
      border-radius: 50%;
      background-color: var(--gray24);
    }

    .level3 {
      background: var(--gray24) url(~@/assets/images/lv1.png) no-repeat center center;
      background-size: auto 106px;
    }

    .level2 {
      background: var(--gray24) url(~@/assets/images/lv2.png) no-repeat center center;
      background-size: auto 106px;
    }

    .level1 {
      background: var(--gray24) url(~@/assets/images/lv3.png) no-repeat center center;
      background-size: auto 137px;
    }

    .lv_name {
      position: absolute;
      bottom: 40px;
      width: 100%;
      text-align: center;
      font: 37px "Black";
      line-height: 40px;
      color: var(--yellow3);
    }
  }

  .content_2 {
    width: 1350px;
    min-height: 461px;
    margin: 30px auto 0;
    padding: 73px 70px;
    text-align: left;
    box-sizing: border-box;
    background-color: var(--white);
    overflow: hidden;
    border-radius: 8px;
  }

  .game_list {
    width: 100%;
    font-size: 0;
    line-height: 0;
  }

  .game_item {
    position: relative;
    display: inline-block;
    width: 593px;
    height: 246px;
    border-radius: 8px;
    margin-bottom: 27px;
    background-color: var(--gray5);
  }

  .game_item:nth-child(2n - 1) {
    margin-right: 23px;
  }

  .game_logo {
    position: absolute;
    top: 30px;
    left: 33px;

    img {
      width: auto;
      height: 56px;
      object-fit: contain;
    }
  }

  .user_desc {
    position: absolute;
    right: 30px;
    top: 20px;

    p {
      font-size: 20px;
      color: var(--dark1);
      line-height: 30px;
      text-align: center;
      font-family: "Regular";
      text-align: right;
    }
  }

  .tab_title {
    position: absolute;
    left: 33px;
    top: 135px;
    font-size: 21px;
    line-height: 22px;
    color: var(--black4);
    font-family: "Black";
  }
  
  .tab_list {
    position: absolute;
    left: 33px;
    top: 178px;
    text-align: left;
    font-size: 0;

    li {
      display: inline-block;
      height: 40px;
      font-size: 16px;
      color: var(--white);
      line-height: 40px;
      font-family: "Black";
      border-radius: 40px;
      padding: 0 24px;
      margin-right: 10px;
      margin-bottom: 10px;
      box-sizing: border-box;
      background-color: var(--yellow13);
    }
  }

  .content_3 {
    width: 1350px;
    height: auto;
    margin: 30px auto 0;
    padding: 73px 70px;
    text-align: center;
    box-sizing: border-box;
    background-color: var(--white);
    overflow: hidden;
    border-radius: 8px;

    ul {
      width: 100%;
      margin-top: 45px;
      text-align: center;
      font-size: 0;
      line-height: 0;
    }

    .module_desc {
      font-size: 20px;
      line-height: 40px;
      color: var(--dark1);
      font-family: "Regular";
      margin-top: -30px;
    }

    li {
      position: relative;
      display: inline-block;
      width: 364px;
      height: 88px;
      margin: 0 15px;
      font-size: 26px;
      line-height: 88px;
      font-family: "Bold";
      border-radius: 15px;
      background-color: var(--gray5);

      &.down::after {
        position: absolute;
        content: '';
        right: 35px;
        top: 40px;
        width: 0;
        height: 0;
        border-top: 16px #acacac solid;
        border-left: 8px transparent solid;
        border-right: 8px transparent solid;
        border-bottom: none;
      }
    }

    select {
      display: block;
      width: 100%;
      height: 100%;
      text-align: center;
      font-size: 20px;
      color: var(--dark1);
      font-family: "Regular";
      background: none;
      border: none;
      outline: none;
      cursor: pointer;
      appearance: none;
    }

    .real_name {
      margin-top: 75px;

      li {
        height: auto;
        width: 425px;
        background-color: transparent;
        text-align: left;

        p:nth-child(1) {
          font-size: 19px;
          line-height: 30px;
          color: var(--dark1);
          font-family: "Regular";
          text-indent: 10px;
        }

        p:nth-child(2) {
          font-size: 20px;
          color: var(--dark1);
          font-family: "Regular";
          padding: 0 40px;
          border-radius: 15px;
          background-color: var(--gray5);
          box-sizing: border-box;
        }
      }
    }

    .country {
      margin-top: 75px;

      >p {
        font-size: 30px;
        line-height: 126px;
        color: var(--white);
        font-family: "Regular";
      }

      li {

        width: 880px;
      }
    }

    .language {
      margin-top: 75px;

      >p {
        width: 880px;
        height: 88px;
        font-size: 20px;
        line-height: 88px;
        margin: 0 auto;
        color: var(--dark1);
        font-family: "Regular";
        background-color: var(--gray5);
        border-radius: 15px;
      }
    }
  }

  .discord {
    margin-top: 90px;

    input {
      display: block;
      width: 560px;
      height: 70px;
      margin: 0 auto 0;
      padding: 0 90px;
      font-size: 20px;
      line-height: 70px;
      color: var(--dark1);
      text-align: left;
      font-family: "Regular";
      border: 3px transparent solid;
      border-radius: 10px;
      box-sizing: border-box;
      background: none;
      outline: none;
      background: var(--gray5);
    }

    input.active {
      border: 3px var(--yellow13) solid;
    }

    .discode_input {
      position: relative;
      width: 560px;
      height: 70px;
      margin: 40px auto 0;
    }

    .discode_icon {
      position: absolute;
      width: 33px;
      height: 24px;
      left: 37px;
      top: 50%;
      margin-top: -10px;
      background: url(~@/assets/images/discord_icon.png) no-repeat center/contain;
    }

    .gou {
      display: none;
      position: absolute;
      width: 35px;
      height: 35px;
      border-radius: 50%;
      top: 50%;
      right: 37px;
      margin-top: -18px;
      background: var(--yellow13) url(~@/assets/images/dagou.png) no-repeat center center;
      background-size: 18px auto;
    }

    .active + .gou {
      display: block;
    }
  }

  .submit {
    position: relative;
    width: 365px;
    height: 100px;
    margin: 60px auto 0;
    font-size: 26px;
    line-height: 102px;
    color: var(--white);
    font-family: "Bold";
    background: url(~@/assets/images/btn7.png) no-repeat top/contain;
    background-size: 100% 100%;
    cursor: pointer;
  }
}