.task_box {
  width: 100%;
  height: 900px;
  text-align: center;
  overflow: hidden;
  background: #f6b92e linear-gradient(20deg, #eb990a, #f6ba2f);
}

.task_box.fire {
  width: 300vw;
  margin-left: -100vw;
  display: flex;
  flex-direction: column;
  align-items: center;

  .gudie_fin_box {
    width: 200vw;
    margin-left: -50vw;
  }
}


.gudie_fin_box {
  position: absolute;
  width: 100%;
  height: 900px;
  top: 0;
  left: 0;
  background-color:	rgb(235, 153, 10, .4);
}

@media screen and (max-width: 1700px) {
  .gudie_fin_box {
    min-width: 1450px;
    width: 100% !important;
    margin: 0 !important;
    white-space: nowrap;
    background: none;
  }

  .gudie_fin_box::before {
    content: "";
    position: absolute;
    width: 500vw;
    left: -200vw;
    height: 100%;
    background-color: rgba(235, 153, 10, 0.4);
  }
}


.guide_fin_light {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  animation: show5 1s ease forwards;
  background: url(~@/assets/images/guide_light.png) no-repeat center -80px;
}

.gudie_fin_txt {
  position: absolute;
  width: 100%;
  text-align: center;
  top: 50%;
  opacity: 0;
  margin-top: 30px;
  animation: show2 1s ease forwards;

  p {
    font-size: 36px;
    line-height: 44px;
    color: var(--yellow14);
    font-family: "Black";
    text-transform: uppercase;
  }
}

@keyframes show2 {
  0% {
    transform: translateY(20px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes show5 {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}