@import "~antd/dist/antd.css";
@import "./color.scss";
*{-webkit-text-size-adjust: none;padding: 0;margin: 0;outline: none;/* -webkit-touch-callout:default; */}
html{font-size: 16px;-webkit-text-size-adjust:none;background-color: var(--white);}
body,h1,h2,h3,ul,li,p{font-weight: normal;margin: 0;}
div,a{background-repeat: no-repeat;border:none;text-decoration: none;outline: none;}
img{vertical-align: top;height: 100%;width: auto;}
ul,li{list-style: none;margin: 0;}
input{font-size:100%;}
em { font-style: normal;font-weight: normal; }
a,select{-webkit-tap-highlight-color: transparent;-webkit-touch-callout: none;-webkit-user-select: none;}

.ant-modal-confirm-body .ant-modal-confirm-title {
  font-size: 20px;
  line-height: 22px;
}

.ant-modal-confirm-body .ant-modal-confirm-content {
  font-size: 18px;
}

.ant-btn-primary .primary-link {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

@font-face {
  font-family: "Black";
  src: url(~@/assets/font/Black.ttf);
  font-style: normal;
}

@font-face {
  font-family: "Bold";
  src: url(~@/assets/font/Bold.ttf);
  font-style: normal;
}

@font-face {
  font-family: "Regular";
  src: url(~@/assets/font/Regular.ttf);
  font-style: normal;
}

@font-face {
  font-family: "impact";
  src: url(~@/assets/font/impact.ttf);
  font-style: normal;
}

@font-face {
  font-family: "thai";
  src: url(~@/assets/font/thai.ttf);
  font-style: normal;
}

@font-face {
  font-family: "jp";
  src: url(~@/assets/font/font-jp.otf);
  font-style: normal;
}

@font-face {
  font-family: "sk";
  src: url(~@/assets/font/hmlt-sk.ttf);
  font-style: normal;
}

@font-face {
  font-family: "zh-tw";
  src: url(~@/assets/font/tw.ttf);
  font-style: normal;
}

:root {
  color-scheme: light dark;
}

.wrap {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.no_scroll {
  overflow: hidden;
}

html.lang_th * {
  font-family: "Bold", "thai" !important;
}

html.lang_jp * {
  font-family: "jp", "Bold" !important;
}

html.lang_sk * {
  font-family: "sk", "Bold" !important;
}

html.lang_zh-tw * {
  font-family: "zh-tw", "Regular" !important;
}