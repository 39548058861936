.gamefeed_box {
  position: relative;
  width: 100%;
  height: auto;
  min-height: 700px;
  padding: 55px 44px;
  box-sizing: border-box;
  background-color: var(--white);
  border-radius: 8px;

  .title1 {
    font-size: 26px;
    line-height: 26px;
    color: var(--black4);
    font-family: "Black";
    text-align: left;
  }

  .query {
    position: absolute;
    right: 44px;
    top: 55px;

    input {
      width: 346px;
      height: 40px;
      line-height: 40px;
      font-size: 14px;
      color: var(--black3);
      font-family: "Regular";
      padding: 0 56px 0 26px;
      box-sizing: border-box;
      border-radius: 40px;
      border: 1px #737373 solid;
      background: none;
    }

    i {
      position: absolute;
      width: 23px;
      height: 23px;
      right: 20px;
      top: 50%;
      margin-top: -11px;
      cursor: pointer;
      background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAXCAMAAADX9CSSAAAApVBMVEUAAAA7Ozs9PT08PDw8PDw8PDw7Ozs9PT09PT08PDwyMjI6Ojo/Pz89PT09PT0+Pj48PDw5OTk8PDw9PT09PT01NTU8PDw6Ojo+Pj4+Pj45OTk8PDw9PT06OjpAQEA8PDw9PT06OjpEREQ8PDw+Pj46Ojo5OTksLCwzMzMuLi45OTlBQUE+Pj4+Pj43Nzc4ODg+Pj4+Pj42NjY9PT0+Pj5AQEBEREROboehAAAAM3RSTlMAG/z36scY2dTDEAjoq6aak4Ytt4t8V0RANCQiFQv75uLVz8y/s7OqnJSQjHVtZFNPPSQtBXkYAAAA1UlEQVQoz3WPV3LDMBBDWUR1R8VxSWyn954A1P2PlhGlKKTHxgex80AOscJJLoyGzt5iEeiUBNAfjUfzG8t6tS3iVQXe5RNPOiPH8Tzl7R+ubOY9Tu3LeIUXhcelwvD5U9eEHezCuWZY7ozXznkpQinsekO2xyNKx5M9rpk7ziLAP4ycz/kR8HdbO1+jDHiJdhhM9+zhx+5+nDbKvk64tvp/E8X0q++wXSYEqinYGBJplgCYr68wE5OWDyCpZp9CyNIPxK79bodF4ggn4pDk8YBHAtP8AuK3E9qVr3m9AAAAAElFTkSuQmCC) no-repeat center/contain;
    }
  }

  .gamefeed_list {
    width: 100%;
    height: auto;
    margin-top: 24px;
    padding-bottom: 50px;
    box-sizing: border-box;
    text-align: left;

    li {
      position: relative;
      width: 100%;
      height: 158px;
      padding: 24px 0;
      box-sizing: border-box;
      border-bottom: 1px var(--gray19) solid;
      cursor: pointer;
    }

    .img_box {
      float: left;
      height: 112px;
      width: 213px;
      margin-right: 40px;
      border-radius: 10px;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .title {
      display: inline-block;
      width: 450px;
      height: 40px;
      height: auto;
      font-size: 18px;
      line-height: 20px;
      color: var(--black3);
      font-family: "Regular";
      padding-top: 8px;
      margin-bottom: 30px;
      box-sizing: border-box;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }

    .time {
      font-size: 18px;
      line-height: 20px;
      color: var(--gray11);
      font-family: "Regular";
    }

    .more {
      position: absolute;
      right: 24px;
      bottom: 24px;
      width: 35px;
      height: 35px;
      background-color: var(--gray19);
      border-radius: 5px;
      cursor: pointer;

      &::before, &::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        background-color: var(--white);
      }

      &::before {
        width: 2px;
        height: 20px;
        margin-top: -10px;
        margin-left: -1px;
      }

      &::after {
        width: 20px;
        height: 2px;
        margin-top: -1px;
        margin-left: -10px;
      }
    }

    .none_tip {
      width: 100%;
      font-size: 50px;
      line-height: 400px;
      margin-top: 60px;
      text-align: center;
      color: var(--gray11);
      font-family: "Bold";
      user-select: none;
    }
  }

  .page {
    position: absolute;
    right: 55px;
    bottom: 44px;

    span {
      font-size: 15px;
      line-height: 18px;
      color: var(--black4);
      font-family: "Bold";
      margin: 0 8px;
      cursor: pointer;
    }

    span.active {
      color: var(--yellow3);
      text-decoration: underline;
      pointer-events: none;
    }
  }
}