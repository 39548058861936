.total_earnings_box {
  width: 100%;
  height: auto;
  border-radius: .08rem;
  padding: .54rem .43rem 0;
  margin-top: .4rem;
  background-color: var(--white);
  box-sizing: border-box;
  text-align: left;

  .total_earnings_title {
    font-size: .26rem;
    line-height: .26rem;
    color: var(--black4);
    font-family: "Black";
    text-transform: uppercase;

    .question {
      display: inline-block;
      width: .25rem;
      height: .25rem;
      color: var(--white);
      font-size: .18rem;
      line-height: .25rem;
      font-family: "Black";
      margin: 0 .15rem;
      background-color: var(--yellow13);
      text-align: center;
      vertical-align: middle;
      border-radius: 50%;
      font-style: normal;
      cursor: pointer;
    }


    .hide {
      display: inline-block;
      width: .28rem;
      height: .28rem;
      vertical-align: middle;
      cursor: pointer;
      background: url(~@/assets/images/eyes.png) no-repeat top/cover;
      background-position-y: .08rem;
    }

    .hide.active {
      background-position-y: bottom;
    }
  }

  ul {
    width: 100%;
    height: auto;
    margin-top: 50px;
    display: flex;
    flex-wrap: wrap;
  }

  li {
    position: relative;
    width: 49%;
    height: auto;
    margin-bottom: .75rem;
    word-wrap: break-word;

    &:nth-child(1)::after {
      content: "";
      position: absolute;
      right: 0;
      top: 50%;
      width: 1px;
      height: 90%;
      transform: translateY(-30%);
      background-color: var(--gray21);
    }

    &:nth-child(2) {
      padding-left: .4rem;
      box-sizing: border-box;
    }
  }

  .total_earnings_subtitle {
    font-size: .2rem;
    line-height: .26rem;
    padding-bottom: .2rem;
    color: var(--black4);
    font-family: "Black";
    text-transform: uppercase;
  }

  .total_earnings_subtitle + p {
    word-break: break-word;
    line-height: .46rem;
  }

  .num {
    font-size: .47rem;
    line-height: .56rem;
    color: var(--yellow13);
    font-family: "Bold";

    .subtxt1 {
      font-size: .22rem;
      color: var(--black4);
      font-family: "Black";
    }

    .subtxt2 {
      font-size: .22rem;
      font-family: "Black";
    }
  }

  .none {
    width: 100%;
    font-size: .22rem;
    line-height: .70rem;
    color: var(--gray15);
    font-family: "Black";
  }
}