.detail_content {
  position: relative;
  width: 6.74rem;
  height: auto;
  font-size: .14rem;
  min-height: 7.50rem;
  padding: 1rem 0;
  margin: 0.4rem auto 0;
  box-sizing: border-box;
  background-color: var(--white);
  border-radius: .1rem;

  display: flex;
  flex-direction: column;

  .back {
    position: absolute;
    width: .52rem;
    height: .52rem;
    left: .25rem;
    top: .25rem;
    cursor: pointer;
    background: url(~@/assets/images/back.png) no-repeat center/contain;
  }

  em {
    font-family: "Bold";
  }

  .title {
    width: 100%;
    font-size: .48rem;
    color: var(--yellow3);
    line-height: .50rem;
    text-align: center;
    font-family: "Black";
  }

  .rewardContent {
    width: 100%;
    height: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .rewardTableBox {
    position: relative;
    width: 100%;
    height: auto;
    margin: 0 auto 0;
    padding-bottom: .25rem;
    box-sizing: border-box;
    background-color: var(--white);
    border-radius: .10rem;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }

  .tableBoxText {
    width: 100%;
    height: .86rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 .2rem;
    box-sizing: border-box;

    p {
      line-height: .21rem;
      font-size: .20rem;
      color: var(--black4);
      font-family: "Black";
    }
  }

  .tableBoxText2 {
    width: 100%;
    height: .20rem;
    text-align: center;
    padding: 0 .2rem;
    margin: -.25rem auto .10rem;
    box-sizing: border-box;

    p {
      line-height: .20rem;
      font-size: .20rem;
      color: var(--black4);
      font-family: "Bold";
    }
  }

  .bounBox {
    width: 100%;
    height: 1.26rem;
    padding: 0 .2rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;

    p {
      font-size: .22rem;
      color: var(--black4);
      line-height: .60rem;
      font-family: "Black";
    }

    P:nth-child(2) {
      font-size: .22rem;
      text-align: right;
    }

    .right {
      line-height: 0.22rem;
    }

    span {
      color: var(--yellow3);

      &.pre {
        font-size: .22rem;
      }
    }

    em {
      font-size: .38rem;
    }

    .pre {
      color: var(--gray20);
      font-size: .22rem;
      font-family: "Bold";
    }
  }

  .question {
    width: 100%;
    padding: 0 .2rem;
    box-sizing: border-box;
    text-align: left;
    font: .19rem "Regular";
    line-height: .22rem;
    color: var(--black4);

    p:nth-child(1) {
      font-size: .22rem;
      color: var(--black4);
      line-height: .30rem;
      font-family: "Black";
      text-align: right;
    }
  }

  .rewardTable {
    width: 100%;
    height: auto;
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .table_head {
      width: 100%;
      height: .66rem;
      margin-bottom: .12rem;
      background-color: var(--gray27);
      font-size: .17rem;
      color: var(--black4);
      line-height: .66rem;
      font-family: "Black";
      text-align: center;
      align-items: center;
      display: flex;

      li:nth-child(1) {
        width: 32%;
      }

      li:nth-child(2) {
        width: 20%;
        flex: 1;
      }

      li:nth-child(3) {
        width: 20%;
        flex: 1;
      }

      li:nth-child(4) {
        width: 20%;
        flex: 1;
      }

      li:nth-child(5) {
        width: 20%;
        flex: 1;
      }

      // li:nth-child(1) {
      //   width: 30%;
      // }

      // li:nth-child(2) {
      //   width: 20%;
      // }

      // li:nth-child(3) {
      //   width: 15%;
      // }

      // li:nth-child(4) {
      //   width: 20%;
      // }

      // li:nth-child(5) {
      //   width: 15%;
      // }

      li {
        height: .38rem;
        line-height: .2rem;
        border-right: 1px rgba(0, 0, 0, 0.15) solid;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;

        &:last-child {
          border: none;
        }
      }
    }

    .table_body {
      width: 97%;
      height: 100%;
      max-height: 4.00rem;
      flex: 1;
      margin: 0 auto;
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: .04rem;
      }

      &::-webkit-scrollbar-track {
        background: none;
        border-radius: .04rem;
      }

      &::-webkit-scrollbar-thumb {
        background: var(--gray4);
        border-radius: .04rem;
      }

      ul {
        width: 100%;
        display: flex;
        border-bottom: 1px rgba(0, 0, 0, 0.08) solid;

        li:nth-child(1) {
          width: 31%;
        }

        li:nth-child(2) {
          width: 20.6%;
          flex: 1;
        }

        li:nth-child(3) {
          width: 20.9%;
          flex: 1;
        }

        li:nth-child(4) {
          width: 20.9%;
          flex: 1;
        }

        li:nth-child(5), li:last-child {
          width: 18.7%;
          flex: 1;
          padding: 0;
        }

        // li:nth-child(1) {
        //   width: 29.4%;
        // }

        // li:nth-child(2) {
        //   width: 20.6%;
        // }

        // li:nth-child(3) {
        //   width: 15.4%;
        // }

        // li:nth-child(4) {
        //   width: 20.9%;
        // }

        // li:nth-child(5) {
        //   width: 13.7%;
        //   padding-right: 0;
        //   padding-left: .15rem;
        // }
      }

      li {
        height: auto;
        font-size: .16rem;
        line-height: .21rem;
        color: var(--black4);
        padding: .06rem;
        box-sizing: border-box;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        word-break: break-all;
        font-family: "Regular";

        &:nth-child(1) {
          text-align: left;
          justify-content: start;
        }
      }
    }
  }

  .noData {
    width: 100%;
    height: auto;
    min-height: .66rem;
    margin: .20rem auto;
    padding: 0 .2rem;
    box-sizing: border-box;
    font: .20rem "Regular";
    line-height: .66rem;
    color: var(--black4);
    text-align: left;
    background-color: var(--gray27);
  }

  .slice {
    width: 98%;
    height: 1px;
    margin: .20rem auto .55rem;
    background-color: rgba(0, 0, 0, 0.2);
  }
}