.community_rules_box {
  position: relative;
  width: 1380px;
  height: 605px;
  margin: 0 auto;
  border-radius: 10px;
  background-color: var(--white);
}

.community_title {
  width: 100%;
  font-size: 38px;
  line-height: 116px;
  color: var(--gray31);
  font-family: "Black";
  text-transform: uppercase;
}

.communit_detail {
  width: 1275px;
  height: 325px;
  padding: 0 20px;
  margin: 0 auto;
  overflow-y: auto;
  text-align: left;
  box-sizing: border-box;

  p {
    font-size: 20px;
    line-height: 25px;
    color: var(--gray32);
    font-family: "Regular";
  }

  .sub {
    font-size: 24px;
    line-height: 30px;
    font-family: "Bold";
  }

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: none;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--gray4);
    border-radius: 4px;
  }
}

.rule_desc {
  width: 100%;
  margin-top: 30px;

  .title {
    font-size: 24px;
    line-height: 30px;
    font-family: "Bold";
    text-align: center;
  }

  .sub_title {
    font-size: 20px;
    line-height: 30px;
    font-family: "Regular";
    text-align: left;
  }

  .content {
    font-size: 20px;
    line-height: 25px;
    color: var(--gray32);
    font-family: "Regular";
  }
}

.agree_btn {
  width: 272px;
  height: 75px;
  font-size: 19px;
  line-height: 65px;
  color: var(--white);
  text-align: center;
  margin: 20px auto 0;
  font-family: "Bold";
  background: url(~@/assets/images/btn2.png) no-repeat center center;
  background-size: 100% 100%;
  filter: grayscale(1);
  -webkit-filter: grayscale(1);
}

.agree_btn.active {
  filter: none;
  -webkit-filter: none;
  cursor: pointer;
}

.agree_tip {
  width: 100%;
  font-size: 16px;
  line-height: 24px;
  color: var(--yellow5);
  font-family: "Regular";
}

.creator_line {
  width: 1225px;
  font-size: 20px;
  line-height: 42px;
  color: var(--yellow21);
  font-family: "Black";
  text-align: left;
  margin: 0 auto;
  cursor: pointer;
  text-decoration: underline;
}

.accept {
  width: 1225px;
  font-size: 16px;
  line-height: 18px;
  color: var(--dark1);
  font-family: "Regular";
  text-align: left;
  margin: 10px auto 0;

  i {
    position: relative;
    display: inline-block;
    width: 16px;
    height: 16px;
    line-height: 16px;
    margin-right: 5px;
    border-radius: 50%;
    border: var(--gray31) 1px solid;
    vertical-align: top;
    cursor: pointer;
  }

  i.active::after {
    content: "";
    position: absolute;
    width: 10px;
    height: 10px;
    top: 50%;
    left: 50%;
    margin-top: -5px;
    margin-left: -5px;
    background-color: var(--yellow21);
    border-radius: 50%;
  }
}