@import "~@/style/afkStyle.scss";

.module_title {
  font-size: .50rem;
  line-height: .90rem;
  color: var(--yellow12);
  text-transform: uppercase;
  font-family: "Black";
}

.module_desc {
  width: 95%;
  margin: 0 auto;
  font-size: .30rem;
  line-height: .64rem;
  color: var(--dark1);
  font-family: "Regular";

  span {
    color: var(--yellow12);
    font-family: "Black";
  }
}

.main_box {
  width: 15.74rem;
  border-radius: .10rem;
  box-sizing: border-box;
  margin: .85rem auto 0;
  padding: .60rem 0;
  background-color: var(--gray26);

  ul {
    width: 100%;
    margin-top: .45rem;
    text-align: center;
    font-size: 0;
    line-height: 0;
  }

  li {
    display: inline-block;
    width: 3.64rem;
    height: .88rem;
    margin: 0 .15rem;
    font-size: .26rem;
    line-height: .88rem;
    font-family: "Bold";
    border-radius: .15rem;
    cursor: pointer;
  }
}

.video_platform {
  @extend .main_box;
  height: auto;
  padding-bottom: 1rem;
  margin: .25rem auto 0;
  user-select: none;

  li {
    position: relative;
    color: var(--black1);
    border: .03rem var(--blue2) solid;
  }

  li.active {
    border-color: var(--yellow19);
  }

  .icon {
    display: inline-block;
    width: .56rem;
    height: .43rem;
    line-height: .88rem;
    margin-right: .10rem;
    vertical-align: middle;
    background: url(~@/assets/images/video_logo.png) no-repeat top/cover;
  }

  .icon1 .media_logo {
    @extend .icon;
    background-position: top;
    margin-right: .20rem;
  }

  .icon2 .media_logo {
    @extend .icon;
    background-position: center;
  }

  .icon3 .media_logo {
    @extend .icon;
    background-position: bottom;
  }

  .check {
    position: absolute;
    width: .30rem;
    height: .30rem;
    right: .30rem;
    top: 50%;
    margin-top: -.15rem;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB8AAAAfCAMAAAAocOYLAAAAw1BMVEUAAACfn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5////+dnZ2WlpaYmJicnJybm5v9/f309PSSkpLKysrAwMC1tbWlpaX4+Pjv7+/n5+fOzs7FxcWtra2hoaHh4eHY2NjU1NSurq6qqqrq6uq5ubn2Hls3AAAAJXRSTlMAyePU9vC1p2UzKw/fnIwmC/nrgXFfPATb2c24l4lqThgWxHZGOe+0BQAAAVdJREFUKM91k+d6gjAUQC9DQBRwb6u2zQ0BFMQ92/d/qhYCiILnD4HzcVcSyKj12sqwVREFo2tBgcaHQDKak+qL/v4kTzTNWl53muQVQX/oKSmhnuX4IqVIc657MilH4X1J5B1a5DVSgDqUxiU0AGqjgnZdf3+icYA+9AraWfmIRxZXoEO7ENw+eLg7OdFa7ICSfKWpj/RPyPiLCsNEUyf5O/Bwe7fTKUKL68M1dGP9u8HtjaXRBlCJHuy4wYvr/Ov1EpdrRlJEEOOS6QXx6jrsxnWGBAJv2Tkj7u37FjfrFX34MRh8wU47RP+MXpDXsgldwmHhDj3EwOaaI83AktO+w2WUguQxFtCfZIM5+oFD81qOjkg12362ynKn04swyRsqFj8gwhvfBY4uluo2pOhSiZ7Cg4X6alsa5Olr9afKVKt4ARUxkSOjCkUaekcVBnVpbM7mkPEHyu1r7tVxUG4AAAAASUVORK5CYII=) no-repeat center/contain;
  }

  .check.active {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB8AAAAfCAMAAAAocOYLAAAA7VBMVEUAAAAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wD/+v8j/yMO/w4G/wby+/Lq+urI/Mhh/WE5/jkd/x3b+9u6+7qR/ZGA/YB6/Xpl/WXi++Li+uLT+9Ou+66d/J2b/JuC/YJv/W9u/G5a/VpZ/VlQ/lBC/UIu/i4Y/xi4JdcUAAAAL3RSTlMA1LfiymX08N+mijImDfnrmnFfPCoQ9tvZzcedjYBqLBgWCgTkxK+slIR2UE1GNw6xnh0AAAE0SURBVCjPddKHboNADIBhMwMJZDd7p3vZCdnN6N7t+z9OkzugjOOT0Fn6AYFk8B2V2nItl1IazaIFMXZHRV/2/DaSb04wJJu2g7mQxSi1C740ChgZL1+ikNTjuaSj2Cn/LwmTaIeuYSLDBrDrgvC2cfgLhlAS5C3RiA1yGdrxvCOajdmkFECO5TnLnAm1aP4gelyiS4UcO53d2n3ka0KrH/RUIcXO0YSe2bCY0nSBPgUUdo5fiNaH++5DGSVoIOM8Eb3ickWTbwyQoYmcc0e03V+fway3oIiu3xntzTFIugKrgq7lA9EGQ/J9gDx6Ru/zcK4cViSjYxLT2y6xlMUXREWxInDlY2G+AE9XEuQ0/OufRWtOg6ChZoS+zLQgYtCRFR71ej4DcYNywVSrhiS3rnvg+wMubYWnXJT5ZQAAAABJRU5ErkJggg==) no-repeat center/contain;
  }

  .platform_upload {
    position: relative;
    width: 11.50rem;
    height: 3.1rem;
    margin: .70rem auto 0;
    border-radius: .10rem;
    background-color: var(--white);
    box-sizing: border-box;
    text-align: left;
    overflow: hidden;

    >span>div>div:last-child {
      border: none;
      background: none;
    }

    .upload_default {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;

      &.default p {
        top: 2rem;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    .upload_icon {
      position: absolute;
      top: 50%;
      left: 50%;
      background-color: var(--gray10);
      border-radius: .14rem
    }

    .upload_icon1 {
      @extend .upload_icon;
      width: 1.00rem;
      height: .14rem;
      margin-top: -.07rem;
      margin-left: 3.3rem
    }

    .upload_icon1.center {
      margin-top: -.27rem;
      margin-left: -0.5rem
    }

    .upload_icon2 {
      @extend .upload_icon;
      width: .14rem;
      height: 1.00rem;
      margin-top: -.5rem;
      margin-left: 3.73rem
    }

    .upload_icon2.center {
      margin-top: -.7rem;
      margin-left: -0.07rem
    }

    .img_box {
      position: absolute;
      width: 2.36rem;
      height: 1.27rem;
      top: 0.9rem;
      left: 5.85rem;
      box-sizing: border-box;
      padding: 0.16rem 0.2rem;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: transform 0.7s;
      }

      .border1, .border2 {
        position: absolute;
        top: 0;
        width: 0.25rem;
        height: 0.2rem;
        border-top: 2px var(--gray10) solid;
      }

      .border3, .border4 {
        position: absolute;
        bottom: 0;
        width: 0.25rem;
        height: 0.2rem;
        border-bottom: 2px var(--gray10) solid;
      }

      .border1, .border3 {
        left: 0;
        border-left: 2px var(--gray10) solid;
      }

      .border2, .border4 {
        right: 0;
        border-right: 2px var(--gray10) solid;
      }
    }

    .upload_media_icon {
      @extend .icon;
      position: absolute;
      top: 1rem;
      left: 1.52rem;
      white-space: nowrap;
      line-height: .43rem;
      text-indent: .7rem;
      font-family: "Bold"
    }
    
    .youtubeIcon {
      @extend .upload_media_icon;
      background-position: top;
    }
    
    .tiktokIcon {
      @extend .upload_media_icon;
      background-position: center;
    }
    
    .twitchIcon {
      @extend .upload_media_icon;
      background-position: bottom;
    }
    
    p {
      position: absolute;
      width: 4rem;
      top: 1.5rem;
      left: 0.3rem;
      font-size: .24rem;
      line-height: 0.3rem;
      color: var(--gray11);
      font-family: "Regular";
      text-align: center;
    }

    .default_tip {
      width: 100%;
      top: 2.2rem;
    }

    .upload_action {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      opacity: 0;
      z-index: 5;
      cursor: pointer;
    }
  }

  .slice {
    position: absolute;
    width: 2px;
    height: 1.28rem;
    top: 50%;
    left: 4.76rem;
    margin-top: -0.64rem;
    background-color: var(--gray28);
  }
}

:global {
  .application-media {

    .ant-upload-picture-card-wrapper,
    .ant-upload-list {
      height: 100%;
      text-align: center;
    }

    .ant-upload-list-picture-card-container {
      width: 4.08rem;
      height: 4.08rem;
    }

    .ant-upload-list-item-info::before {
      left: 0;
    }

    .ant-upload-list-item-actions {
      transform: scale(2) translate(-50%, -50%);

      >a {
        display: none;
      }
    }

    .ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-delete {
      margin: 0 auto;
    }

    .ant-upload {
      width: 0;
    }

    .ant-upload-list-picture-card-container+.ant-upload {
      display: none;
    }

    .ant-upload-list.ant-upload-list-picture-card > div:nth-child(1) {
      width: 4rem;
      height: 2.75rem;
      margin: 0.1rem 0;
    }
  }
}

.step1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  border-top: 0.55rem var(--yellow12) solid;
  border-left: 0.55rem var(--yellow12) solid;
  border-right: 0.55rem transparent solid;
  border-bottom: 0.55rem transparent solid;

  span {
    position: absolute;
    top: -0.46rem;
    left: -0.44rem;
    font-size: 0.55rem;
    line-height: 0.56rem;
    color: var(--white);
    font-family: "Arial";
    font-weight: 700;
  }
}

.platform_mediaData {
  position: relative;
  width: 11.5rem;
  height: auto;
  margin: 0.6rem auto 0;
  padding-top: 0.8rem;
  padding-bottom: 0.65rem;
  box-sizing: border-box;
  background-color: var(--white);
  border-radius: 0.1rem;
  overflow: hidden;
  text-align: center;

  .mediaDataT1 {
    font-size: 0.24rem;
    line-height: 0.3rem;
    color: var(--yellow12);
    font-family: "Black";
  }

  .desc1 {
    font-size: 0.16rem;
    color: var(--gray11);
    line-height: 0.44rem;
    font-family: "Arial";
  }

  .input_box {
    position: relative;
    width: 8.42rem;
    height: auto;
    border-radius: 0.1rem;
    overflow: hidden;
    display: flex;
    align-items: center;
    margin: 0.1rem auto;
  }

  input {
    flex: 1;
    width: 100%;
    height: 0.56rem;
    font-size: 0.18rem;
    line-height: 0.56rem;
    color: var(--dark1);
    font-family: "Regular";
    text-align: left;
    box-sizing: border-box;
    padding: 0 0.4rem;
    background: var(--gray26);
    border: none;
    outline: none;
  }

  .confirm {
    width: 1.34rem;
    height: 0.56rem;
    font-size: 0.2rem;
    line-height: 0.56rem;
    color: var(--white);
    font-family: "Black";
    background-color: var(--yellow20);
  }

  .tip {
    font-size: 0.19rem;
    line-height: 0.24rem;
    color: var(--red6);
    font-family: "Regualr";
    margin: 0.3rem auto;

    i {
      position: relative;
      display: inline-block;
      width: 0.18rem;
      height: 0.18rem;
      margin-right: 0.1rem;
      margin-bottom: 0.02rem;
      vertical-align: middle;
      background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAARCAMAAAAMs7fIAAAATlBMVEUAAADzNjbzNjbzNjbzNjbzNjbzNjbzNjbzNjbzNjbzNjbzNjbzNjbzNjbzNjbzNjbzNjbzNjbzNjbzNjbzNjbzNjbzNjbzNjbzNjbzNjZf3LrHAAAAGXRSTlMABfL2qzqzr0ZCTOSeZtPRv7p8cFgpIx4ZpDEUMQAAAGBJREFUGNNNyEcWgCAMBcAf7Cj2lvtf1AeExFkOdjcRimGcHzjmjkpUzAcmjqXhLlCdS6IBSmloeQkrltCSUD7OShZ9xVFL/3CblEZDdSoLQMrCasyhFbCksPJ4ww1zeny77wlwVqbkmwAAAABJRU5ErkJggg==) no-repeat center/contain;
    }
  }

  .meidaData {
    position: relative;
    width: 8.42rem;
    height: 1.43rem;
    margin: 0.14rem auto 0;
    
    img {
      position: absolute;
      width: 2.55rem;
      height: 1.43rem;
      top: 0;
      left: 0;
      object-fit: cover;
    }

    .title {
      position: absolute;
      left: 2.8rem;
      top: 0;
      width: 5.2rem;
      font-size: 0.22rem;
      line-height: 0.28rem;
      color: var(--black5);
      font-family: "Bold";
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      text-align: left;
    }

    .time {
      position: absolute;
      left: 2.8rem;
      top: 0.36rem;
      font-size: 0.18rem;
      line-height: 0.22rem;
      color: var(--gray12);
      font-family: "Reguale";
    }

    ul {
      position: absolute;
      width: auto !important;
      height: auto;
      top: 0.84rem;
      left: 2.8rem;
      font-size: 0;
      line-height: 0;
      margin-top: 0 !important;

      li {
        display: inline-block;
        width: 1.74rem;
        height: 0.64rem;
        text-align: left;
        padding-left: 0.42rem;
        box-sizing: border-box;
        margin: 0;
        border-radius: 0;
        border: none;

        &:nth-child(1) {
          padding: 0;
        }

        & p:nth-child(1) {
          font-size: 0.14rem;
          line-height: 0.14rem;
          color: var(--black5);
          font-family: "Black";
          white-space: nowrap;
        }

        & p:nth-child(2) {
          font-size: 0.36rem;
          line-height: 0.36rem;
          margin-top: 0.12rem;
          color: var(--yellow12);
          font-family: "Bold";
        }
      }
    }

    .clean {
      position: absolute;
      width: 0.2rem;
      height: 0.25rem;
      right: 0;
      top: 0;
      background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAZCAMAAAAL146mAAAASFBMVEUAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACrhKybAAAAGHRSTlOZAFF9DXQyCJR6WyZtVz8sj4dvZUlFOBO/T6+aAAAAc0lEQVQY083OSRKDMAxE0W4rAQ9A5uT+N41sFQXCF+AvulRvJbA2hNrCllFE62eURIP1qPcXVmyhFsAujJdDRNeMIA7ucwE/jiYSDHq8Jh3JOteVSJ0bpae0p1FnoJyB0vHV4ihknec7brRllB1JpeJoIf+SRwOAWrecSwAAAABJRU5ErkJggg==) no-repeat center/contain;
    }
  }
}

.video_platform {
  @include application_platform;
}