.detail_box {
  position: relative;
  width: 100%;
  height: auto;
  min-height: 30vh;
  padding: .4rem .44rem;
  margin: .4rem auto;
  box-sizing: border-box;
  background-color: var(--white);
  border-radius: .08rem;
  word-break: break-word;
  
  .back {
    position: absolute;
    width: .52rem;
    height: .52rem;
    left: .25rem;
    top: .25rem;
    cursor: pointer;
    background: url(~@/assets/images/back.png) no-repeat center/contain;
  }

  .title {
    width: 80%;
    margin: 0 auto;
    font-size: .26rem;
    line-height: .3rem;
    color: var(--black3);
    font-family: "Black";
    text-align: center;
  }

  .time {
    font-size: .18rem;
    color: var(--gray20);
    line-height: .5rem;
    font-family: "Regular";
    text-align: center;
  }

  .contain {
    width: 100%;
    padding: 0 .1rem;
    box-sizing: border-box;
    overflow-y: auto;
    text-align: left;
    font-size: .18rem;
    line-height: 0.3rem;
    color: var(--black3);
    font-family: "Regular";

    img {
      display: block;
      width: 100%;
      height: auto;
      margin: .35rem auto;
      max-width: 100%;
    }

    video, iframe {
      max-width: 100%;
      width: 100%;
      height: auto;
    }

    &::-webkit-scrollbar {
      width: .04rem;
    }

    &::-webkit-scrollbar-track {
      background: none;
      border-radius: .04rem;
    }

    &::-webkit-scrollbar-thumb {
      background: var(--gray4);
      border-radius: .04rem;
    }
  }
}
