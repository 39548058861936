@import "~@/style/afkStyle.scss";

.module_title {
  margin: 0 auto;
  font-size: .40rem;
  line-height: .5rem;
  color: var(--yellow12);
  text-transform: uppercase;
  font-family: "Black";
}

.module_desc {
  font-size: .26rem;
  line-height: .4rem;
  color: var(--dark1);
  font-family: "Regular";
  padding-top: .2rem;

  span {
    color: var(--yellow12);
    font-family: "Black";
  }
}

.main_box {
  width: 6.93rem;
  border-radius: .10rem;
  box-sizing: border-box;
  margin: .85rem auto 0;
  padding: .3rem 0;
  background-color: var(--gray26);

  ul {
    width: 100%;
    margin-top: .45rem;
    text-align: center;
    font-size: 0;
    line-height: 0;
  }

  li {
    display: inline-block;
    width: 3.64rem;
    height: .88rem;
    margin: 0 .15rem;
    font-size: .26rem;
    line-height: .88rem;
    font-family: "Bold";
    border-radius: .1rem;
    cursor: pointer;
  }
}

.video_platform {
  @extend .main_box;
  height: auto;
  margin: .3rem auto 0;
  padding-bottom: 0.6rem;

  li {
    position: relative;
    width: 1.93rem;
    height: .53rem;
    margin: 0 .08rem;
    font-size: .18rem;
    line-height: .53rem;
    font-family: "Bold";
    border-radius: .1rem;
    color: var(--black1);
    border: .03rem var(--blue2) solid;
    box-sizing: border-box;
    padding-left: .1rem;
    text-align: left;
  }

  li.active {
    border-color: var(--yellow19);
  }

  .icon {
    display: inline-block;
    width: .56rem;
    height: .43rem;
    line-height: .88rem;
    margin-right: .10rem;
    vertical-align: middle;
    transform: scale(.9);
    transform-origin: center top;
    background: url(~@/assets/images/video_logo.png) no-repeat top/cover;
  }

  .icon1 .media_logo {
    @extend .icon;
    background-position: top;
    margin-right: 0.04rem;
  }

  .icon2 .media_logo {
    @extend .icon;
    background-position: center;
  }

  .icon3 .media_logo {
    @extend .icon;
    background-position: bottom;
  }

  .check {
    position: absolute;
    width: .26rem;
    height: .26rem;
    right: .10rem;
    top: 50%;
    margin-top: -.13rem;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB8AAAAfCAMAAAAocOYLAAAAw1BMVEUAAACfn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5////+dnZ2WlpaYmJicnJybm5v9/f309PSSkpLKysrAwMC1tbWlpaX4+Pjv7+/n5+fOzs7FxcWtra2hoaHh4eHY2NjU1NSurq6qqqrq6uq5ubn2Hls3AAAAJXRSTlMAyePU9vC1p2UzKw/fnIwmC/nrgXFfPATb2c24l4lqThgWxHZGOe+0BQAAAVdJREFUKM91k+d6gjAUQC9DQBRwb6u2zQ0BFMQ92/d/qhYCiILnD4HzcVcSyKj12sqwVREFo2tBgcaHQDKak+qL/v4kTzTNWl53muQVQX/oKSmhnuX4IqVIc657MilH4X1J5B1a5DVSgDqUxiU0AGqjgnZdf3+icYA+9AraWfmIRxZXoEO7ENw+eLg7OdFa7ICSfKWpj/RPyPiLCsNEUyf5O/Bwe7fTKUKL68M1dGP9u8HtjaXRBlCJHuy4wYvr/Ov1EpdrRlJEEOOS6QXx6jrsxnWGBAJv2Tkj7u37FjfrFX34MRh8wU47RP+MXpDXsgldwmHhDj3EwOaaI83AktO+w2WUguQxFtCfZIM5+oFD81qOjkg12362ynKn04swyRsqFj8gwhvfBY4uluo2pOhSiZ7Cg4X6alsa5Olr9afKVKt4ARUxkSOjCkUaekcVBnVpbM7mkPEHyu1r7tVxUG4AAAAASUVORK5CYII=) no-repeat center/contain;
  }

  .check.active {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB8AAAAfCAMAAAAocOYLAAAA7VBMVEUAAAAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wD/+v8j/yMO/w4G/wby+/Lq+urI/Mhh/WE5/jkd/x3b+9u6+7qR/ZGA/YB6/Xpl/WXi++Li+uLT+9Ou+66d/J2b/JuC/YJv/W9u/G5a/VpZ/VlQ/lBC/UIu/i4Y/xi4JdcUAAAAL3RSTlMA1LfiymX08N+mijImDfnrmnFfPCoQ9tvZzcedjYBqLBgWCgTkxK+slIR2UE1GNw6xnh0AAAE0SURBVCjPddKHboNADIBhMwMJZDd7p3vZCdnN6N7t+z9OkzugjOOT0Fn6AYFk8B2V2nItl1IazaIFMXZHRV/2/DaSb04wJJu2g7mQxSi1C740ChgZL1+ikNTjuaSj2Cn/LwmTaIeuYSLDBrDrgvC2cfgLhlAS5C3RiA1yGdrxvCOajdmkFECO5TnLnAm1aP4gelyiS4UcO53d2n3ka0KrH/RUIcXO0YSe2bCY0nSBPgUUdo5fiNaH++5DGSVoIOM8Eb3ickWTbwyQoYmcc0e03V+fway3oIiu3xntzTFIugKrgq7lA9EGQ/J9gDx6Ru/zcK4cViSjYxLT2y6xlMUXREWxInDlY2G+AE9XEuQ0/OufRWtOg6ChZoS+zLQgYtCRFR71ej4DcYNywVSrhiS3rnvg+wMubYWnXJT5ZQAAAABJRU5ErkJggg==) no-repeat center/contain;
  }

  .platform_upload {
    position: relative;
    width: 90%;
    min-height: 4.88rem;
    margin: .50rem auto 0;
    border-radius: .10rem;
    background-color: var(--white);
    padding: .25rem .25rem 3.18rem;
    box-sizing: border-box;
    cursor: pointer;
    text-align: left;
    padding: 0;

    &.platform_upload_default {
      min-height: 3.15rem;

      p {
        top: 1.9rem;
        width: 60%;
      }
    }

    > span > div > div:last-child {
      border: none;
      background: none;
    }

    .upload_default {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
  
    .upload_icon {
      position: absolute;
      top: 50%;
      left: 50%;
      background-color: var(--gray10);
      border-radius: .14rem
    }

    .upload_icon1 {
      @extend .upload_icon;
      width: 1.00rem;
      height: .14rem;
      margin-top: 1.08rem;
      margin-left: 0.7rem
    }

    .upload_icon1.center {
      margin-top: -.47rem;
      margin-left: -0.5rem;
    }

    .upload_icon2 {
      @extend .upload_icon;
      width: .14rem;
      height: 1.00rem;
      margin-top: 0.65rem;
      margin-left: 1.13rem
    }

    .upload_icon2.center {
      margin-left: -0.07rem;
      margin-top: -.90rem;
    }

    .img_box {
      position: absolute;
      width: 2rem;
      height: 1.2rem;
      top: 3rem;
      left: 0.87rem;
      border-radius: .1rem;
      padding: 0.16rem 0.2rem;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .border1, .border2 {
        position: absolute;
        top: 0;
        width: 0.25rem;
        height: 0.2rem;
        border-top: 2px var(--gray10) solid;
      }

      .border3, .border4 {
        position: absolute;
        bottom: 0;
        width: 0.25rem;
        height: 0.2rem;
        border-bottom: 2px var(--gray10) solid;
      }

      .border1, .border3 {
        left: 0;
        border-left: 2px var(--gray10) solid;
      }

      .border2, .border4 {
        right: 0;
        border-right: 2px var(--gray10) solid;
      }
    }

    .upload_media_icon {
      @extend .icon;
      position: absolute;
      top: 0.6rem;
      left: 50%;
      margin-left: -.28rem;
      white-space: nowrap;
      line-height: .43rem;
      text-indent: .7rem;
      margin-left: -.8rem;
      font-size: 0.22rem;
      font-family: "Bold"
    }
    
    .youtubeIcon {
      @extend .upload_media_icon;
      background-position: top;
    }
    
    .tiktokIcon {
      @extend .upload_media_icon;
      background-position: center;
    }
    
    .twitchIcon {
      @extend .upload_media_icon;
      background-position: bottom;
    }
    

    p {
      position: absolute;
      width: 60%;
      top: 1.15rem;
      left: 20%;
      font-size: .24rem;
      line-height: 0.26rem;
      color: var(--gray11);
      font-family: "Regular";
      text-align: center;
    }

    .upload_action {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      opacity: 0;
      z-index: 5;
      cursor: pointer;
    }

    .default_tip {
      width: 100%;
      top: 2.2rem;
    }

  }

  .slice {
    position: absolute;
    width: 4.68rem;
    height: 1px;
    top: 2.3rem;
    left: 50%;
    margin-left: -2.34rem;
    background-color: var(--gray28);
  }

  :global {
    .application-media {
  
      .ant-upload-picture-card-wrapper,
      .ant-upload-list {
        height: 100%;
        text-align: center;
      }
  
      .ant-upload-list.ant-upload-list-picture-card > div:nth-child(1) {
        width: 4.08rem;
        height: 4.08rem;
      }
  
      .ant-upload-list-item-info::before {
        left: 0;
      }
  
      .ant-upload-list-item-actions {
        transform: scale(2) translate(-50%, -50%);
  
        >a {
          display: none;
        }
      }
  
      .ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-delete {
        margin: 0 auto;
      }
  
      .ant-upload {
        width: 0;
      }
  
      .ant-upload-list-picture-card-container+.ant-upload {
        display: none;
      }
  
      .ant-upload-list.ant-upload-list-picture-card > div:nth-child(1) {
        width: 4rem;
        height: 2.75rem;
        margin: 0.5rem 0;
      }
    }
  }
}

.step1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  border-top: 0.55rem var(--yellow12) solid;
  border-left: 0.55rem var(--yellow12) solid;
  border-right: 0.55rem transparent solid;
  border-bottom: 0.55rem transparent solid;

  span {
    position: absolute;
    top: -0.46rem;
    left: -0.44rem;
    font-size: 0.55rem;
    line-height: 0.56rem;
    color: var(--white);
    font-family: "Arial";
    font-weight: 700;
  }

  &+span {
    margin-top: 1rem !important;
  }
}

.platform_mediaData {
  position: relative;
  width: 90%;
  height: auto;
  margin: 0.6rem auto 0;
  padding-top: 0.8rem;
  padding-bottom: 0.65rem;
  box-sizing: border-box;
  background-color: var(--white);
  border-radius: 0.1rem;
  overflow: hidden;
  text-align: center;

  .mediaDataT1 {
    width: 90%;
    margin: 0 auto;
    font-size: 0.24rem;
    line-height: 0.3rem;
    color: var(--yellow12);
    font-family: "Black";
  }

  .desc1 {
    font-size: 0.2rem;
    color: var(--gray11);
    line-height: 0.24rem;
    font-family: "Arial";
    margin: 0.2rem auto;
  }

  .input_box {
    position: relative;
    width: 5.6rem;
    height: auto;
    border-radius: 0.1rem;
    overflow: hidden;
    display: flex;
    align-items: center;
    margin: 0.1rem auto;
  }

  input {
    flex: 1;
    width: 100%;
    height: 0.56rem;
    font-size: 0.18rem;
    line-height: 0.56rem;
    color: var(--dark1);
    font-family: "Regular";
    text-align: left;
    box-sizing: border-box;
    padding: 0 0.4rem;
    background: var(--gray26);
    border: none;
    outline: none;
  }

  .confirm {
    width: 1.34rem;
    height: 0.56rem;
    font-size: 0.2rem;
    line-height: 0.56rem;
    color: var(--white);
    font-family: "Black";
    background-color: var(--yellow20);
  }

  .tip {
    width: 5.5rem;
    font-size: 0.19rem;
    line-height: 0.24rem;
    color: var(--red6);
    font-family: "Regualr";
    margin: 0.3rem auto;
    text-align: left;
    padding-left: 0.28rem;
    box-sizing: border-box;
    text-indent: -0.28rem;

    i {
      position: relative;
      display: inline-block;
      width: 0.18rem;
      height: 0.18rem;
      margin-right: 0.1rem;
      margin-bottom: 0.02rem;
      vertical-align: middle;
      background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAARCAMAAAAMs7fIAAAATlBMVEUAAADzNjbzNjbzNjbzNjbzNjbzNjbzNjbzNjbzNjbzNjbzNjbzNjbzNjbzNjbzNjbzNjbzNjbzNjbzNjbzNjbzNjbzNjbzNjbzNjbzNjZf3LrHAAAAGXRSTlMABfL2qzqzr0ZCTOSeZtPRv7p8cFgpIx4ZpDEUMQAAAGBJREFUGNNNyEcWgCAMBcAf7Cj2lvtf1AeExFkOdjcRimGcHzjmjkpUzAcmjqXhLlCdS6IBSmloeQkrltCSUD7OShZ9xVFL/3CblEZDdSoLQMrCasyhFbCksPJ4ww1zeny77wlwVqbkmwAAAABJRU5ErkJggg==) no-repeat center/contain;
    }
  }

  .meidaData {
    position: relative;
    width: 5.6rem;
    height: 2.8rem;
    margin: 0.14rem auto 0;
    
    img {
      position: absolute;
      width: 2.55rem;
      height: 1.43rem;
      top: 0;
      left: 0;
      object-fit: cover;
    }

    .title {
      position: absolute;
      left: 2.8rem;
      top: 0;
      width: 2.64rem;
      font-size: 0.22rem;
      line-height: 0.28rem;
      color: var(--black5);
      font-family: "Bold";
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      text-align: left;

      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      white-space: break-spaces;
    }

    .time {
      position: absolute;
      left: 2.8rem;
      top: 1.16rem;
      font-size: 0.18rem;
      line-height: 0.22rem;
      color: var(--gray12);
      font-family: "Reguale";
    }

    ul {
      position: absolute;
      width: 100%;
      height: auto;
      top: 1.84rem;
      left: 0;
      font-size: 0;
      line-height: 0;
      margin-top: 0 !important;
      text-align: left;

      li {
        position: relative;
        display: inline-block;
        width: 1.64rem;
        height: 0.64rem;
        text-align: left;
        padding-left: 0.25rem;
        box-sizing: border-box;
        margin: 0;
        border-radius: 0;
        border: none;

        &:nth-child(1) {
          padding: 0;
        }

        & p:nth-child(1) {
          font-size: 0.18rem;
          line-height: 0.18rem;
          color: var(--black5);
          font-family: "Black";
          white-space: nowrap;
        }

        & p:nth-child(2) {
          font-size: 0.36rem;
          line-height: 0.36rem;
          margin-top: 0.12rem;
          color: var(--yellow12);
          font-family: "Bold";
        }

        &::after {
          content: "";
          position: absolute;
          right: 0;
          top: 50%;
          height: 0.58rem;
          width: 1px;
          background-color: var(--gray28);
          margin-top: -0.29rem;
        }
      }
    }

    .clean {
      position: absolute;
      width: 0.2rem;
      height: 0.25rem;
      right: 0.18rem;
      top: 1.14rem;
      background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAZCAMAAAAL146mAAAASFBMVEUAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACrhKybAAAAGHRSTlOZAFF9DXQyCJR6WyZtVz8sj4dvZUlFOBO/T6+aAAAAc0lEQVQY083OSRKDMAxE0W4rAQ9A5uT+N41sFQXCF+AvulRvJbA2hNrCllFE62eURIP1qPcXVmyhFsAujJdDRNeMIA7ucwE/jiYSDHq8Jh3JOteVSJ0bpae0p1FnoJyB0vHV4ihknec7brRllB1JpeJoIf+SRwOAWrecSwAAAABJRU5ErkJggg==) no-repeat center/contain;
    }
  }

}

.video_platform {
  @include application_platform;
}