.task_nav {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 52px auto 85px;
}

.task_nav.fire {
  width: 1460px;
  position: absolute;
  left: 50%;
  top: 0px;
  margin-left: -730px;
}

@media screen and (max-width: 1700px) {
  .task_nav.fire {
    left: 0;
    margin-left: 0;
  }
}

.task_num {
  position: relative;
  width: 67px;
  height: 67px;
  text-align: center;
  line-height: 67px;
  font-size: 43px;
  font-family: "Black";
  color: var(--yellow22);
  border-radius: 50%;
  border: 3px var(--yellow22) solid;

  span {
    position: absolute;
    top: 80px;
    width: 150px;
    left: 50%;
    font-size: 16px;
    line-height: 18px;
    color: var(--yellow22);
    font-family: "Black";
    transform: translateX(-50%);
  }
}

.task_num.active {
  color: var(--white);
  border-color: var(--white);

  span {
    color: var(--white);
  }
}

.task_line {
  width: 115px;
  height: 4px;
  background-color: var(--yellow22);
}

.task_line.active {
  background-color: var(--white);
}