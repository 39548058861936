.afk-content {
  .head_contain {
    background: var(--black5) url(~@/assets/images/afk2/nav-bg.jpg) no-repeat center/cover !important;

    .head_nav {
      ul {
        background: url(~@/assets/images/afk2/nav-bg2.jpg) no-repeat center/cover;
      }

      li {
        border-bottom: 1px rgba(255, 255, 255, 0.2) dashed;
      }

      li p {
        color: var(--white);
      }

      li:hover p {
        color: var(--yellow3);
      }
    }

    .nav_list a {
      color: var(--white);
    }
  }

  .footer_box {
    background: var(--gray53) url(~@/assets/images/afk2/nav-bg.jpg) no-repeat center/cover;
  }

  .sing_up_main {

    .title {
      line-height: 9999px;
      overflow: hidden;
    }

    .title2 {
      color: #635757;
    }

    .desc {
      display: none;
    }

    .require_box,
    .sing_box {
      position: relative;
      background: url(~@/assets/images/afk2/sign_pc_box.png) no-repeat center/cover;

      span {
        color: var(--black9);

        span:hover {
          color: var(--yellow36);
        }
      }

      i {
        border-color: var(--black9);
      }

      i.active {
        border-color: var(--black9);
        background-color: var(--yellow35);
      }

      .sign_other {
        color: var(--yellow5);
      }

      input {
        background-color: var(--gray4);
      }
    }

    .line1 {
      position: absolute;
      background: url(~@/assets/images/afk2/line1.png) no-repeat center center;
      background-size: 100% 100%;
    }

    .line2 {
      position: absolute;
      background: url(~@/assets/images/afk2/line2.png) no-repeat center center;
      background-size: 100% 100%;
    }

    .line3 {
      position: absolute;
      background: url(~@/assets/images/afk2/line3.png) no-repeat center center;
      background-size: 100% 100%;
    }

    .line4 {
      position: absolute;
      background: url(~@/assets/images/afk2/line4.png) no-repeat center center;
      background-size: 100% 100%;
    }

    .star1,
    .star2,
    .star3,
    .star4 {
      position: absolute;
      background: url(~@/assets/images/afk2/star.png) no-repeat center/contain;
    }
  }

  .wrap, .admin_contain, .information_content {
    background-color: var(--gray50);
  }

  .home_contain>div,
  .gamefeed_box,
  .art_assets_box,
  .upload_box,
  .manage_content,
  .earnings_content,
  .detail_box,
  .detail_content,
  .rewardTableBox,
  .information_content_box,
  .information_base {
    background-color: var(--gray49);
  }

  .information_content_box1,
  .information_base input,
  .information_birthday li,
  .information_content_box li input,
  .information_country li,
  .information_language,
  .information_game_item,
  .information_name,
  .information_media_item {
    background-color: var(--white) !important;
  }

  .information_level {
    background-color: var(--gray33);
  }

  .user_welcome {

    .level_box {
      background: none;
      border: none;
    }

    .welcome_txt p:not(.lv_name) {
      color: var(--black15);
    }

    .comm_icons i:not(.hr) {
      background-image: url(~@/assets/images/afk2/mideo-icon-afk.png);
    }
  }

  .message_title p,
  .content_title,
  .content_subtitle,
  .total_earnings_title,
  .total_earnings_subtitle {
    color: var(--black15) !important;
  }

  .message_title+ul p {
    color: var(--black15);
  }

  .none_message {
    color: var(--gray11);
  }

  .content_subtitle+p,
  .total_earnings_subtitle+p:not(.earnings_none) {
    color: var(--yellow42) !important;
  }

  .question-icon {
    background-color: var(--green10) !important;
  }

  .game_feed_more,
  .manage_more:hover div div {
    background-color: var(--gray2) !important;
  }

  .earnings_content>ul {
    background-color: var(--white);
  }

  .list-menu {
    .ant-menu {
      background-color: var(--gray51);
    }

    li {
      
      &:not(.ant-menu-item-selected, .ant-menu-item-active) {
        span {
          color: var(--gray52) !important;
        }
      }
    }
  }

  .list-menu-date {
    margin-right: 10px;
  }

  .ant-picker, .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background: none;
    border: 1px #b1ae9e solid !important;
    
    background-color: var(--gray49);
    box-shadow: none;
  }

  .ant-radio-button-wrapper {
    background: none;
    border: 1px #b1ae9e solid;
    margin-right: 6px;
    color: #b1ae9e;
    font-size: 18px;
    font-family: "Bold";

    &::before {
      display: none;
    }
  }

  .ant-radio-button-wrapper-checked {
    background: #f0a22e;
    color: #FFF;
    border-color: #f0a22e !important;
    box-shadow: none;
  }

  .upLoad_tip {
    &.vi {
      background: url(~@/assets/images/afk2/upload-tip-vi.png) no-repeat center/contain;
    }
    &.th {
      background: url(~@/assets/images/afk2/upload-tip-th.png) no-repeat center/contain;
    }
    &.id {
      background: url(~@/assets/images/afk2/upload-tip-id.png) no-repeat center/contain;
    }
    &.jp {
      background: url(~@/assets/images/afk2/upload-tip-jp.png) no-repeat center/contain;
    }
    &.sk {
      background: url(~@/assets/images/afk2/upload-tip-sk.png) no-repeat center/contain;
    }
    &.zh-tw {
      background: url(~@/assets/images/afk2/upload-tip-tw.png) no-repeat center/contain;
    }
  }

}

.afk-content-pc {

  .head_contain {

    .nav_list a {
      color: var(--white);
    }

    .back_btn {
      color: var(--white);
    }

    .gameList .change_tip {
      background: url(~@/assets/images/afk2/changeTip2.png) no-repeat center/contain;
    }

    .head_nav {
      ul {
        border-radius: 10px;
      }
    }
  }

  .sing_up_main {
    background: var(--gray50) url(~@/assets/images/afk2/sign_pc_bottom.png) no-repeat bottom/contain;
    margin-bottom: -160px;
    height: 125vh;

    .title {
      height: 130px;
      background: url(~@/assets/images/afk2/sign_pc_title.png) no-repeat center/contain;
    }


    .require_box,
    .sing_box {
      margin-bottom: 200px;
    }

    .line1 {
      height: 2px;
      width: calc(100% - 60px);
      top: 10px;
      left: 40px;
    }

    .line2 {
      height: 2px;
      width: calc(100% - 40px);
      bottom: 10px;
      left: 20px;
    }

    .line3 {
      width: 2px;
      height: calc(100% - 52px);
      top: 32px;
      left: 10px;
    }

    .line4 {
      width: 2px;
      height: calc(100% - 37px);
      top: 14px;
      right: 10px;
    }

    .star1,
    .star2,
    .star3,
    .star4 {
      width: 20px;
      height: 18px;
    }

    .star1 {
      top: 3px;
      left: 6px;
    }

    .star2 {
      top: 3px;
      right: 1px;
    }

    .star3 {
      bottom: 3px;
      left: 4px;
    }

    .star4 {
      bottom: 3px;
      right: 1px;
    }
  }

  .sing_up_main.id {
    .title {
      background: url(~@/assets/images/afk2/sign_pc_title_id.png) no-repeat center/contain;
    }
  }

  .sing_up_main.th {
    .title {
      background: url(~@/assets/images/afk2/sign_pc_title_th.png) no-repeat center/contain;
    }
  }

  .sing_up_main.vi {
    .title {
      background: url(~@/assets/images/afk2/sign_pc_title_vi.png) no-repeat center/contain;
    }
  }

  .sing_up_main.jp {
    .title {
      background: url(~@/assets/images/afk2/sign_pc_title_jp.png) no-repeat center/contain;
    }
  }

  .sing_up_main.sk {
    .title {
      background: url(~@/assets/images/afk2/sign_pc_title_sk.png) no-repeat center/contain;
    }
  }

  .sing_up_main.zh-tw {
    .title {
      background: url(~@/assets/images/afk2/sign_pc_title_tw.png) no-repeat center/contain;
    }
  }

  .admin_nav .ant-menu-inline.ant-menu-root .ant-menu-item {
    transition: none;
    padding: 4px 0 !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.04);
  }

  .admin_nav .ant-menu-inline .ant-menu-item:not(:last-child) {
    margin-bottom: 0;
  }

  .admin_nav .ant-menu-item-selected {
    background: url(~@/assets/images/afk2/nac-active.jpg) no-repeat center/cover;
    border: none !important;
  }

  .admin_nav .subLink {
    width: 336px;
    height: 72px;
    background: url(~@/assets/images/afk2/admin-nav-upload.png) no-repeat center/contain;
    overflow: hidden;

    a {
      font-size: 0;
      line-height: 999px;
    }

    &.en {}
  
    &.id {
      background: url(~@/assets/images/afk2/admin-nav-upload-id.png) no-repeat center/contain;
    }
  
    &.th {
      background: url(~@/assets/images/afk2/admin-nav-upload-th.png) no-repeat center/contain;
    }
  
    &.vi {
      background: url(~@/assets/images/afk2/admin-nav-upload-vi.png) no-repeat center/contain;
    }
  
    &.jp {
      background: url(~@/assets/images/afk2/admin-nav-upload-jp.png) no-repeat center/contain;
    }
  
    &.sk {
      background: url(~@/assets/images/afk2/admin-nav-upload-sk.png) no-repeat center/contain;
    }

    &.zh-tw {
      background: url(~@/assets/images/afk2/admin-nav-upload-tw.png) no-repeat center/contain;
    }
  }

  .information_content_box1 {
    background-color: var(--gray33) !important;
  }

  .message_title p {
    font-size: 22px;
  }
}

.afk-content-m {
  
  .head_contain {

    .head_nav {
      ul {
        border-radius: 0.14rem;
      }
    }
  }

  .footer_box {
    background: url(~@/assets/images/afk2/nav-bg.jpg) no-repeat center/cover;
  }

  .sing_up_main {
    background: var(--gray50) url(~@/assets/images/afk2/sign_m_bottom.jpg) no-repeat bottom/contain;

    .title {
      width: 95%;
      margin: 0 auto;
      height: 1.3rem;
      background: url(~@/assets/images/afk2/sign_m_title.png) no-repeat center/contain;
    }

    .require_box,
    .sing_box {
      margin-bottom: 2rem;
    }

    .line1 {
      height: 0.02rem;
      width: calc(100% - .60rem);
      top: .10rem;
      left: .40rem;
    }

    .line2 {
      height: 0.02rem;
      width: calc(100% - .40rem);
      bottom: .10rem;
      left: .20rem;
    }

    .line3 {
      width: 0.02rem;
      height: calc(100% - .52rem);
      top: .32rem;
      left: .10rem;
    }

    .line4 {
      width: 0.02rem;
      height: calc(100% - .37rem);
      top: .14rem;
      right: .10rem;
    }

    .star1,
    .star2,
    .star3,
    .star4 {
      width: .20rem;
      height: .18rem;
    }

    .star1 {
      top: 0.03rem;
      left: 0.06rem;
    }

    .star2 {
      top: 0.03rem;
      right: 0.01rem;
    }

    .star3 {
      bottom: 0.03rem;
      left: 0.04rem;
    }

    .star4 {
      bottom: 0.03rem;
      right: 0.01rem;
    }
  }

  .sing_up_main.id {
    .title {
      background: url(~@/assets/images/afk2/sign_pc_title_id.png) no-repeat center/contain;
    }
  }

  .sing_up_main.th {
    .title {
      background: url(~@/assets/images/afk2/sign_pc_title_th.png) no-repeat center/contain;
    }
  }

  .sing_up_main.vi {
    .title {
      background: url(~@/assets/images/afk2/sign_pc_title_vi.png) no-repeat center/contain;
    }
  }

  .sing_up_main.jp {
    .title {
      background: url(~@/assets/images/afk2/sign_pc_title_jp.png) no-repeat center/contain;
    }
  }

  .sing_up_main.sk {
    .title {
      background: url(~@/assets/images/afk2/sign_pc_title_sk.png) no-repeat center/contain;
    }
  }

  .sing_up_main.zh-tw {
    .title {
      background: url(~@/assets/images/afk2/sign_pc_title_tw.png) no-repeat center/contain;
    }
  }

  .growth_nav {
    background-color: var(--gray49);
  }

  .admin_nav .active {
    background-color: #6fac73;
  }

  .none_message {
    &.id, &.vi {
      font-size: 0.5rem;
    }
  }

  .list-menu.vi {
    span.ant-menu-title-content {
      font-size: 0.16rem;
    }
  }
}


// application 采用mixin混入
@mixin mudule_title_color {

  .module_title,
  .module_desc {
    color: var(--black15) !important;
  }
}

@mixin application_afk {
  background: var(--gray50);
  @include mudule_title_color;

  .logout {
    background: url(~@/assets/images/afk2/logout.png) no-repeat center/contain;
  }

  .title1 {
    height: 0.43rem;
    line-height: 9999px;
    overflow: hidden;
    background: url(~@/assets/images/afk2/application_pc_t1.png) no-repeat center/contain;
  }
  
  .title1.id {
    background: url(~@/assets/images/afk2/application_pc_id.png) no-repeat center/contain;
  }
  
  .title1.th {
    background: url(~@/assets/images/afk2/application_pc_th.png) no-repeat center/contain;
  }
  
  .title1.vi {
    background: url(~@/assets/images/afk2/application_pc_vi.png) no-repeat center/contain;
  }

  .title1.jp {
    background: url(~@/assets/images/afk2/application_pc_jp.png) no-repeat center/contain;
  }

  .title1.sk {
    background: url(~@/assets/images/afk2/application_pc_sk.png) no-repeat center/contain;
  }

  .title1.zh-tw {
    background: url(~@/assets/images/afk2/application_pc_tw.png) no-repeat center/contain;
  }

  .user_account {
    margin-top: 0.4rem;
    color: var(--black13);
  }

  .application_detail {
    color: var(--yellow23);
  }

  >div:not(.user_data) {
    background-color: var(--gray49) !important;
  }

  .create_type li:not(.active) {
    color: var(--yellow41);
    background: url(~@/assets/images/afk2/application_pc_type.png) no-repeat center/contain
  }

  .game_type ul li:not(.active) {
    border-color: var(--yellow23);
  }

  .user_data {

    li,
    input {
      background-color: var(--gray49);
    }

    li,
    input,
    select {
      color: var(--gray32);
    }

    li>p,
    .real_box p,
    .language p,
    .country p {
      color: var(--black15);
    }
  }

  .submit::before {
    width: 5.57rem;
    height: 1.7rem;
    top: -1.7rem;
    margin-left: -2.78rem;
    background: url(~@/assets/images/afk2/submit_pet2.png) no-repeat top/cover
  }

}

@mixin application_platform {
  @include mudule_title_color;

  li {
    border-color: var(--black16);
  }

  li.active {
    background-color: var(--white);
  }

  .step1 {
    border: none;
    width: 1.45rem;
    height: 1.1rem;
    background: url(~@/assets/images/afk2/step_bg.jpg) no-repeat center/cover;

    span {
      top: 0.07rem;
      left: 0.1rem
    }
  }
}

@mixin application_afk_m {
  @include application_afk;

  .module_desc {
    color: var(--dark1) !important;
  }

  .title1 {
    height: 0.78rem;
    background: url(~@/assets/images/afk2/application_m_t1.png) no-repeat center/contain;
  }
  
  .title1.id {
    background: url(~@/assets/images/afk2/application_m_id.png) no-repeat center/contain;
  }
  
  .title1.th {
    background: url(~@/assets/images/afk2/application_m_th.png) no-repeat center/4.91rem;
  }
  
  .title1.vi {
    background: url(~@/assets/images/afk2/application_m_vi.png) no-repeat center/contain;
  }

  .title1.jp {
    background: url(~@/assets/images/afk2/application_m_jp.png) no-repeat center/contain;
  }

  .title1.zh-tw {
    background: url(~@/assets/images/afk2/application_pc_tw.png) no-repeat center/4rem;
  }

  .user_account {
    margin-top: 0;
  }

  .create_type {
    height: 3.3rem;

    ul {
      margin-top: 0.3rem;
    }

    li {
      height: 0.6rem;
      background-size: 100% 90%;
    }
  }

  .submit {
    margin-top: 1.7rem;
  }

  .submit::before {
    height: 2rem;
    top: -1.88rem;
  }
}