.sing_up_main {
  width: 100%;
  min-height: 950px;
  padding: 80px 0;
  box-sizing: border-box;
  text-align: center;
  // background: #f6b92e linear-gradient(20deg, #eb990a, #f6ba2f);


  .title {
    font-size: 46px;
    line-height: 80px;
    color: var(--white);
    font-family: "Black";
    text-transform: uppercase;
  }

  .desc {
    font-size: 24px;
    line-height: 30px;
    color: var(--white);
    font-family: "Regular";
  }

  .require_box {
    position: relative;
    width: 750px;
    height: auto;
    border-radius: 10px;
    margin: 18px auto 0;
    padding: 85px 85px;
    box-sizing: border-box;
    background-color: var(--white);

    ul {
      width: 100%;
      height: auto;
      text-align: left;
    }

    li {
      position: relative;
      width: 100%;
      padding-left: 40px;
      margin-bottom: 40px;
      box-sizing: border-box;
      cursor: pointer;

      &:last-child {
        margin-bottom: 20px;
      }
    }

    i {
      position: absolute;
      width: 26px;
      height: 26px;
      left: 0;
      top: 5px;
      box-sizing: border-box;
      border: 2px #a1a1a1 solid;
      border-radius: 5px;
      background: transparent;
      transition: background-color .2s, border-color .2s;
    }

    i.active {
      background: var(--green3) url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAALCAYAAACgR9dcAAAAqElEQVQokZ2RrQoCQRRGF8Gy1WiwWgWzTbCaxFfYYjdsEex2o8FqFPEVfAMR4xaDGGTLWQbu4Mewg+xemGHuuXMu85MAbUYOPNqIGRZNxQW/ODURZyJeHXOwC0z/iGMRb567aWPwEhGHQGl77kCq8lK6HgKxD7ytVgA9rfvFShrsjHWAp7EvMAhPpclWGqyBs+SjuiuFYC+Cj0nsIevgEfgAL2Ae/QFIKiaAGR7xXNbLAAAAAElFTkSuQmCC) no-repeat center center;
      border-color: var(--green3);
    }

    span {
      font-size: 24px;
      line-height: 36px;
      color: var(--black12);
      font-family: "Regular";

      span, a {
        color: var(--dark1);
        text-decoration: underline;

        &:hover {
          color: var(--yellow40);
        }
      }
    }

    .got_btn {
      width: 272px;
      height: 64px;
      font-size: 19px;
      line-height: 65px;
      color: var(--white);
      text-align: center;
      margin: 0 auto 24px;
      font-family: "Bold";
      background: linear-gradient(135deg, #ed942e, #f6b92e);
      filter: grayscale(1);
      -webkit-filter: grayscale(1);
      border-radius: 8px;
      transition: transform .5s;
    }

    .got_btn.active {
      filter: none;
      -webkit-filter: none;
      cursor: pointer;
      filter: drop-shadow(0px 5px 10px #ffd391);
    }

    .got_btn.active:hover {
      transform: translateY(-5px);
    }

    .sign_other {
      width: 100%;
      font-size: 20px;
      line-height: 24px;
      color: var(--black12);
      font-family: "Regular";
      text-align: center;

      a {
        text-decoration: underline;
        font-weight: bold;
      }
    }
  }

  .sing_box {
    width: 750px;
    height: 675px;
    border-radius: 10px;
    margin: 18px auto 0;
    padding: 70px 85px 0;
    box-sizing: border-box;
    background-color: var(--white);

    input {
      width: 100%;
      height: 64px;
      color: var(--black1);
      font-size: 20px;
      line-height: 64px;
      text-align: left;
      padding: 0 45px;
      border: none;
      box-sizing: border-box;
      border-radius: 7px;
      font-family: "Reuglar";
      background: var(--gray5);
    }

    .slide1 {
      margin-bottom: 26px;
    }
    
    .password_box {
      position: relative;
      width: 100%;

      input {
        padding-right: 92px;
      }
    }

    .input_box {
      position: relative;
      width: 100%;
      height: auto;
    }

    .num {
      position: absolute;
      right: 20px;
      top: 0;
      line-height: 64px;
      color: var(--gray48);
      font-size: 20px;
    }

    .hideIcon {
      position: absolute;
      width: 22px;
      height: 22px;
      right: 40px;
      top: 23px;
      background: url(~@/assets/images/eyes.png) no-repeat top/cover;
      background-position: center 5px;
      cursor: pointer;
    }

    .password_box.active .hideIcon {
      background-position: bottom;
    }

    .password_require {
      width: 100%;
      padding: 8px 0 8px 25px;
      text-align: left;

      p {
        font-size: 18px;
        line-height: 20px;
        color: var(--dark1);
        font-family: "Regular";
      }

      i {
        display: inline-block;
        width: 22px;
        height: 18px;
        vertical-align: top;
      }

      i.active {
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAJCAYAAAAGuM1UAAAAyUlEQVQYlYXRrUpFURAF4O8Ku4jRp7hNUPQV3Dtd1OIDaPAFTIIgajOZTTbBNCa7oA8gWCwWu+ls5Mi53ityFM6CKTNrftaaUdu2hlBLWcQ7RguD7G88YQnngw21lAeMcZoiTjQ57zc5n3Wn9aPJ+a7JuW1yvpzXug1bOKylHPcmX2MTNyniYJ7vGrbxjKNayt6MfIFd3KeInd+Dpi7VUjpBr1jGLSZ4TBHrfU1T0SniAyv4nJFfsPGfCT8upYg3rOIKayni74PwBSHaUAp3fYH0AAAAAElFTkSuQmCC) no-repeat left 7px;
      }
    }

    .email_box {
      position: relative;
      width: 100%;

      input {
        padding-right: 215px
      }

      .code_btn, .count_down {
        position: absolute;
        right: 0;
        top: 0;
        width: 180px;
        height: 64px;
        background-color: var(--blue1);
        border-radius: 0 7px 7px 0;
        cursor: pointer;
      }

      p {
        font-size: 16px;
        line-height: 64px;
        color: var(--white);
        text-align: center;
        font-family: "Bold";
        text-transform: uppercase;
      }

      .count_down {
        background-color: var(--gray9);
        p {
          font-size: 18px;
        }
      }
    }

    .accept {
      width: 100%;
      font-size: 19px;
      line-height: 22px;
      color: var(--dark1);
      font-family: "Regular";
      text-align: left;
      box-sizing: border-box;
      padding-left: 25px;

      i {
        float: left;
        position: relative;
        width: 20px;
        height: 20px;
        line-height: 20px;
        margin-right: 5px;
        border-radius: 50%;
        border: var(--gray31) 1px solid;
        vertical-align: top;
        margin-left: -30px;
        cursor: pointer;
      }

      i.active::after {
        content: "";
        position: absolute;
        width: 10px;
        height: 10px;
        top: 50%;
        left: 50%;
        margin-top: -5px;
        margin-left: -5px;
        background-color: var(--yellow21);
        border-radius: 50%;
      }

      span em {
        font-style: normal;
        font-family: "Bold";
        cursor: pointer;

        a {
          color: var(--dark1);
        }

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .next_btn {
      width: 272px;
      height: 66px;
      font-size: 19px;
      line-height: 65px;
      color: var(--white);
      text-align: center;
      margin: 30px auto 5px;
      font-family: "Bold";
      background: linear-gradient(135deg, #ed942e, #f6b92e);
      filter: grayscale(1);
      -webkit-filter: grayscale(1);
      border-radius: 8px;
      transition: transform .5s;
    }

    .next_btn.active {
      filter: none;
      -webkit-filter: none;
      cursor: pointer;
      filter: drop-shadow(0px 5px 10px #ffd391);
    }

    .next_btn.active:hover {
      transform: translateY(-5px);
    }

    .sign_in_tip {
      width: 100%;
      font-size: 16px;
      line-height: 24px;
      color: var(--yellow5);
      font-family: "Regular";
      text-align: center;

      a {
        color: var(--yellow5);
        font-family: "Black";
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
}