.content_box {
  width: 100%;
  height: 360px;
  border-radius: 8px;
  padding: 50px 80px;
  background-color: var(--white);
  box-sizing: border-box;
  text-align: left;
  margin-top: 35px;

  .content_title {
    font-size: 26px;
    line-height: 26px;
    color: var(--black4);
    font-family: "Black";
    text-transform: uppercase;

    i {
      display: inline-block;
      width: 25px;
      height: 25px;
      color: var(--white);
      font-size: 18px;
      line-height: 25px;
      font-family: "Black";
      margin-left: 15px;
      background-color: var(--yellow13);
      text-align: center;
      vertical-align: middle;
      border-radius: 50%;
      font-style: normal;
      cursor: pointer;
    }
  }

  .media_list {
    margin-top: 10px;
  }

  .overview_list {
    position: relative;
    margin-top: 10px;
    margin-left: -20px;

    span {
      font-size: 18px;
      font-family: "Black";
      color: var(--black4);
    }

    :global {

      .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after {
        border-color: var(--yellow13) !important;
        box-shadow: none;
        outline: none;
      }
  
      .ant-menu-item-selected span {
        color: var(--yellow13);
      }
    }

    .date {
      position: absolute;
      right: 0;
      top: 8px;
    }
  }

  .data_list {
    width: 100%;
    height: auto;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;

    li {
      width: 25%;
      height: auto;
      flex: 1;
      margin-right: 38px;
      border-right: 1px var(--gray16) solid;
    }
  
    li:last-child {
      border-right: none;
      margin: 0;
    }
  
    .content_subtitle {
      display: inline-block;
      font-size: 18px;
      line-height: 26px;
      height: 52px;
      color: var(--black4);
      font-family: "Black";
      text-transform: uppercase;
    }
  
    .num {
      font-size: 40px;
      line-height: 56px;
      color: var(--yellow13);
      font-family: "Bold";
    }
  
    .bite {
      display: inline-block;
      font-size: 17px;
      color: var(--red4);
      line-height: 28px;
      font-family: "Bold";
      padding: 0 18px;
      box-sizing: border-box;
      background-color: var(--gray22);
      border-radius: 28px;
      margin-top: 5px;
    }
  }

}