.total_earnings_box {
  width: 100%;
  min-height: 310px;
  margin-top: 35px;
  border-radius: 8px;
  background-color: var(--white);
  padding: 50px 80px;
  box-sizing: border-box;
  text-align: left;

  .total_earnings_title {
    font-size: 26px;
    line-height: 26px;
    color: var(--black4);
    font-family: "Black";
    text-transform: uppercase;

    .question {
      display: inline-block;
      width: 25px;
      height: 25px;
      color: var(--white);
      font-size: 18px;
      line-height: 25px;
      font-family: "Black";
      margin: 0 15px;
      background-color: var(--yellow13);
      text-align: center;
      vertical-align: middle;
      border-radius: 50%;
      font-style: normal;
      cursor: pointer;
    }


    .hide {
      display: inline-block;
      width: 28px;
      height: 28px;
      vertical-align: middle;
      cursor: pointer;
      background: url(~@/assets/images/eyes.png) no-repeat top/cover;
      background-position-y: 8px;
    }

    .hide.active {
      background-position-y: bottom;
    }
  }

  ul {
    width: 100%;
    height: auto;
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
  }

  li {
    width: 33%;
    height: auto;
    flex: 1;
    padding-right: 20px;
    margin-right: 38px;
    border-right: 1px var(--gray16) solid;
    box-sizing: border-box;
    word-wrap: break-word;
  }

  li:last-child {
    border-right: none;
    margin: 0;
  }

  .total_earnings_subtitle {
    font-size: 18px;
    line-height: 26px;
    color: var(--black4);
    font-family: "Black";
    text-transform: uppercase;
  }

  .num {
    font-size: 34px;
    line-height: 56px;
    color: var(--yellow13);
    font-family: "Bold";

    .subtxt1 {
      font-size: 22px;
      color: var(--black4);
      font-family: "Black";
    }

    .subtxt2 {
      font-size: 22px;
      font-family: "Black";
    }
  }

  .none {
    width: 100%;
    font-size: 22px;
    line-height: 24px;
    padding-top: 16px;
    color: var(--gray15);
    font-family: "Black";
  }
}