@import "~@/style/afkStyle.scss";

.application_box {
  position: relative;
  width: 100%;
  height: auto;
  padding-top: .85rem;
  box-sizing: border-box;
  text-align: center;
  background: var(--black5) url(~@/assets/images/bg1.jpg) no-repeat top/cover;

  .logout {
    position: absolute;
    width: .93rem;
    height: .93rem;
    top: 1.55rem;
    right: .20rem;
    cursor: pointer;
    background: url(~@/assets/images/log_out.png) no-repeat center/contain;
  }

  .title1 {
    font-size: .40rem;
    line-height: .50rem;
    color: var(--white);
    font-family: "Black";
    text-transform: uppercase;
  }

  .user_account {
    font-size: .30rem;
    line-height: .48rem;
    color: var(--white);
    font-family: "Black";
    padding-top: .3rem;

    span {
      color: var(--yellow18);
    }
  }

  .application_detail {
    font-size: .30rem;
    line-height: .40rem;
    color: var(--gray30);
    padding-top: .25rem;
    font-family: "Regular";
    letter-spacing: -.01rem;
  }

  .module_title {
    width: 90%;
    font-size: .40rem;
    line-height: .5rem;
    color: var(--yellow12);
    text-transform: uppercase;
    font-family: "Black";
    margin: 0 auto;
  }

  .module_desc {
    font-size: .26rem;
    line-height: .4rem;
    color: var(--dark1);
    font-family: "Regular";
    padding-top: .2rem;

    span {
      color: var(--yellow12);
      font-family: "Black";
    }

    &.fl84 {
      margin: 0 auto;
      font-size: 0.22rem;
      padding-top: 0;

      &:nth-child(2) {
        padding-top: .2rem;
      }

      & + ul {
        margin-top: 0.4rem;
      }
    }
  }

  .real_box {
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: left;

    > div {
      width: 6.00rem;
      margin: .10rem .12rem 0;
    }

    p {
      font-size: .26rem;
      line-height: .40rem;
      color: var(--white);
      font-family: "Regular";
      text-indent: .05rem;
    }

    input {
      display: block;
      width: 100%;
      height: 0.54rem;
      font-size: .20rem;
      line-height: 0.54rem;
      padding: 0 .20rem;
      color: var(--gray25);
      font-family: "Regular";
      background: var(--black6);
      border: none;
      outline: none;
      border-radius: .1rem;
      box-sizing: border-box;
    }
  }

  .main_box {
    position: relative;
    width: 6.93rem;
    border-radius: .10rem;
    box-sizing: border-box;
    margin: .85rem auto 0;
    padding: .3rem 0;
    background-color: var(--gray26);

    ul {
      width: 100%;
      margin-top: .75rem;
      text-align: center;
      font-size: 0;
      line-height: 0;
    }

    li {
      display: inline-block;
      width: 3.64rem;
      height: .88rem;
      margin: 0 .15rem;
      font-size: .26rem;
      line-height: .88rem;
      font-family: "Bold";
      border-radius: .1rem;
      cursor: pointer;
    }
  }

  .video_platform {
    @extend .main_box;
    height: auto;
    min-height: 7.5rem;
    margin: .3rem auto 0;

    li {
      width: 1.93rem;
      height: .53rem;
      margin: 0 .08rem;
      font-size: .18rem;
      line-height: .53rem;
      font-family: "Bold";
      border-radius: .1rem;
      color: var(--black1);
      border: .03rem var(--blue2) solid;
    }

    li.active {
      border-color: var(--yellow19);
    }

    .icon {
      display: inline-block;
      width: .56rem;
      height: .43rem;
      line-height: .88rem;
      margin-right: .10rem;
      vertical-align: middle;
      transform: scale(.9);
      background: url(~@/assets/images/video_logo.png) no-repeat top/cover;
    }

    .icon1 i {
      @extend .icon;
      background-position: top;
    }

    .icon2 i {
      @extend .icon;
      background-position: center;
    }

    .icon3 i {
      @extend .icon;
      background-position: bottom;
    }

    .platform_upload {
      position: relative;
      width: 6.14rem;
      height: 3.36rem;
      margin: .35rem auto 0;
      border-radius: .10rem;
      background-color: var(--white);

      .upload_default {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
      }

      .upload_icon {
        position: absolute;
        top: 50%;
        left: 50%;
        background-color: var(--gray10);
        border-radius: .14rem
      }

      .upload_icon1 {
        @extend .upload_icon;
        width: 1.00rem;
        height: .14rem;
        margin-top: -.47rem;
        margin-left: -.50rem
      }

      .upload_icon2 {
        @extend .upload_icon;
        width: .14rem;
        height: 1.00rem;
        margin-top: -.90rem;
        margin-left: -.07rem
      }

      p {
        position: absolute;
        width: 100%;
        top: 2.2rem;
        left: 0;
        font-size: .26rem;
        color: var(--gray11);
        font-family: "Regular";
      }

      .upload_action {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        opacity: 0;
        z-index: 5;
        cursor: pointer;
      }
    }
  }

  .create_type {
    @extend .main_box;
    height: 3.7rem;

    li {
      width: 1.86rem;
      height: .53rem;
      line-height: .53rem;
      font-size: .18rem;
      color: var(--white);
      vertical-align: top;
      margin: 0 .1rem;
      background: url(~@/assets/images/btn5.png) no-repeat center/contain;
    }

    li.active {
      background: url(~@/assets/images/btn6.png) no-repeat center/contain;
    }
  }

  .game_type {
    @extend .main_box;
    min-height: 6.8rem;

    .module_desc {
      line-height: .40rem;
    }

    >ul > li {
      width: 2.73rem;
      height: .64rem;
      margin: 0 .12rem .2rem;
      position: relative;
      background-color: var(--dark2);
      border: .03rem var(--blue2) solid;
    }

    >ul > li.active {
      border-color: var(--yellow19);
    }

    li img {
      position: absolute;
      width: 80%;
      height: 80%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      pointer-events: none;
      object-fit: contain;
    }

    .game_input {
      display: block;
      width: 5.70rem;
      height: .76rem;
      font-size: .22rem;
      color: var(--black1);
      text-align: left;
      padding: 0 .34rem;
      margin: .33rem auto 0;
      box-sizing: border-box;
      border-radius: .08rem;
      border: none;
      outline: none;
      font-family: "Regular";
    }

    > input {
      margin: 0 auto .2rem !important;
    }

    .game_codeBox {
      position: relative;
      width: 5.70rem;
      margin: .33rem auto 0;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .game_input {
        width: 65%;
        flex: 1;
        margin: 0 !important;
        border-radius: 0.1rem 0 0 0.1rem;
      }
    }

    .verifiBtn,
    .verifiSubmit,
    .timeLoad {
      display: block;
      width: 53%;
      height: .76rem;
      line-height: 0.76rem;
      color: var(--white);
      font-size: 0.2rem;
      background-color: var(--blue1);
      text-align: center;
      border: none;
      font-family: "Bold";
      cursor: pointer;
      text-transform: uppercase;
      border-radius: 0 0.1rem 0.1rem 0;
    }

    .verifiSubmit {
      width: 3rem;
      height: 0.7rem;
      line-height: 0.7rem;
      font-size: 0.22rem;
      margin: 0.5rem auto;
      border-radius: 0.1rem;
    }

    .timeLoad {
      background-color: var(--gray9);
      cursor: default;
    }

    .codeDesc {
      position: absolute;
      right: 0.02rem;
      bottom: -0.34rem;
      font-size: 0.19rem;
      line-height: 0.3rem;
      color: var(--dark1);
      font-family: "Regular";
      text-decoration: underline;
      cursor: pointer;
    }

    .completed {
      display: inline-block;
      height: 0.7rem;
      line-height: 0.7rem;
      font-size: 0.22rem;
      margin: 0 auto 0.5rem;
      color: var(--dark1);
      font-family: "Regular";
    }

    .slice {
      width: 85%;
      height: 1px;
      background-color: rgba(0, 0, 0, .15);
      margin: 0 auto;
    }

    .check {
      display: inline-block;
      width: .30rem;
      height: .30rem;
      vertical-align: text-top;
      margin-right: 0.1rem;
      background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB8AAAAfCAMAAAAocOYLAAAA7VBMVEUAAAAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wD/+v8j/yMO/w4G/wby+/Lq+urI/Mhh/WE5/jkd/x3b+9u6+7qR/ZGA/YB6/Xpl/WXi++Li+uLT+9Ou+66d/J2b/JuC/YJv/W9u/G5a/VpZ/VlQ/lBC/UIu/i4Y/xi4JdcUAAAAL3RSTlMA1LfiymX08N+mijImDfnrmnFfPCoQ9tvZzcedjYBqLBgWCgTkxK+slIR2UE1GNw6xnh0AAAE0SURBVCjPddKHboNADIBhMwMJZDd7p3vZCdnN6N7t+z9OkzugjOOT0Fn6AYFk8B2V2nItl1IazaIFMXZHRV/2/DaSb04wJJu2g7mQxSi1C740ChgZL1+ikNTjuaSj2Cn/LwmTaIeuYSLDBrDrgvC2cfgLhlAS5C3RiA1yGdrxvCOajdmkFECO5TnLnAm1aP4gelyiS4UcO53d2n3ka0KrH/RUIcXO0YSe2bCY0nSBPgUUdo5fiNaH++5DGSVoIOM8Eb3ickWTbwyQoYmcc0e03V+fway3oIiu3xntzTFIugKrgq7lA9EGQ/J9gDx6Ru/zcK4cViSjYxLT2y6xlMUXREWxInDlY2G+AE9XEuQ0/OufRWtOg6ChZoS+zLQgYtCRFR71ej4DcYNywVSrhiS3rnvg+wMubYWnXJT5ZQAAAABJRU5ErkJggg==) no-repeat center/contain;
    }
  }

  .user_data {
    @extend .main_box;
    height: auto;
    background: none;

    .module_title,
    .module_desc {
      color: var(--white);
    }

    .module_desc {
      line-height: .40rem;
    }

    .year_select {
      display: flex;
      justify-content: space-between;
    }

    li {
      width: 2.15rem;
      height: .54rem;
      position: relative;
      margin: 0;
      background-color: var(--black6);

      > p {
        position: absolute;
        width: 100%;
        color: var(--white);
        font-size: .22rem;
        line-height: .3rem;
        top: -.32rem;
        font-family: "Regular";
      }

      &::after {
        position: absolute;
        content: '';
        right: .25rem;
        top: .24rem;
        width: 0;
        height: 0;
        border-top: .12rem #acacac solid;
        border-left: .06rem transparent solid;
        border-right: .06rem transparent solid;
        border-bottom: none;
      }
    }

    .user_detail {
      li:nth-child(2) {
        margin: 0 .23rem;
      }
    }

    select {
      display: block;
      width: 100%;
      height: 100%;
      font-size: .20rem;
      line-height: .2rem;
      color: var(--gray25);
      font-family: "Black";
      background: none;
      border: none;
      outline: none;
      cursor: pointer;
      appearance: none;
      text-align: center;
      padding: 0 .4rem;
      box-sizing: border-box;

      option {
        text-align: center;
      }
    }

    .country, .language {
      margin-top: .75rem;

      >p {
        font-size: .30rem;
        line-height: 1rem;
        color: var(--white);
        font-family: "Regular";
      }

      li {
        width: 5.1rem;
      }
    }
  }

  .discord {
    margin-top: .90rem;

    input {
      display: block;
      width: 5.1rem;
      height: .54rem;
      font-size: .30rem;
      line-height: .54rem;
      color: var(--white);
      margin: .40rem auto 0;
      border: .02rem var(--yellow15) solid;
      background: none;
      border-radius: .10rem;
      outline: none;
      text-align: center;
    }
  }

  .submit {
    position: relative;
    width: 5.90rem;
    height: 1.18rem;
    margin: 1.30rem auto 0;
    font-size: .34rem;
    line-height: 1.18rem;
    color: var(--white);
    font-family: "Black";
    background: url(~@/assets/images/btn7.png) no-repeat top/contain;
    transform: scale(.67);
    cursor: pointer;

    &::before {
      position: absolute;
      content: "";
      width: 1.84rem;
      height: 1.78rem;
      left: 50%;
      margin-left: -.92rem;
      top: -1.51rem;
      pointer-events: none;
      background: url(~@/assets/images/submit_pet.png) no-repeat center/contain;
    }

    &.fl84::before {
      width: 2.84rem;
      margin-left: -1.22rem;
      top: -1.78rem;
      background: url(~@/assets/images/submit_pet2.png) no-repeat top/cover;
    }
  }

  .tip {
    width: 100%;
    height: auto;
    font-size: .22rem;
    line-height: .26rem;
    color: var(--gray15);
    font-family: "Regular";
    letter-spacing: -.01rem;
    padding: .4rem 0;
    text-align: center;
    box-sizing: border-box;
    background-color: var(--black7);
  }

  :global {
    .ant-modal-confirm-title {
      font-size: .4rem;
      line-height: .4rem;
    }
    .ant-modal-body {
      text-align: left;
      padding: .5rem .35rem .24rem;
    }
    .ant-modal-confirm-body > .anticon + .ant-modal-confirm-title + .ant-modal-confirm-content {
      margin-left: 8px;
    }
    .ant-btn-farlight84-submit {
      display: block;
      margin: 0 auto;
      color: var(--white);
      height: .64rem;
      font-size: .27rem;
      margin-bottom: .3rem;
      background-color: var(--blue5);
      border-color: var(--blue5);
      text-shadow: 0 -1px 0 rgba(0,0,0,.12);
      -webkit-box-shadow: 0 2px 0 rgba(0,0,0,.045);
      box-shadow: 0 2px 0 rgba(0,0,0,.045);
    }
  }
}

@media (prefers-color-scheme: dark) {
  .application_box {
    // background: var(--black5) url(http://localhost:3000/static/media/bg1.3523a69….jpg) no-repeat top/cover;
    background: linear-gradient(to bottom, #232323, #232323);
  }
}

:global {
  .ant-modal-confirm-btns p {
    text-align: left;
    line-height: 18px;
    box-sizing: border-box;
    padding-top: 10px;
  }
}

.loadingBox {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: .10rem;
  overflow: hidden;
}

.gameLogoList {
  width: 100%;
  height: auto;
  margin: 0.2rem auto .50rem;
  display: flex;
  justify-content: center;

  .gameLogoItem {
    position: relative;
    width: 1.3rem;
    height: auto;
    margin: 0 .50rem;
    cursor: pointer;

    &.disable {
      opacity: 0.5;
      cursor: not-allowed;
    }

    &.active {
      p {
        color: var(--yellow2);
        // font-family: "Bold";
      }
    }
  }

  img {
    width: 100%;
    height: auto;
  }

  p {
    position: absolute;
    left: 50%;
    top: 1.35rem;
    font-size: .18rem;
    line-height: .22rem;
    color: var(--dark1);
    font-family: "Regular";
    white-space: nowrap;
    transform: translateX(-50%);
    text-align: center;
  }
}

.application_box.afk {
  @include application_afk_m;
}