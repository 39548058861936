.community_rules_box {
  position: relative;
  width: 6.75rem;
  height: 9.07rem;
  margin: 0 auto;
  border-radius: .10rem;
  background-color: var(--white);
}

.community_rules_box2 {
  @extend .community_rules_box;
  background: none;
  font-size: 0;
  line-height: 0;
}

.task_item {
  position: relative;
  display: inline-block;
  width: 2.18rem;
  height: 2.35rem;
  border-radius: .05rem;
  margin-bottom: .28rem;
  margin-right: .1rem;

  &:nth-child(3n), &:last-child {
    margin-right: 0;
  }

  // &.active {
  //   filter: brightness(0.7) grayscale(1);
  //   -webkit-filter: brightness(0.7) grayscale(1);
  // }

  // &:nth-child(n + 7) {

  //   &.active {
  //     filter: none;
  //     -webkit-filter: none;
  //   }
  // }

  &.none {
    display: none;
  }
}

.task_base {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border-radius: .05rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  backface-visibility: hidden;
  transition: transform 1s;

  p {
    font-family: "Black";
    text-transform: uppercase;
    margin: 0 auto;
  }
}

.active .task_base {
  backface-visibility: hidden;
}

.task_default {
  @extend .task_base;
  transform: rotateY(0deg);
  background: url(~@/assets/images/task_default_m.png) no-repeat center/cover;
  background-size: 100% 100%;

  &:hover {
    background: url(~@/assets/images/task_none_m.png) no-repeat center/cover;

    p {
      opacity: 1;
    }
  }

  p {
    width: 90%;
    color: var(--white)59d;
    font-size: .22rem;
    line-height: .26rem;
    text-align: center;
    opacity: 0;
    transition: opacity .5s
  }
}

.task_data {
  @extend .task_base;
  background-color: var(--white);
  transform: rotateY(180deg);
  overflow: hidden;

  p {
    width: 100%;
    font-size: .26rem;
    line-height: .30rem;
    color: var(--gray31);
    text-align: center;
    cursor: normal;
  }
}

.active .task_default,
.task_item:hover .task_default {
  transform: rotateY(180deg);
}

.active .task_data,
.task_item:hover .task_data {
  transform: rotateY(0);
}

.active .task_data {
  padding-left: 0;
  padding-top: .6rem;

  &::before {
    content: "";
    position: absolute;
    top: -.65rem;
    left: -.40rem;
    width: 1.50rem;
    height: 1.50rem;
    border-radius: 50%;
    background: var(--green5) url(~@/assets/images/dagou.png) no-repeat  0.55rem 0.8rem;
    background-size: .6rem auto;
  }

  p {
    text-align: center;
  }
}
