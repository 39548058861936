@import "~@/style/afkStyle.scss";

.application_box {
  position: relative;
  width: 100%;
  height: auto;
  min-height: 49.00rem;
  min-width: 16rem;
  padding-top: 1.80rem;
  padding-bottom: 1rem;
  box-sizing: border-box;
  text-align: center;
  background: var(--black5) url(~@/assets/images/bg1.jpg) no-repeat top/cover;

  .logout {
    position: absolute;
    width: .93rem;
    height: .93rem;
    top: .95rem;
    right: .50rem;
    cursor: pointer;
    background: url(~@/assets/images/log_out.png) no-repeat center/contain;
  }

  .title1 {
    width: 100%;
    min-width: 16rem;
    font-size: .56rem;
    line-height: .70rem;
    padding: .20rem 0;
    color: var(--white);
    font-family: "Black";
    text-transform: uppercase;
  }

  .user_account {
    font-size: .30rem;
    line-height: .48rem;
    color: var(--white);
    font-family: "Black";

    span {
      color: var(--yellow18);
    }
  }

  .application_detail {
    font-size: .30rem;
    line-height: .40rem;
    color: var(--gray30);
    padding-top: .85rem;
    font-family: "Regular";
  }

  .module_title {
    font-size: .50rem;
    line-height: .90rem;
    color: var(--yellow12);
    text-transform: uppercase;
    font-family: "Black";
  }

  .module_desc {
    font-size: .30rem;
    line-height: .64rem;
    color: var(--dark1);
    font-family: "Regular";

    span {
      color: var(--yellow12);
      font-family: "Black";
    }
  }

  .real_box {
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: left;

    >div {
      width: 5.00rem;
      margin: .25rem .50rem 0;
    }

    p {
      font-size: .28rem;
      line-height: .70rem;
      color: var(--white);
      font-family: "Regular";
      text-indent: .05rem;
    }

    input {
      display: block;
      width: 100%;
      height: .88rem;
      font-size: .30rem;
      line-height: .88rem;
      padding: 0 .40rem;
      color: var(--gray25);
      font-family: "Regular";
      background: var(--black6);
      border: none;
      outline: none;
      border-radius: .15rem;
      box-sizing: border-box;
    }
  }

  .main_box {
    position: relative;
    width: 15.74rem;
    border-radius: .10rem;
    box-sizing: border-box;
    margin: .85rem auto 0;
    padding: .60rem 0;
    background-color: var(--gray26);

    ul {
      width: 100%;
      margin-top: .75rem;
      text-align: center;
      font-size: 0;
      line-height: 0;
    }

    li {
      display: inline-block;
      width: 3.64rem;
      height: .88rem;
      margin: 0 .15rem;
      font-size: .26rem;
      line-height: .88rem;
      font-family: "Bold";
      border-radius: .15rem;
      cursor: pointer;
    }
  }

  .create_type {
    @extend .main_box;
    height: 4.77rem;

    li {
      width: 3.76rem;
      height: 1.02rem;
      color: var(--white);
      background: url(~@/assets/images/btn5.png) no-repeat center/contain;
    }

    li.active {
      background: url(~@/assets/images/btn6.png) no-repeat center/contain;
    }
  }

  .game_type {
    @extend .main_box;
    min-height: 7.65rem;

    .module_desc {
      line-height: .40rem;
    }

    >ul {

      li {
        position: relative;
        margin-bottom: .2rem;
        background-color: var(--dark2);
        border: .03rem var(--blue2) solid;
      }

      li.active {
        border-color: var(--yellow19);
      }

      img {
        position: absolute;
        max-width: 80%;
        max-height: 80%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: auto;
        height: auto;
        pointer-events: none;
      }
    }

    .game_input {
      display: block;
      width: 9.40rem;
      height: .96rem;
      font-size: .30rem;
      color: var(--black1);
      text-align: left;
      padding: 0 .94rem;
      margin: .33rem auto 0;
      box-sizing: border-box;
      border-radius: .08rem;
      border: none;
      outline: none;
      font-family: "Regular";
    }

    .game_codeBox {
      position: relative;
      width: 9.40rem;
      margin: .33rem auto 0;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .game_input {
        width: 65%;
        flex: 1;
        margin: 0;
        border-radius: 0.1rem 0 0 0.1rem;
      }
    }

    .verifiBtn,
    .verifiSubmit,
    .timeLoad {
      display: block;
      width: 40%;
      height: .96rem;
      line-height: 0.96rem;
      border-radius: 0 0.1rem 0.1rem 0;
      color: var(--white);
      font-size: 0.22rem;
      background-color: var(--blue1);
      text-align: center;
      border: none;
      font-family: "Bold";
      cursor: pointer;
      text-transform: uppercase
    }

    .verifiSubmit {
      width: 3rem;
      height: 0.7rem;
      line-height: 0.7rem;
      font-size: 0.22rem;
      margin: 0.5rem auto;
      border-radius: 0.1rem;
    }

    .timeLoad {
      background-color: var(--gray9);
      cursor: default;
    }

    .codeDesc {
      position: absolute;
      right: 0.02rem;
      bottom: -0.34rem;
      font-size: 0.24rem;
      line-height: 0.3rem;
      color: var(--dark1);
      font-family: "Regular";
      text-decoration: underline;
      cursor: pointer;
    }

    .completed {
      display: inline-block;
      height: 0.7rem;
      line-height: 0.7rem;
      font-size: 0.22rem;
      margin: 0 auto 0.5rem;
      color: var(--dark1);
      font-family: "Regular";
    }

    .slice {
      width: 85%;
      height: 1px;
      background-color: rgba(0, 0, 0, .15);
      margin: 0 auto;
    }

    .check {
      display: inline-block;
      width: .30rem;
      height: .30rem;
      vertical-align: text-top;
      margin-right: 0.1rem;
      background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB8AAAAfCAMAAAAocOYLAAAA7VBMVEUAAAAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wD/+v8j/yMO/w4G/wby+/Lq+urI/Mhh/WE5/jkd/x3b+9u6+7qR/ZGA/YB6/Xpl/WXi++Li+uLT+9Ou+66d/J2b/JuC/YJv/W9u/G5a/VpZ/VlQ/lBC/UIu/i4Y/xi4JdcUAAAAL3RSTlMA1LfiymX08N+mijImDfnrmnFfPCoQ9tvZzcedjYBqLBgWCgTkxK+slIR2UE1GNw6xnh0AAAE0SURBVCjPddKHboNADIBhMwMJZDd7p3vZCdnN6N7t+z9OkzugjOOT0Fn6AYFk8B2V2nItl1IazaIFMXZHRV/2/DaSb04wJJu2g7mQxSi1C740ChgZL1+ikNTjuaSj2Cn/LwmTaIeuYSLDBrDrgvC2cfgLhlAS5C3RiA1yGdrxvCOajdmkFECO5TnLnAm1aP4gelyiS4UcO53d2n3ka0KrH/RUIcXO0YSe2bCY0nSBPgUUdo5fiNaH++5DGSVoIOM8Eb3ickWTbwyQoYmcc0e03V+fway3oIiu3xntzTFIugKrgq7lA9EGQ/J9gDx6Ru/zcK4cViSjYxLT2y6xlMUXREWxInDlY2G+AE9XEuQ0/OufRWtOg6ChZoS+zLQgYtCRFR71ej4DcYNywVSrhiS3rnvg+wMubYWnXJT5ZQAAAABJRU5ErkJggg==) no-repeat center/contain;
    }
  }

  .user_data {
    @extend .main_box;
    height: auto;
    background: none;

    .module_title,
    .module_desc {
      color: var(--white);
    }

    .module_desc {
      line-height: .40rem;
    }

    li {
      position: relative;
      background-color: var(--black6);

      >p {
        position: absolute;
        width: 100%;
        color: var(--white);
        font-size: .22rem;
        line-height: .3rem;
        top: -.32rem;
        font-family: "Regular";
      }

      &::after {
        position: absolute;
        content: '';
        right: .35rem;
        top: .40rem;
        width: 0;
        height: 0;
        border-top: .16rem #acacac solid;
        border-left: .08rem transparent solid;
        border-right: .08rem transparent solid;
        border-bottom: none;
      }
    }

    select {
      display: block;
      width: 100%;
      height: 100%;
      text-align: center;
      font-size: .30rem;
      color: var(--gray25);
      font-family: "Black";
      background: none;
      border: none;
      outline: none;
      cursor: pointer;
      appearance: none;
    }

    .language,
    .country {
      margin-top: .75rem;

      >p {
        font-size: .30rem;
        line-height: 1.26rem;
        color: var(--white);
        font-family: "Regular";
      }

      li {

        width: 8.80rem;
      }
    }
  }

  .discord {
    margin-top: .90rem;

    input {
      display: block;
      width: 8.80rem;
      height: .96rem;
      font-size: .40rem;
      line-height: .96rem;
      color: var(--white);
      margin: .40rem auto 0;
      border: .02rem var(--yellow15) solid;
      background: none;
      border-radius: .10rem;
      outline: none;
      text-align: center;
    }
  }

  .submit {
    position: relative;
    width: 5.90rem;
    height: 1.18rem;
    margin: 1.90rem auto 0;
    font-size: .34rem;
    line-height: 1.18rem;
    color: var(--white);
    font-family: "Black";
    background: url(~@/assets/images/btn7.png) no-repeat top/contain;
    cursor: pointer;

    &::before {
      position: absolute;
      content: "";
      width: 1.84rem;
      height: 1.78rem;
      left: 50%;
      margin-left: -.92rem;
      top: -1.51rem;
      pointer-events: none;
      background: url(~@/assets/images/submit_pet.png) no-repeat center/contain;
    }

    &.fl84 {
      margin-top: 2.2rem;
    }

    &.fl84::before {
      width: 2.84rem;
      margin-left: -1.22rem;
      top: -1.78rem;
      background: url(~@/assets/images/submit_pet2.png) no-repeat top/cover;
    }
  }

  :global {
    .ant-modal {
      width: 60vw !important;
      text-align: left;
    }

    .ant-btn-farlight84-submit {
      display: block;
      height: 48px;
      font-size: 20px;
      padding: 0 25px;
      margin: 0 auto;
      color: var(--white);
      background-color: var(--blue5);
      border-color: var(--blue5);
      text-shadow: 0 -1px 0 rgba(0, 0, 0, .12);
      -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, .045);
      box-shadow: 0 2px 0 rgba(0, 0, 0, .045);
    }
  }
}

:global {
  .ant-modal-confirm-btns p {
    text-align: left;
    line-height: 18px;
    box-sizing: border-box;
    padding-top: 10px;
  }
}

.gameLogoList {
  width: 100%;
  height: auto;
  margin-bottom: 80px;
  display: flex;
  justify-content: center;

  .gameLogoItem {
    position: relative;
    width: 80px;
    height: auto;
    margin: 0 50px;
    cursor: pointer;

    &.disable {
      opacity: 0.5;
      cursor: not-allowed;
    }

    &.active {
      p {
        color: var(--yellow2);
        // font-family: "Bold";
      }
    }
  }

  img {
    width: 100%;
    height: auto;
  }

  p {
    position: absolute;
    left: 50%;
    font-size: 14px;
    line-height: 20px;
    color: var(--dark1);
    font-family: "Regular";
    white-space: nowrap;
    transform: translateX(-50%);
    text-align: center;
  }
}

.loadingBox {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: .10rem;
  overflow: hidden;
}

.application_box.afk {
  @include application_afk;
}