.guide_main {
  position: relative;
  width: 100%;
  min-height: calc(100vh - 1rem);
  padding-bottom: 1rem;
  box-sizing: border-box;
  text-align: center;
  overflow: hidden;
  background: #f6b92e linear-gradient(20deg, #eb990a, #f6ba2f);

  .hide {
    animation: hide .5s linear forwards;
  }

  .welcome,
  .task_box {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .cz_icon {
    width: 2.07rem;
    height: auto;
    margin: 2rem auto .40rem;

    img {
      width: 100%;
      height: auto;
      opacity: 0;
      animation: show1 .6s ease forwards;
      animation-delay: .2s;
    }
  }

  .welcome_text {
    width: 100%;
    font-size: .45rem;
    line-height: .56rem;
    color: var(--white);
    font-family: "Black";
    text-align: center;
    text-transform: uppercase;
    opacity: 0;
    animation: show2 1.2s ease forwards;
    animation-delay: .7s;
  }

  .firework_left {
    position: absolute;
    left: 50%;
    top: -1.4rem;
    margin-left: -3.1rem;
    width: 3.06rem;
    height: 6.39rem;
    background: url(~@/assets/images/firement_left_m.png) no-repeat center/contain;
    transform: scale(0);
    animation: show3 .5s ease forwards;
    animation-delay: .2s;
    transform-origin: right center;
  }

  .firework_right {
    position: absolute;
    right: 50%;
    top: -1rem;
    margin-right: -3rem;
    width: 1.19rem;
    height: 5.35rem;
    background: url(~@/assets/images/firement_right_m.png) no-repeat center/contain;
    transform: scale(0);
    animation: show3 .5s ease forwards;
    animation-delay: .2s;
    transform-origin: left center;
  }

  .community_rules_box {
    position: relative;
    width: 6.75rem;
    height: 9.07rem;
    margin: 0 auto;
    border-radius: .10rem;
    background-color: var(--white);
  }

  .community_rules_box2 {
    @extend .community_rules_box;
    background: none;
    font-size: 0;
    line-height: 0;
  }

  .community_title,
  .community_title2 {
    width: 100%;
    font-size: .38rem;
    line-height: 1.16rem;
    color: var(--gray31);
    font-family: "Black";
    text-transform: uppercase;
  }

  .community_title2 {
    line-height: .45rem;
    padding: .4rem 0;
  }

  .communit_detail {
    width: 96%;
    height: 6.25rem;
    padding: 0 .25rem;
    margin: 0 auto;
    overflow-y: auto;
    text-align: left;
    box-sizing: border-box;

    p {
      font-size: .22rem;
      line-height: .25rem;
      color: var(--gray32);
      font-family: "Regular";
    }

    &::-webkit-scrollbar {
      width: .04rem;
    }

    &::-webkit-scrollbar-track {
      background: none;
      border-radius: .04rem;
    }

    &::-webkit-scrollbar-thumb {
      background: var(--gray4);
      border-radius: .04rem;
    }
  }

  .communit_detail2 {
    height: 4.5rem;
  }

  .agree_btn {
    width: 2.72rem;
    height: .75rem;
    font-size: .19rem;
    line-height: .65rem;
    color: var(--white);
    text-align: center;
    margin: .20rem auto 0;
    font-family: "Bold";
    background: url(~@/assets/images/btn2.png) no-repeat center center;
    background-size: 100% 100%;
    filter: grayscale(1);
    -webkit-filter: grayscale(1);
  }

  .agree_btn.active {
    filter: none;
    -webkit-filter: none;
    cursor: pointer;
  }

  .agree_tip {
    width: 100%;
    font-size: .16rem;
    line-height: .24rem;
    color: var(--yellow5);
    font-family: "Regular";
  }

  .creator_line {
    width: 100%;
    font-size: .20rem;
    line-height: .8rem;
    color: var(--yellow21);
    font-family: "Black";
    text-align: left;
    margin: 0 auto;
    text-indent: .4rem;

    a {
      color: var(--yellow21);
      text-decoration: underline;
    }
  }

  .accept {
    position: relative;
    width: 100%;
    font-size: .2rem;
    line-height: .34rem;
    color: var(--dark1);
    font-family: "Regular";
    text-align: left;
    margin: 0 auto;
    box-sizing: border-box;
    padding-left: .65rem;

    i {
      position: absolute;
      display: inline-block;
      width: .16rem;
      height: .16rem;
      line-height: .16rem;
      top: .08rem;
      left: .35rem;
      border-radius: 50%;
      border: var(--gray31) .01rem solid;
      cursor: pointer;
    }

    i.active::after {
      content: "";
      position: absolute;
      width: .10rem;
      height: .10rem;
      top: 50%;
      left: 50%;
      margin-top: -.05rem;
      margin-left: -.05rem;
      background-color: var(--yellow21);
      border-radius: 50%;
    }
  }

  .gudie_fin_box {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgb(235, 153, 10, .4);
  }

  .guide_fin_light {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    animation: show5 1s ease forwards;
    background: url(~@/assets/images/guide_light.png) no-repeat top/cover;
    transform: rotate(180deg);
  }

  .gudie_fin_txt {
    position: absolute;
    width: 100%;
    text-align: center;
    top: 50%;
    opacity: 0;
    margin-top: -2.40rem;
    animation: show2 1s ease forwards;

    p {
      font-size: .32rem;
      line-height: .38rem;
      color: var(--yellow14);
      font-family: "Black";
      text-transform: uppercase;
    }
  }
}

@keyframes show1 {
  0% {
    transform: scale(0);
    opacity: 0;
  }

  90% {
    transform: scale(1.05);
    opacity: 1;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes show2 {
  0% {
    transform: translateY(.20rem);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes show3 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes show4 {
  0% {
    transform: rotateY(180deg);
  }

  100% {
    transform: rotateY(0);
  }
}

@keyframes show5 {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes hide {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(.50rem);
  }
}