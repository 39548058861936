.admin_nav {
  position: absolute;
  left: 0;
  top: 0;
  width: 345px;
  border-radius: 8px;
  background-color: var(--white);

  &.fix {
    position: fixed;
    top: 157px;
    left: 50%;
    margin-left: -730px;

    &.fire {
      top: 980px;
      margin-left: -595px;
    }
  }

  &.fix2 {
    position: fixed;
    top: 180px;
    left: 50%;
    margin-left: -730px;

    &.fire {
      top: 78px;
    }
  }

  :global {
    ul {
      border-radius: 8px;
    }

    .ant-menu-sub.ant-menu-inline > .ant-menu-item {
      height: 80px;
      margin-bottom: 0;
    }

    .ant-menu-inline>.ant-menu-item,
    .ant-menu-inline>.ant-menu-submenu>.ant-menu-submenu-title {
      height: 80px;
      line-height: 80px !important;
      margin: 0;
    }

    .ant-menu-inline .ant-menu-item:first-child {
      margin-top: 0;
      border-radius: 8px 8px 0 0;
    }

    .ant-menu-inline .ant-menu-item:last-child {
      margin-bottom: 0;
      border-radius: 0 0 8px 8px;
    }

    .ant-menu-submenu .ant-menu-inline .ant-menu-item {
      border-radius: 0;
      background-color: var(--gray27);

      span {
        font-family: "Regular" !important;
        color: var(--gray17) !important;
      }

      &.ant-menu-item-selected span {
        color: var(--white) !important;
      }
    }

    .ant-menu-inline .ant-menu-item-selected::after {
      display: none;
    }

    .ant-menu-inline.ant-menu-root .ant-menu-item>.ant-menu-title-content,
    .ant-menu-inline.ant-menu-root .ant-menu-submenu-title>.ant-menu-title-content {
      font-size: 20px;
      color: var(--gray17);
      font-family: "Black";
      text-align: center;

      i {
        position: absolute;
        right: 30px;
        top: 16px;
        width: 8px;
        height: 8px;
        border-radius: 8px;
        background-color: #F00;
      }
    }

    .ant-menu-inline.ant-menu-root .ant-menu-item.ant-menu-item-selected>.ant-menu-title-content {
      color: var(--white);
    }

    .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
      background-color: var(--yellow3);
    }

    .ant-menu-submenu-arrow::before, .ant-menu-submenu-arrow::after {
      display: none;
      transition: none;
    }

    .ant-menu-submenu-arrow {
      width: 0;
      height: 0;
      border-top: 10px var(--gray17) solid;
      border-left: 7px transparent solid;
      border-right: 7px transparent solid;
      border-bottom: none;
      transition: transform 0.3s;
      right: 26px;
    }

    .ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
      transform: rotate(180deg) translateY(3px);
    }
  }

}

@media screen and (max-width: 1470px) {
  .admin_nav {
    &.fix2 {
      &.fire {
        margin: 0;
        left: 0;
      }
    }
  }
}

.subLink {
  position: absolute;
  width: 100%;
  height: 65px;
  bottom: -100px;
  background: url(~@/assets/images/btn4.png) no-repeat center center;
  background-size: 100% 100%;
  text-align: center;

  a {
    display: inline-block;
    width: 100%;
    height: 100%;
    font-size: 20px;
    line-height: 60px;
    color: var(--white);
    font-family: "Bold";
    text-transform: uppercase;

    &:hover {
      color: var(--white);
    }
  }
}