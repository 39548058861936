.message_box {
  width: 100%;
  height: auto;
  min-height: 215px;
  font-size: 0;
  line-height: 0;
  margin-top: 40px;
  padding: 55px 85px;
  box-sizing: border-box;
  border-radius: 8px;
  background-color: var(--white);
  overflow: hidden;
  white-space: nowrap;
  text-align: left;

  .message_item {
    display: inline-block;
    width: 50%;
    height: auto;
    min-height: 215px;
    box-sizing: border-box;
    overflow: hidden;
    vertical-align: top;

    &:nth-child(1) {
      padding-right: 70px;
    }

    &:nth-child(2) {
      padding-left: 70px;
    }
  }

  .message_title {
    position: relative;
    width: 100%;
    margin-bottom: 20px;

    p {
      font-size: 26px;
      color: var(--black4);
      line-height: 26px;
      font-family: "Black";
    }

    span {
      position: absolute;
      right: 0;
      top: 0;
      font-size: 20px;
      line-height: 26px;
      color: var(--yellow15);
      font-family: "Black";
      cursor: pointer;
    }
  }

  ul {
    position: relative;
    width: 100%;
    height: auto;

    li {
      position: relative;
      width: 100%;
      font-size: 20px;
      line-height: 50px;
      font-family: "Regular";
      cursor: pointer;

      p {
        max-width: 13em;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      p span {
        font-size: 20px;
        color: var(--black4);
        font-family: "Black";
      }

      > span {
        position: absolute;
        font-size: 16px;
        color: var(--gray11);
        right: 0;
        top: 0;
      }
    }

    

    > li:nth-child(1),> li:nth-child(2), > li:nth-child(3) {
      p span {
        color: var(--yellow3);
      }
    }

    .none_message {
      width: 100%;
      font-size: 40px;
      line-height: 80px;
      margin-top: 60px;
      text-align: center;
      color: var(--gray11);
      font-family: "Bold";
    }

  }
}