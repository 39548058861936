.content_box {
  width: 100%;
  height: auto;
  border-radius: .08rem;
  padding: .54rem .43rem .54rem;
  background-color: var(--white);
  box-sizing: border-box;
  text-align: left;
  margin-top: .4rem;

  .content_title {
    font-size: .26rem;
    line-height: .26rem;
    color: var(--black4);
    font-family: "Black";
    text-transform: uppercase;

    i {
      display: inline-block;
      width: .25rem;
      height: .25rem;
      color: var(--white);
      font-size: .18rem;
      line-height: .25rem;
      font-family: "Black";
      margin-left: .15rem;
      background-color: var(--yellow13);
      text-align: center;
      vertical-align: middle;
      border-radius: 50%;
      font-style: normal;
      cursor: pointer;
    }
  }

  .overview_list {
    position: relative;
    width: 100%;
    margin: 0.05rem 0 .1rem -0.2rem;

    li {
      padding: 0 .2rem !important;

      &::after {
        left: 0.2rem !important;
        right: 0.2rem !important;
      }

      span {
        font-size: .18rem;
        font-family: "Black";
        color: var(--black4);
        line-height: .24rem;
      }
    }

    :global {

      .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after {
        border-color: var(--yellow13) !important;
        box-shadow: none;
        outline: none;
      }
  
      .ant-menu-item-selected span {
        color: var(--yellow13);
      }
    }
    
    .date {
      width: 100%;
      margin: 0.1rem 0 0 0.2rem;
      
      > div {
        max-width: 100%;
      }

      :global {
        .ant-picker-input > input {
          font-size: 0.18rem;
        }
      }
    }
  }

  .media_list {
    display: flex;
    align-items: center;

    > span {
      font-size: .3rem;
      margin: 0 .1rem;
    }
  }

  .data_list {
    width: 100%;
    height: auto;
    margin-top: 0.2rem;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    li {
      position: relative;
      width: 49%;
      height: auto;
      padding-left: .2rem;
      box-sizing: border-box;
  
      &:nth-child(2n - 1)::after {
        content: "";
        position: absolute;
        right: 0;
        top: 50%;
        width: 1px;
        height: 1.12rem;
        margin-top: -.6rem;
        background-color: var(--gray21);
      }
    }
  
    .content_subtitle {
      display: inline-block;
      height: 0.56rem;
      font-size: .22rem;
      line-height: .28rem;
      color: var(--black4);
      font-family: "Black";
      text-transform: uppercase;
    }
  
    .num {
      font-size: .5rem;
      line-height: .56rem;
      color: var(--yellow13);
      font-family: "Bold";
    }
  
    .bite {
      display: inline-block;
      font-size: .18rem;
      color: var(--red4);
      line-height: .28rem;
      font-family: "Bold";
      padding: 0 .18rem;
      box-sizing: border-box;
      background-color: var(--gray22);
      border-radius: .28rem;
      vertical-align: top;
      margin-top: .18rem;
    }
  }

}