.step_box {
  position: relative;
  width: 100%;
  height: auto;
  margin-top: .20rem;
  font-size: 0;
  line-height: 0;

  >div:nth-child(1) {
    margin-top: 0 !important;
  }

  :global {
    .ant-checkbox-wrapper {
      font-size: .22rem;
      color: var(--black4);
      font-family: "Regular";
      display: flex;
      height: 0.52rem;
      justify-content: flex-start;
      align-items: center;
    }

    .ant-checkbox-inner {
      width: .20rem;
      height: .20rem;
      border: 1px solid #d9d9d9 !important;
    }

    .ant-checkbox-inner::after {
      width: 0.08rem;
      height: 0.14rem;
      top: 41%;
    }

    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: transparent;
      border-color: var(--yellow3) !important;
    }

    .ant-checkbox-checked .ant-checkbox-inner::after,
    .ant-checkbox-wrapper:hover .ant-checkbox-inner {
      border-color: var(--yellow3) !important;
    }

    .ant-checkbox-checked+span {
      color: var(--yellow3);
    }

    .ant-checkbox-checked::after {
      border: none;
    }

    .ant-checkbox {
      top: 0em;
    }

    .ant-checkbox + span {
      font-size: 0.24rem;
      line-height: .26rem;
      height: auto;
      word-break: break-all;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }

  .step_title {
    font-size: .24rem;
    color: var(--black4);
    line-height: .30rem;
    text-align: left;
    font-family: "Black";

    span {
      font-family: "Bold";
    }
  }
  
  .upLoadTip {
    font-size: 0.2rem;
    color: var(--gray11);
    line-height: 0.26rem;
    font-family: "Regular";
    margin-top: .10rem;
    margin-bottom: -.20rem;
  }

  .step1_list {
    width: 100%;
    height: auto;
    min-height: 4.80rem;
    margin-top: .05rem;

    .scroll,
    .scroll * {
      cursor: default;
    }

    .active_list {
      width: 100%;
      height: 100%;
      font-size: 0;
      line-height: 0;
      overflow-y: hidden;
      overflow-x: auto;
      white-space: nowrap;

      /*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
      &::-webkit-scrollbar {
        height: .06rem;
        background-color: transparent;
      }

      /*定义滚动条轨道 内阴影+圆角*/
      &::-webkit-scrollbar-track {
        border-radius: 0;
        background-color: transparent;
      }

      /*定义滑块 内阴影+圆角*/
      &::-webkit-scrollbar-thumb {
        border-radius: 0;
        background-color: var(--yellow3);
      }
    }

    .active_item {
      display: inline-block;
      height: 100%;
      width: 3.5rem;
      margin-right: .20rem;
      vertical-align: top;
      white-space: break-spaces;
      word-wrap: break-word;

      &:last-child {
        margin-right: 0;
      }
    }

    .step_data {
      position: relative;
      width: 100%;
      height: 5.75rem;
      margin-top: .12rem;
      padding-bottom: .30rem;
      box-sizing: border-box;
      background-color: var(--gray5);
      border-radius: .12rem .12rem 0 0;

      .img_box {
        position: relative;
        display: block;
        width: 100%;
        height: 1.7rem;
        border-radius: .12rem;
        overflow: hidden;
        margin-bottom: .15rem;

        img {
          display: block;
          width: 100%;
          height: 100%;
          border-radius: .12rem;
          object-fit: cover;
        }

        p {
          position: absolute;
          width: 100%;
          height: .32rem;
          bottom: 0;
          left: 0;
          font-size: .14rem;
          line-height: .32rem;
          padding: 0 .06rem;
          box-sizing: border-box;
          font-family: "Bold";
          background-color: rgba(0, 0, 0, 0.7);

          .num {
            position: absolute;
            right: .06rem;
            color: var(--white);

            em {
              color: var(--yellow3);
              font-style: normal;
            }

            i {
              display: inline-block;
              width: .10rem;
              height: .10rem;
              border-radius: 50%;
              margin-right: .04rem;
              background-color: var(--green4);
            }
          }

          .type1,
          .type2,
          .type3 {
            color: var(--green4);

            i {
              position: relative;
              display: inline-block;
              width: .14rem;
              height: .14rem;
              border-radius: 50%;
              margin-bottom: .02rem;
              margin-right: .04rem;
              border: .01px var(--green4) solid;
              vertical-align: middle;

              &::after {
                position: absolute;
                content: "";
                width: .06rem;
                height: .06rem;
                top: 50%;
                left: 50%;
                margin-top: -.03rem;
                margin-left: -.03rem;
                background-color: var(--green4);
                border-radius: 50%;
              }
            }
          }

          .type1 {
            color: var(--yellow16);

            i {
              border-color: var(--yellow16);

              &::after {
                background-color: var(--yellow16);
              }
            }
          }

          .type3 {
            color: var(--red5);

            i {
              border-color: var(--red5);

              &::after {
                background-color: var(--red5);
              }
            }
          }
        }
      }

      .desc,
      .desc2 {
        width: 3.15rem;
        margin: 0 auto;
        overflow: hidden;
        font-size: .2rem;
        color: var(--black4);
        line-height: .25rem;
        white-space: normal;
        font-family: "Regular";
      }

      .desc2 {
        min-height: 0;
      }

      .desc3 {
        min-height: .66rem;
      }

      .desc4 {
        max-height: 0.97rem;
        overflow: hidden;
      }

      .subtitle {
        width: 3.10rem;
        margin: .08rem auto 0;
        font-size: .2rem;
        font-family: "Bold";
        line-height: .24rem;
        color: var(--black4);

        span {
          font-family: "Regular";
        }
      }

      .more {
        position: absolute;
        bottom: 0.3rem;
        display: block;
        font-size: .16rem;
        color: var(--black4);
        margin-left: .18rem;
        margin-top: .20rem;
        font-family: "Regular";
        font-weight: bold;
        text-decoration: underline;
        cursor: pointer;

        &:hover {
          color: var(--yellow3);
        }
      }

      .step_messsagebox {
        display: block;
        width: 100%;
        max-height: 3.2rem;
        margin-bottom: .35rem;
        overflow: hidden;
      }

      .tip_box {
        position: relative;
        width: 100%;
        height: auto;
        overflow: hidden;
      }

      .tip {
        position: absolute;
        top: 3.14rem;
        width: 100%;
        text-align: left;
        font-size: .28rem;
        color: var(--black4);
        line-height: .2rem;
        white-space: normal;
        font-family: "Regular";
        padding: 0 .19rem;
        box-sizing: border-box;
        font-weight: bold;
      }
    }
  }

  .unJoin {
    margin: .10rem auto 0;
    font-weight: bold;

    :global {
      .ant-checkbox-wrapper {
        font-size: .22rem;
        color: var(--black4);
        line-height: .30rem;
        font-family: "Regular";
      }

      span {
        text-decoration: underline;
      }
    }
  }

  .step_tip {
    font-size: .22rem;
    color: var(--black4);
    line-height: .40rem;
    margin: .10rem auto -.10rem;
    font-family: "Regular";
    font-weight: bold;

    span {
      font-weight: normal;
    }

    em {
      font-style: normal;
      color: var(--yellow17);
      font-family: "Bold";
    }
  }
}

.upload_box {
  width: 100%;
  padding: 0 !important;
  min-height: 0 !important;
  max-width: 100% !important;
}

.add_Field {
  width: 100%;
  height: .68rem;
  border-radius: .05rem;
  background-color: var(--white)fff;
  margin: .25rem auto 0;
  cursor: pointer;
  border: 1px var(--gray16) solid;

  p {
    width: 100%;
    line-height: .68rem;
    color: var(--black4);
    font-size: .24rem;
    font-family: "Regular";
    text-align: center;
  }
}

hr {
  margin-top: .18rem;
  border-top: none;
}

.detail_box {
  width: 100%;
  padding: 0;

  .back {
    top: -0.4rem;
    left: 0;
  }
}