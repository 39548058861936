.user_welcome {
  position: relative;
  width: 100%;
  background-color: var(--white);
  border-radius: 8px 8px 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 0;

  .guide_enter {
    position: absolute;
    width: 0.36rem;
    height: 0.36rem;
    top: 0.3rem;
    right: 0.32rem;
    background: url(~@/assets/images/guide_icon.png) no-repeat center/contain;
    cursor: pointer;

    .num {
      position: absolute;
      width: 0.22rem;
      height: 0.22rem;
      right: -0.16rem;
      top: -0.13rem;
      border-radius: 0.22rem;
      background-color: var(--red4);
      font-size: 0.14rem;
      line-height: 0.22rem;
      color: var(--white);
      font-family: "Bold";
      text-align: center;
    }
  }

  .level_box {
    position: relative;
    width: 100%;
    height: 1.68rem;
    margin: 0.62rem auto 0.3rem;

    img {
      width: auto;
      height: 100%;
    }
  }

  .welcome_txt {
    width: 100%;

    .lv_name {
      width: 100%;
      text-align: center;
      font: .3rem "Black";
      line-height: .40rem;
      color: var(--yellow3);
    }

    p {
      font-size: 0.26rem;
      line-height: 0.4rem;
      color: var(--dark1);
      font-family: "Regular";
      letter-spacing: -0.01rem;
    }

    p:nth-child(2) {
      font-size: 0.24rem;
    }

    span {
      color: var(--yellow3);
      font-family: "Black";
    }
  }

  .comm_icons {
    width: 100%;
    margin: 0.4rem auto;

    ul {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    li {
      margin: 0 0.2rem;
    }

    i {
      display: block;
      background-image: url(~@/assets/images/comm_icons.png);
      background-repeat: no-repeat;
      background-size: 0.34rem auto;
      cursor: pointer;
    }

    .youtube {
      width: 0.39rem;
      height: 0.24rem;
      background: url(~@/assets/images/yt_icon_mono_light.png) no-repeat center/contain !important;
    }

    .tiktok {
      width: 0.3rem;
      height: 0.3rem;
      background-position: left -0.3rem;
    }

    .twitch {
      width: 0.3rem;
      height: 0.32rem;
      background-position: left -0.6rem;
    }

    .creator {
      position: relative;
      width: 0.31rem;
      height: 0.24rem;
      background-position: left -1rem;

      &.on::after {
        content: "";
        position: absolute;
        width: .08rem;
        height: .08rem;
        border-radius: .08rem;
        background-color: var(--red1);
        right: -.08rem;
        top: -.08rem;
      }
    }

    .hr {
      width: 0.03rem;
      height: 0.27rem;
      background: var(--gray16);
      cursor: auto;
    }
  }

  .notice_box {
    width: 100%;
    height: auto;
    padding: 0.43rem 0;
    box-sizing: border-box;
    background-color: var(--gray5);

    ul {
      width: 95%;
      height: auto;
      max-height: 2.8rem;
      margin: 0 auto;
      overflow-y: auto;
      padding: 0 2.5%;
      box-sizing: border-box;
    }

    li {
      position: relative;
      width: 100%;
      height: 0.66rem;
      max-height: 0.66rem;
      margin-bottom: 0.25rem;
      padding: 0 1.3rem 0 0.28rem;
      box-sizing: border-box;
      border-radius: 0.08rem;
      background-color: var(--white);
      transition: max-height 0.7s, margin-bottom 0.7s;
      overflow: hidden;

      &.hide {
        max-height: 0;
        margin-bottom: 0;
      }

      p {
        display: inline-block;
        width: 100%;
        font-size: 0.2rem;
        line-height: 0.66rem;
        color: var(--dark1);
        font-family: "Regular";
        text-align: left;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    em {
      font-style: italic;
      font-size: .22rem;
      margin-right: .05rem;
    }

    li:last-child {
      margin-bottom: 0;
    }

    .todo_icons {
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
    }

    i {
      position: relative;
      width: 0.6rem;
      height: 100%;
      float: right;
      cursor: pointer;
    }

    i.next::after {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      right: 50%;
      top: 50%;
      margin-top: -0.07rem;
      margin-right: -0.07rem;
      border-right: none;
      border-top: 0.07rem transparent solid;
      border-bottom: 0.07rem transparent solid;
      border-left: 0.14rem var(--gray10) solid;
    }

    i.close::before,
    i.close::after {
      content: "";
      position: absolute;
      width: 0.2rem;
      height: 0.02rem;
      top: 50%;
      left: 50%;
      margin-top: -0.01rem;
      margin-left: -0.1rem;
      background-color: var(--gray10);
    }

    i.close::before {
      transform: rotate(45deg);
    }

    i.close::after {
      transform: rotate(-45deg);
    }
  }
}
