.step_box {
  position: relative;
  width: 100%;
  height: auto;
  margin-top: 20px;
  overflow: hidden;

  &+.step_box {
    overflow: visible;
  }

  >div:nth-child(1) {
    margin-top: 0 !important;
  }

  :global {
    .ant-checkbox-wrapper {
      font-size: 22px;
      color: var(--black4);
      font-family: "Regular";
      display: flex;
      height: 52px;
      justify-content: flex-start;
      align-items: center;
    }

    .ant-checkbox-inner {
      width: 20px;
      height: 20px;
      border: 1px solid #d9d9d9 !important;
    }

    .ant-checkbox-inner::after {
      width: 8px;
      height: 14px;
      top: 41%;
    }

    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: transparent;
      border-color: var(--yellow3) !important;
    }

    .ant-checkbox-checked .ant-checkbox-inner::after,
    .ant-checkbox-wrapper:hover .ant-checkbox-inner {
      border-color: var(--yellow3) !important;
    }

    .ant-checkbox-checked+span {
      color: var(--yellow3);
    }

    .ant-checkbox-checked::after {
      border: none;
    }

    .ant-checkbox {
      top: 0em;
    }

    .ant-checkbox + span {
      padding-right: 8px;
      padding-left: 8px;
      line-height: 26px;
      height: auto;
      word-break: break-all;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }

  .step_title {
    font-size: 24px;
    color: var(--black4);
    line-height: 30px;
    text-align: left;
    font-family: "Black";

    span {
      font-family: "Bold";
    }
  }

  .upLoadTip {
    font-size: 0.24rem;
    color: var(--gray11);
    line-height: 0.32rem;
    font-family: "Regular";
    margin-top: 0;
    margin-bottom: -10px;
  }

  .step1_list {
    width: 100%;
    height: auto;
    min-height: 480px;
    margin-top: 5px;

    .scroll,
    .scroll * {
      cursor: default;
    }

    .active_list {
      width: 100%;
      height: 100%;
      font-size: 0;
      line-height: 0;
      overflow-y: hidden;
      overflow-x: auto;
      white-space: nowrap;

      /*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
      &::-webkit-scrollbar {
        height: 6px;
        background-color: transparent;
      }

      /*定义滚动条轨道 内阴影+圆角*/
      &::-webkit-scrollbar-track {
        border-radius: 0;
        background-color: transparent;
      }

      /*定义滑块 内阴影+圆角*/
      &::-webkit-scrollbar-thumb {
        border-radius: 0;
        background-color: var(--yellow3);
      }
    }

    .active_item {
      display: inline-block;
      height: 100%;
      width: 265px;
      margin-right: 20px;
      vertical-align: top;
      white-space: break-spaces;
      word-wrap: break-word;

      &:last-child {
        margin-right: 0;
      }
    }

    .step_data {
      position: relative;
      width: 100%;
      height: 522px;
      flex: 1;
      min-height: 445px;
      margin-top: 12px;
      padding-bottom: 30px;
      box-sizing: border-box;
      background-color: var(--gray5);
      border-radius: 12px 12px 0 0;

      .img_box {
        position: relative;
        display: block;
        width: 100%;
        height: 140px;
        border-radius: 12px;
        overflow: hidden;
        margin-bottom: 15px;

        img {
          display: block;
          width: 100%;
          height: 140px;
          border-radius: 12px;
          object-fit: cover;
        }

        p {
          position: absolute;
          width: 100%;
          height: 32px;
          bottom: 0;
          left: 0;
          font-size: 14px;
          line-height: 32px;
          padding: 0 6px;
          box-sizing: border-box;
          font-family: "Bold";
          background-color: rgba(0, 0, 0, 0.7);

          .num {
            position: absolute;
            right: 6px;
            color: var(--white);

            em {
              color: var(--yellow3);
              font-style: normal;
            }

            i {
              display: inline-block;
              width: 10px;
              height: 10px;
              border-radius: 50%;
              margin-right: 4px;
              background-color: var(--green4);
            }
          }

          .type1,
          .type2,
          .type3 {
            color: var(--green4);

            i {
              position: relative;
              display: inline-block;
              width: 14px;
              height: 14px;
              border-radius: 50%;
              margin-bottom: 2px;
              margin-right: 4px;
              border: 1px var(--green4) solid;
              vertical-align: middle;

              &::after {
                position: absolute;
                content: "";
                width: 6px;
                height: 6px;
                top: 50%;
                left: 50%;
                margin-top: -3px;
                margin-left: -3px;
                background-color: var(--green4);
                border-radius: 50%;
              }
            }
          }

          .type1 {
            color: var(--yellow16);

            i {
              border-color: var(--yellow16);

              &::after {
                background-color: var(--yellow16);
              }
            }
          }

          .type3 {
            color: var(--red5);

            i {
              border-color: var(--red5);

              &::after {
                background-color: var(--red5);
              }
            }
          }
        }
      }

      .desc,
      .desc2 {
        width: 250px;
        min-height: 40px;
        margin: 5px auto;
        overflow: hidden;
        font-size: 18px;
        color: var(--black4);
        line-height: 20px;
        white-space: normal;
        font-family: "Regular";
      }

      .desc2 {
        min-height: 0;
      }

      .subtitle {
        width: 250px;
        margin: 10px auto 0;
        font-size: 18px;
        font-family: "Bold";
        line-height: 22px;
        color: var(--black4);

        span {
          font-family: "Regular";
        }
      }

      .more {
        position: absolute;
        bottom: 30px;
        display: block;
        font-size: 16px;
        color: var(--black4);
        margin-left: 10px;
        margin-top: 20px;
        font-family: "Regular";
        font-weight: bold;
        text-decoration: underline;
        cursor: pointer;

        &:hover {
          color: var(--yellow3);
        }
      }

      .step_messsagebox {
        display: block;
        width: 100%;
        max-height: 296px;
        margin-bottom: 20px;
        overflow: hidden;
      }

      .tip_box {
        position: relative;
        width: 100%;
        height: auto;
        overflow: hidden;
      }

      .tip {
        position: absolute;
        top: 297px;
        width: 100%;
        text-align: left;
        font-size: 28px;
        color: var(--black4);
        line-height: 20px;
        white-space: normal;
        font-family: "Regular";
        padding: 0 10px;
        font-weight: bold;
        box-sizing: border-box;
      }
    }
  }

  .unJoin {
    margin: 10px auto 0;
    font-weight: bold;

    :global {
      .ant-checkbox-wrapper {
        font-size: 22px;
        color: var(--black4);
        line-height: 30px;
        font-family: "Regular";
      }

      span {
        text-decoration: underline;
      }
    }
  }

  .step_tip {
    font-size: 22px;
    color: var(--black4);
    line-height: 40px;
    margin: 10px auto -10px;
    font-family: "Regular";
    font-weight: bold;

    span {
      font-weight: normal;
    }

    em {
      font-style: normal;
      color: var(--yellow17);
      font-family: "Bold";
    }
  }
}

.upload_box {
  padding: 0 !important;
  min-height: 0 !important;
}

.add_Field {
  width: 945px;
  height: 68px;
  border-radius: 5px;
  background-color: var(--white)fff;
  margin: 25px auto 0;
  cursor: pointer;
  border: 1px var(--gray16) solid;

  p {
    width: 100%;
    line-height: 68px;
    color: var(--black4);
    font-size: 24px;
    font-family: "Regular";
    text-align: center;
  }
}

hr {
  margin-top: 18px;
  border-top: none;
}