.admin_contain {
  position: relative;
  width: 100%;
  height: auto;
  min-height: calc(100vh - 1rem);
  background-color: var(--gray16);
}

.admin_wrap {
  position: relative;
  width: 100%;
  height: auto;
  min-height: calc(100vh - 2.1rem);
  padding-bottom: 1.5rem;
  box-sizing: border-box;
  overflow: auto;
}