.art_assets_box {
  position: relative;
  width: 100%;
  height: auto;
  padding: .4rem .44rem;
  margin: .4rem auto;
  box-sizing: border-box;
  background-color: var(--white);
  border-radius: .08rem;

  .title1 {
    font-size: .26rem;
    line-height: .26rem;
    color: var(--black4);
    font-family: "Black";
    text-align: left;

    &.vi {

      &+.query {
        width: 2.4rem;
      }
    }
  }

  .query {
    position: absolute;
    right: .44rem;
    top: .3rem;
    width: 3.2rem;

    input {
      display: block;
      width: 100%;
      height: .46rem;
      line-height: .46rem;
      font-size: .18rem;
      color: var(--black3);
      font-family: "Regular";
      padding: 0 .56rem 0 .2rem;
      box-sizing: border-box;
      border-radius: .46rem;
      border: 1px #737373 solid;
      background: none;
    }

    i {
      position: absolute;
      width: .23rem;
      height: .23rem;
      right: .20rem;
      top: 50%;
      margin-top: -.11rem;
      cursor: pointer;
      background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAXCAMAAADX9CSSAAAApVBMVEUAAAA7Ozs9PT08PDw8PDw8PDw7Ozs9PT09PT08PDwyMjI6Ojo/Pz89PT09PT0+Pj48PDw5OTk8PDw9PT09PT01NTU8PDw6Ojo+Pj4+Pj45OTk8PDw9PT06OjpAQEA8PDw9PT06OjpEREQ8PDw+Pj46Ojo5OTksLCwzMzMuLi45OTlBQUE+Pj4+Pj43Nzc4ODg+Pj4+Pj42NjY9PT0+Pj5AQEBEREROboehAAAAM3RSTlMAG/z36scY2dTDEAjoq6aak4Ytt4t8V0RANCQiFQv75uLVz8y/s7OqnJSQjHVtZFNPPSQtBXkYAAAA1UlEQVQoz3WPV3LDMBBDWUR1R8VxSWyn954A1P2PlhGlKKTHxgex80AOscJJLoyGzt5iEeiUBNAfjUfzG8t6tS3iVQXe5RNPOiPH8Tzl7R+ubOY9Tu3LeIUXhcelwvD5U9eEHezCuWZY7ozXznkpQinsekO2xyNKx5M9rpk7ziLAP4ycz/kR8HdbO1+jDHiJdhhM9+zhx+5+nDbKvk64tvp/E8X0q++wXSYEqinYGBJplgCYr68wE5OWDyCpZp9CyNIPxK79bodF4ggn4pDk8YBHAtP8AuK3E9qVr3m9AAAAAElFTkSuQmCC) no-repeat center/contain;
    }
  }

  ul {
    width: 100%;
    margin-top: .3rem;
    padding-bottom: .5rem;
    box-sizing: border-box;

    .none_tip {
      width: 100%;
      font-size: .50rem;
      line-height: 1rem;
      margin-top: .60rem;
      text-align: center;
      color: var(--gray11);
      font-family: "Bold";
      user-select: none;
    }
  }

  li {
    position: relative;
    width: 100%;
    height: 1rem;
    padding: .2rem 0;
    box-sizing: border-box;
    border-bottom: 1px var(--gray19) solid;

    .title {
      float: left;
      display: block;
      max-width: 3.9rem;
      font-size: .2rem;
      color: var(--black3);
      line-height: .3rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-family: "Regular";
    }

    .enter {
      position: absolute;
      width: 4rem;
      height: 100%;
      top: 0;
      left: 0;
    }

    .download_data {
      position: absolute;
      bottom: 0.15rem;
      right: 0;
      width: 100%;

      .time {
        font-size: .18rem;
        line-height: .18rem;
        color: var(--gray11);
        font-family: "Regular";
      }
    }

    .download {
      position: absolute;
      right: 0;
      top: .3rem;
    }

    .type {
      font-size: .14rem;
      line-height: .20rem;
      color: var(--white);
      font-family: "Black";
      padding: 0 .08rem;
      margin: 0 .25rem;
      box-sizing: border-box;
      border-radius: .20rem;
      border-radius: 20px;
      background-color: var(--blue3)
    }

    .apk,
    .psd,
    .jpg,
    .mp4 {
      @extend .type;
    }

    .apk {
      background-color: var(--red3);
    }

    .psd {
      background-color: var(--pruple1);
    }

    .jpg {
      background-color: var(--blue3);
    }

    .mp4 {
      background-color: var(--green2);
    }

    .download_btn {
      display: block;
      width: 2rem;
      height: .45rem;
      border-radius: .05rem;
      font-size: .16rem;
      line-height: .45rem;
      color: var(--white);
      font-family: "Black";
      text-align: center;
      cursor: pointer;
      text-transform: uppercase
    }

    .download_btn1,
    .download_btn2,
    .download_btn3,
    .download_btn4 {
      @extend .download_btn;
    }

    .download_btn1 {
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: .16rem;
      background-color: var(--yellow3);
    }

    .download_btn2 {
      background-color: var(--yellow3);
    }

    .download_btn3 {
      position: relative;
      background-color: var(--yellow3);
    }

    .download_btn4 {
      background-color: var(--blue4);
    }

    .line {
      position: absolute;
      width: 1.04rem;
      left: 50%;
      margin-left: -.52rem;
      bottom: -.10rem;
      height: .05rem;
      border-radius: .05rem;
      background-color: var(--gray19);
      overflow: hidden;
    }

    .activeLine {
      position: absolute;
      left: 0;
      top: 0;
      height: .05rem;
      width: 50%;
      background-color: var(--yellow3);
    }
  }

  .page {
    position: relative;
    width: 100%;
    text-align: center;
    font-size: 0;

    span {
      font-size: .22rem;
      line-height: .22rem;
      color: var(--black4);
      font-family: "Bold";
      margin: 0 .18rem;
      cursor: pointer;
    }

    span.active {
      color: var(--yellow3);
      text-decoration: underline;
      pointer-events: none;
    }

    ul {
      padding: 0;
    }

    li {
      width: auto;
      padding: 0;
      border: none;
    }
  }
}