.tip_content {
  position: absolute;
  width: 1200px;
  height: auto;
  min-height: 300px;
  top: 50%;
  left: 50%;
  transform: translateY(-50%);
  margin-top: 0 !important;
  max-height: 90vh !important;
  margin-left: -600px;
  padding: 43px 0;
  box-sizing: border-box;
  border-radius: 8px;
  background-color: var(--white);
  z-index: 10;

  .desc {
    position: relative;
    width: 90%;
    height: auto;
    min-height: 250px;
    max-height: 500px;
    margin: 26px auto 0;
    padding: 0 20px;
    overflow-y: auto;
    word-break: break-all;

    &::-webkit-scrollbar {
      width: 4px;
    }
  
    &::-webkit-scrollbar-track {
      background: none;
      border-radius: 4px;
    }
  
    &::-webkit-scrollbar-thumb {
      background: var(--gray4);
      border-radius: 4px;
    }
    
    p {
      font-size: 24px;
      line-height: 34px;
      color: var(--dark1);
      text-align: left;
    }

    .t1 {
      font-size: 26px;
      line-height: 40px;
      font-family: "Bold";
      padding-top: 20px;
    }

    .t1:nth-child(1) {
      padding: 0;
    }

    img {
      display: block;
      max-width: 100%;
      width: auto;
      height: auto;
      margin: 10px auto;
    }
  }
}

.tip_content.m {
  width: 7rem;
  margin-left: -3.5rem;
  margin-top: -6rem;
  max-height: 70vh;
  display: flex;
  flex-direction: column;

  .desc {
    height: auto;
    margin: .26rem auto 0;
    padding: 0 .20rem;
    overflow-y: auto;
    
    p {
      font-size: .28rem;
      line-height: .34rem;
    }

    .t1 {
      font-size: .36rem;
      line-height: .46rem;
      padding-top: .30rem;
    }

    .t1:nth-child(1) {
      padding: 0;
    }

    img {
      margin: .1rem auto;
    }
  }
}