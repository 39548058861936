.community_rules_box {
  position: relative;
  width: 1380px;
  // height: 605px;
  margin: 0 auto;
  border-radius: 10px;
  background-color: var(--white);
}

.community_rules_box2 {
  @extend .community_rules_box;
  background: none;
  font-size: 0;
  line-height: 0;
}

.community_rules_box2.fire {
  position: absolute;
  width: 1460px;
  top: 230px;
  left: 50%;
  margin-left: -700px;
}

@media screen and (max-width: 1700px) {
  .community_rules_box2.fire {
    left: 0;
    margin-left: 0;
  }
}

.task_item {
  position: relative;
  display: inline-block;
  width: 420px;
  height: 175px;
  border-radius: 5px;
  margin-bottom: 28px;
  margin-right: 28px;

  &:nth-child(3n) {
    margin-right: 0;
  }

  &:nth-child(n + 7) {
    margin-bottom: 0;
  }

  &.none {
    display: none;
  }

  // &.active {
  //   filter: brightness(0.7) grayscale(1);
  //   -webkit-filter: brightness(0.7) grayscale(1);
  // }

  &:nth-child(n + 7) {
    cursor: pointer;

    // &.active {
    //   filter: none;
    //   -webkit-filter: none;
    // }
  }
}

.active .task_item {
  cursor: normal;
}

.task_base {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  backface-visibility: hidden;
  transition: transform 1s;

  p {
    font-family: "Black";
    text-transform: uppercase;
    margin: 0 auto;
  }
}

.active .task_base {
  backface-visibility: hidden;
}

.task_default {
  @extend .task_base;
  transform: rotateY(0deg);
  background: url(~@/assets/images/task_default.png) no-repeat center/cover;
  background-size: 100% 100%;

  // &:hover {
  //   background: url(~@/assets/images/task_none.png) no-repeat center/cover;

  //   p {
  //     opacity: 1;
  //   }
  // }

  p {
    width: 90%;
    color: var(--white)59d;
    font-size: 22px;
    line-height: 26px;
    text-align: center;
    cursor: normal;
  }
}

.task_data {
  @extend .task_base;
  background-color: var(--white);
  transform: rotateY(180deg);
  overflow: hidden;

  p {
    width: 100%;
    font-size: 26px;
    line-height: 30px;
    color: var(--gray31);
    text-align: center;
    cursor: normal;
  }
}

.active .task_default,
.task_item:hover .task_default {
  transform: rotateY(180deg);
}

.active .task_data,
.task_item:hover .task_data {
  transform: rotateY(0);
  cursor: normal;
}

.active .task_data {
  padding-left: 128px;

  &::before {
    content: "";
    position: absolute;
    top: -60px;
    left: -40px;
    width: 160px;
    height: 160px;
    border-radius: 50%;
    background: var(--green5) url(~@/assets/images/dagou.png) no-repeat 60px 80px;
  }

  p {
    text-align: left;
    padding-right: 20px;
  }
}