.community_rules_box {
  position: relative;
  width: 6.75rem;
  height: 9.07rem;
  margin: 0 auto;
  border-radius: .10rem;
  background-color: var(--white);
}

.community_title {
  width: 100%;
  font-size: .38rem;
  line-height: 1.16rem;
  color: var(--gray31);
  font-family: "Black";
  text-transform: uppercase;
}

.communit_detail {
  width: 96%;
  height: 6.25rem;
  padding: 0 .25rem;
  margin: 0 auto;
  overflow-y: auto;
  text-align: left;
  box-sizing: border-box;

  p {
    font-size: .22rem;
    line-height: .25rem;
    color: var(--gray32);
    font-family: "Regular";
  }

  .sub {
    font-size: .24rem;
    line-height: .28rem;
    font-family: "Bold";
  }

  &::-webkit-scrollbar {
    width: .04rem;
  }

  &::-webkit-scrollbar-track {
    background: none;
    border-radius: .04rem;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--gray4);
    border-radius: .04rem;
  }
}

.rule_desc {
  width: 100%;
  margin-top: .30rem;

  .title {
    font-size: .24rem;
    line-height: .30rem;
    font-family: "Bold";
    text-align: center;
  }

  .sub_title {
    font-size: .20rem;
    line-height: .30rem;
    font-family: "Regular";
    text-align: left;
  }

  .content {
    font-size: .20rem;
    line-height: .25rem;
    color: var(--gray32);
    font-family: "Regular";
  }
}

.agree_btn {
  width: 2.72rem;
  height: .75rem;
  font-size: .19rem;
  line-height: .65rem;
  color: var(--white);
  text-align: center;
  margin: .20rem auto 0;
  font-family: "Bold";
  background: url(~@/assets/images/btn2.png) no-repeat center center;
  background-size: 100% 100%;
  filter: grayscale(1);
  -webkit-filter: grayscale(1);
}

.agree_btn.active {
  filter: none;
  -webkit-filter: none;
  cursor: pointer;
}

.agree_tip {
  position: relative;
  width: 100%;
  font-size: .16rem;
  line-height: .24rem;
  color: var(--yellow5);
  font-family: "Regular";
  padding-top: .2rem;

  i {
    position: relative;
    display: inline-block;
    width: .16rem;
    height: .16rem;
    line-height: .16rem;
    border-radius: 50%;
    border: var(--gray31) .01rem solid;
    margin-right: .1rem;
    vertical-align: middle;
    cursor: pointer;
  }

  i.active::after {
    content: "";
    position: absolute;
    width: .10rem;
    height: .10rem;
    top: 50%;
    left: 50%;
    margin-top: -.05rem;
    margin-left: -.05rem;
    background-color: var(--yellow21);
    border-radius: 50%;
  }
}

.creator_line {
  width: 12.25rem;
  font-size: .20rem;
  line-height: .42rem;
  color: var(--yellow21);
  font-family: "Black";
  text-align: left;
  margin: 0 auto;
  cursor: pointer;
  text-decoration: underline;
}
