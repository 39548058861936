.sing_up_main {
  width: 100%;
  height: auto;
  padding: 1.80rem 0;
  box-sizing: border-box;
  text-align: center;
  background: #f6b92e linear-gradient(20deg, #eb990a, #f6ba2f);


  .title {
    font-size: .4rem;
    line-height: .80rem;
    color: var(--white);
    font-family: "Black";
    text-transform: uppercase;
  }

  .desc {
    font-size: .24rem;
    line-height: .30rem;
    color: var(--white);
    font-family: "Regular";
  }

  .require_box {
    width: 6.7rem;
    height: auto;
    border-radius: .10rem;
    margin: .3rem auto 0;
    padding: .83rem .54rem;
    box-sizing: border-box;
    background-color: var(--white);

    ul {
      width: 100%;
      height: auto;
      text-align: left;
    }

    li {
      position: relative;
      width: 100%;
      padding-left: .44rem;
      line-height: .36rem;
      margin-bottom: .40rem;
      box-sizing: border-box;
      cursor: pointer;
    }

    i {
      position: absolute;
      width: .26rem;
      height: .26rem;
      left: 0;
      top: .05rem;
      box-sizing: border-box;
      border: .02rem #a1a1a1 solid;
      border-radius: .05rem;
      background: transparent;
      transition: background-color .2s, border-color .2s;
    }

    i.active {
      background: var(--green3) url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAALCAYAAACgR9dcAAAAqElEQVQokZ2RrQoCQRRGF8Gy1WiwWgWzTbCaxFfYYjdsEex2o8FqFPEVfAMR4xaDGGTLWQbu4Mewg+xemGHuuXMu85MAbUYOPNqIGRZNxQW/ODURZyJeHXOwC0z/iGMRb567aWPwEhGHQGl77kCq8lK6HgKxD7ytVgA9rfvFShrsjHWAp7EvMAhPpclWGqyBs+SjuiuFYC+Cj0nsIevgEfgAL2Ae/QFIKiaAGR7xXNbLAAAAAElFTkSuQmCC) no-repeat center center;
      border-color: var(--green3);
      background-size: .15rem auto;
    }

    span {
      font-size: .24rem;
      line-height: .36rem;
      color: var(--black12);
      font-family: "Regular";
      vertical-align: top;

      span, a {
        color: var(--black3);
        text-decoration: underline;
      }
    }

    .got_btn {
      width: 2.72rem;
      height: .75rem;
      font-size: .19rem;
      line-height: .65rem;
      color: var(--white);
      text-align: center;
      margin: 0rem auto 0.2rem;
      font-family: "Bold";
      background: url(~@/assets/images/btn2.png) no-repeat center center;
      background-size: 100% 100%;
      filter: grayscale(1);
      -webkit-filter: grayscale(1);
    }

    .got_btn.active {
      filter: none;
      -webkit-filter: none;
      cursor: pointer;
    }

    .sign_other {
      width: 100%;
      font-size: .20rem;
      line-height: .24rem;
      color: var(--black12);
      font-family: "Regular";
      text-align: center;

      a {
        text-decoration: underline;
        font-weight: bold;
      }
    }
  }

  .sing_box {
    width: 6.7rem;
    height: 7.7rem;
    border-radius: .10rem;
    margin: .3rem auto 0;
    padding: .7rem .33rem 0;
    box-sizing: border-box;
    background-color: var(--white);

    .input_box {
      position: relative;

      .num {
        position: absolute;
        right: .3rem;
        top: 0;
        line-height: .62rem;
        font-size: .2rem;
      }
    }

    input {
      display: block;
      width: 100%;
      height: .62rem;
      color: var(--black1);
      font-size: .20rem;
      line-height: .62rem;
      text-align: left;
      padding: 0 .35rem;
      border: none;
      box-sizing: border-box;
      border-radius: .07rem;
      font-family: "Reuglar";
      background: var(--gray5);
    }

    .slide1 {
      margin-bottom: .26rem;
    }

    .password_box {
      position: relative;
      width: 100%;

      input {
        padding-right: .92rem;
      }
    }

    .hideIcon {
      position: absolute;
      width: .22rem;
      height: .22rem;
      right: .40rem;
      top: .23rem;
      background: url(~@/assets/images/eyes.png) no-repeat top/cover;
      background-position: center .05rem;
      cursor: pointer;
    }

    .password_box.active .hideIcon {
      background-position: bottom;
    }

    .password_require {
      width: 100%;
      padding: .2rem 0.1rem;
      text-align: left;

      p {
        font-size: .18rem;
        line-height: .2rem;
        color: var(--dark1);
        font-family: "Regular";
      }

      i {
        display: inline-block;
        width: .22rem;
        height: .16rem;
        vertical-align: bottom;
      }

      i.active {
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAJCAYAAAAGuM1UAAAAyUlEQVQYlYXRrUpFURAF4O8Ku4jRp7hNUPQV3Dtd1OIDaPAFTIIgajOZTTbBNCa7oA8gWCwWu+ls5Mi53ityFM6CKTNrftaaUdu2hlBLWcQ7RguD7G88YQnngw21lAeMcZoiTjQ57zc5n3Wn9aPJ+a7JuW1yvpzXug1bOKylHPcmX2MTNyniYJ7vGrbxjKNayt6MfIFd3KeInd+Dpi7VUjpBr1jGLSZ4TBHrfU1T0SniAyv4nJFfsPGfCT8upYg3rOIKayni74PwBSHaUAp3fYH0AAAAAElFTkSuQmCC) no-repeat left center;
        background-size: .12rem auto;
      }
    }

    .email_box {
      position: relative;
      width: 100%;

      input {
        padding-right: 2.15rem
      }

      .code_btn, .time_load {
        position: absolute;
        right: 0;
        top: 0;
        width: 1.80rem;
        height: .64rem;
        background-color: var(--blue1);
        border-radius: 0 .07rem .07rem 0;
        cursor: pointer;
      }

      .time_load {
        background-color: var(--gray42);
        pointer-events: none;

        p {
          text-transform: lowercase;
        }
      }

      p {
        font-size: .16rem;
        line-height: .64rem;
        color: var(--white);
        text-align: center;
        font-family: "Bold";
        text-transform: uppercase;
      }
    }

    .accept {
      position: relative;
      width: 100%;
      font-size: .18rem;
      line-height: .2rem;
      color: var(--dark1);
      font-family: "Regular";
      text-align: left;
      padding-left: .25rem;
      box-sizing: border-box;

      i {
        position: absolute;
        left: 0;
        top: 0;
        display: inline-block;
        width: .16rem;
        height: .16rem;
        line-height: .16rem;
        margin-right: .05rem;
        border-radius: 50%;
        border: var(--gray31) 1px solid;
        vertical-align: top;
        cursor: pointer;
      }

      i.active::after {
        content: "";
        position: absolute;
        width: .10rem;
        height: .10rem;
        top: 50%;
        left: 50%;
        margin-top: -.05rem;
        margin-left: -.05rem;
        background-color: var(--yellow21);
        border-radius: 50%;
      }

      em {
        text-decoration: underline;
        
        a {
          color: var(--dark1);
        }
      }
    }

    .next_btn {
      width: 2.72rem;
      height: .75rem;
      font-size: .19rem;
      line-height: .65rem;
      color: var(--white);
      text-align: center;
      margin: .80rem auto 0;
      font-family: "Bold";
      background: url(~@/assets/images/btn2.png) no-repeat center center;
      background-size: 100% 100%;
      filter: grayscale(1);
      -webkit-filter: grayscale(1);
    }

    .next_btn.active {
      filter: none;
      -webkit-filter: none;
      cursor: pointer;
    }

    .sign_in_tip {
      width: 100%;
      font-size: .16rem;
      line-height: .24rem;
      color: var(--yellow5);
      font-family: "Regular";
      text-align: center;

      a {
        color: var(--yellow5);
        font-family: "Black";
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
}

.tip {
  width: 100%;
  height: auto;
  font-size: .22rem;
  line-height: .26rem;
  color: var(--gray15);
  font-family: "Regular";
  letter-spacing: -.01rem;
  padding: .4rem 0;
  text-align: center;
  box-sizing: border-box;
  background-color: var(--black7);
}

@media (prefers-color-scheme: dark) {
  .sing_up_main {
    background: #f6b92e linear-gradient(20deg, #eb990a, #f6ba2f);

    .require_box {
      background: var(--white) linear-gradient(20deg, #fff, #fff);
    }
  }
}