.box {
  p {
    font-family: "Regular";
    text-align: left;
  }
  .c1 {
    color: var(--red2);
  }
  .c2 {
    color: var(--yellow8);
  }
  .slice {
    line-height: 12px;
  }
  .emit {
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 10px;
  }
}

.box.m {
  p {
    font-size: .28rem;
    line-height: .38rem;
  }
}