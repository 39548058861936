.task_box {
  width: 100%;
  height: 10.5rem;
  text-align: center;
  overflow: hidden;
  background: #f6b92e linear-gradient(20deg, #eb990a, #f6ba2f);
}


.gudie_fin_box {
  position: absolute;
  width: 100%;
  height: 10.5rem;
  top: 0;
  left: 0;
  background-color:	rgb(235, 153, 10, .4);
}

.guide_fin_light {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  animation: show5 1s ease forwards;
  background: url(~@/assets/images/guide_light.png) no-repeat center -6.90rem;
}

.gudie_fin_txt {
  position: absolute;
  width: 100%;
  text-align: center;
  top: 50%;
  opacity: 0;
  margin-top: -.40rem;
  animation: show2 1s ease forwards;

  p {
    font-size: .36rem;
    line-height: .44rem;
    color: var(--yellow14);
    font-family: "Black";
    text-transform: uppercase;
  }
}

@keyframes show2 {
  0% {
    transform: translateY(.20rem);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes show5 {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}