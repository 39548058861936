.art_assets_box {
  position: relative;
  width: 100%;
  height: auto;
  min-height: 700px;
  padding: 55px 44px;
  box-sizing: border-box;
  background-color: var(--white);
  border-radius: 8px;

  .title1 {
    font-size: 26px;
    line-height: 26px;
    color: var(--black4);
    font-family: "Black";
    text-align: left;
  }

  .query {
    position: absolute;
    right: 44px;
    top: 55px;

    input {
      width: 346px;
      height: 40px;
      line-height: 40px;
      font-size: 14px;
      color: var(--black3);
      font-family: "Regular";
      padding: 0 56px 0 26px;
      box-sizing: border-box;
      border-radius: 40px;
      border: 1px #737373 solid;
      background: none;
    }

    i {
      position: absolute;
      width: 23px;
      height: 23px;
      right: 20px;
      top: 50%;
      margin-top: -11px;
      cursor: pointer;
      background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAXCAMAAADX9CSSAAAApVBMVEUAAAA7Ozs9PT08PDw8PDw8PDw7Ozs9PT09PT08PDwyMjI6Ojo/Pz89PT09PT0+Pj48PDw5OTk8PDw9PT09PT01NTU8PDw6Ojo+Pj4+Pj45OTk8PDw9PT06OjpAQEA8PDw9PT06OjpEREQ8PDw+Pj46Ojo5OTksLCwzMzMuLi45OTlBQUE+Pj4+Pj43Nzc4ODg+Pj4+Pj42NjY9PT0+Pj5AQEBEREROboehAAAAM3RSTlMAG/z36scY2dTDEAjoq6aak4Ytt4t8V0RANCQiFQv75uLVz8y/s7OqnJSQjHVtZFNPPSQtBXkYAAAA1UlEQVQoz3WPV3LDMBBDWUR1R8VxSWyn954A1P2PlhGlKKTHxgex80AOscJJLoyGzt5iEeiUBNAfjUfzG8t6tS3iVQXe5RNPOiPH8Tzl7R+ubOY9Tu3LeIUXhcelwvD5U9eEHezCuWZY7ozXznkpQinsekO2xyNKx5M9rpk7ziLAP4ycz/kR8HdbO1+jDHiJdhhM9+zhx+5+nDbKvk64tvp/E8X0q++wXSYEqinYGBJplgCYr68wE5OWDyCpZp9CyNIPxK79bodF4ggn4pDk8YBHAtP8AuK3E9qVr3m9AAAAAElFTkSuQmCC) no-repeat center/contain;
    }
  }

  > ul {
    width: 100%;
    margin-top: 30px;
    padding-bottom: 100px;
    box-sizing: border-box;

    .none_tip {
      width: 100%;
      font-size: 50px;
      line-height: 400px;
      margin-top: 60px;
      text-align: center;
      color: var(--gray11);
      font-family: "Bold";
      user-select: none;
    }
  }

  >ul li {
    display: flex;
    width: 100%;
    height: 88px;
    border-bottom: 1px var(--gray19) solid;

    .title {
      width: 610px;
      display: block;
      font-size: 18px;
      color: var(--black3);
      line-height: 88px;
      font-family: "Regular";
      cursor: pointer;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .download_data {
      flex: 1;
      height: 88px;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .time {
        font-size: 18px;
        line-height: 18px;
        color: var(--gray11);
        font-family: "Regular";
      }
    }

    .type {
      font-size: 14px;
      line-height: 20px;
      color: var(--white);
      font-family: "Black";
      padding: 0 8px;
      margin: 0 25px;
      box-sizing: border-box;
      border-radius: 20px;
      background-color: var(--blue3)
    }

    .apk, .psd, .jpg, .mp4 {
      @extend .type;
    }

    .apk {
      background-color: var(--red3);
    }

    .psd {
      background-color: var(--pruple1);
    }

    .jpg {
      background-color: var(--blue3);
    }

    .mp4 {
      background-color: var(--green2);
    }

    .download_btn {
      display: block;
      width: 210px;
      height: 45px;
      border-radius: 5px;
      font-size: 16px;
      line-height: 45px;
      color: var(--white);
      font-family: "Black";
      text-align: center;
      cursor: pointer;
      margin-left: 15px;
      text-transform: uppercase
    }

    .download_btn1, .download_btn2, .download_btn3, .download_btn4 {
      @extend .download_btn;
    } 

    .download_btn1 {
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: 16px;
      background-color: var(--yellow3);
    }

    .download_btn2 {
      background-color: var(--yellow3);
    }

    .download_btn3 {
      position: relative;
      background-color: var(--yellow3);
    }

    .download_btn4 {
      background-color: var(--blue4);
    }

    .line {
      position: absolute;
      width: 104px;
      left: 50%;
      margin-left: -52px;
      bottom: -10px;
      height: 5px;
      border-radius: 5px;
      background-color: var(--gray19);
      overflow: hidden;
    }

    .activeLine {
      position: absolute;
      left: 0;
      top: 0;
      height: 5px;
      width: 50%;
      background-color: var(--yellow3);
    }
  }

  >ul.vi li {
    .download_btn {
      font-size: 14px;
    }
  }

  .page {
    position: absolute;
    right: 65px;
    bottom: 94px;

    // span {
    //   font-size: 15px;
    //   line-height: 18px;
    //   color: var(--black4);
    //   font-family: "Bold";
    //   margin: 0 8px;
    //   cursor: pointer;
    // }

    // span.active {
    //   color: var(--yellow3);
    //   text-decoration: underline;
    //   pointer-events: none;
    // }
  }
}