.user_welcome {
  position: relative;
  width: 100%;
  min-height: 460px;
  background-color: var(--white);
  border-radius: 8px 8px 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  .guide_enter {
    position: absolute;
    width: 36px;
    height: 36px;
    top: 32px;
    right: 32px;
    background: url(~@/assets/images/guide_icon.png) no-repeat center/contain;
    cursor: pointer;

    .num {
      position: absolute;
      width: 22px;
      height: 22px;
      right: -16px;
      top: -13px;
      border-radius: 22px;
      background-color: var(--red4);
      font-size: 14px;
      line-height: 22px;
      color: var(--white);
      font-family: "Bold";
      text-align: center;
    }
  }

  .level_box {
    position: relative;
    width: 192px;
    height: 192px;
    border-radius: 50%;
    margin: 45px auto 15px;
    background-color: var(--gray43);
    border: 1px #dcdcdc solid;

    img {
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .welcome_txt {
    width: 100%;

    .lv_name {
      width: 100%;
      text-align: center;
      font: 32px "Black";
      line-height: 28px;
      color: var(--yellow3);
    }

    p {
      font-size: 26px;
      line-height: 40px;
      color: var(--dark1);
      font-family: "Regular";
    }

    span {
      color: var(--yellow3);
      font-family: "Black";
    }
  }

  .comm_icons {
    width: 100%;
    margin: 40px auto;

    ul {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    li {
      margin: 0 20px;
    }

    i {
      display: block;
      background-image: url(~@/assets/images/comm_icons.png);
      background-size: 34px auto;
      background-repeat: no-repeat;
      cursor: pointer;
    }

    .youtube {
      width: 39px;
      height: 24px;
      background: url(~@/assets/images/yt_icon_mono_light.png) no-repeat center/contain !important;
    }

    .tiktok {
      width: 30px;
      height: 30px;
      background-position: left -30px;
    }

    .twitch {
      width: 30px;
      height: 32px;
      background-position: left -60px;
    }

    .creator {
      position: relative;
      width: 31px;
      height: 24px;
      background-position: left -100px;

      &.on::after {
        content: "";
        position: absolute;
        width: 8px;
        height: 8px;
        border-radius: 8px;
        background-color: var(--red1);
        right: -8px;
        top: -8px;
      }
    }

    .hr {
      width: 2px;
      height: 20px;
      background: var(--gray16);
      cursor: auto;
    }
  }

  .notice_box {
    width: 100%;
    height: auto;
    padding: 43px 0;
    box-sizing: border-box;
    background-color: var(--gray5);

    ul {
      width: 95%;
      height: auto;
      max-height: 400px;
      margin: 0 auto;
      overflow-y: auto;
      padding: 0 2.5%;
      box-sizing: border-box;

      &::-webkit-scrollbar {
        width: 4px;
      }
  
      &::-webkit-scrollbar-track {
        background: none;
        border-radius: 4px;
      }
  
      &::-webkit-scrollbar-thumb {
        background: var(--gray4);
        border-radius: 4px;
      }
    }

    li {
      position: relative;
      width: 100%;
      height: 66px;
      max-height: 66px;
      margin-bottom: 25px;
      padding: 0 130px 0 28px;
      box-sizing: border-box;
      border-radius: 8px;
      background-color: var(--white);
      transition: max-height .7s, margin-bottom .7s;
      overflow: hidden;

      &.hide {
        max-height: 0;
        margin-bottom: 0;
      }

      p {
        display: inline-block;
        width: 100%;
        font-size: 20px;
        line-height: 66px;
        color: var(--dark1);
        font-family: "Regular";
        text-align: left;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    em {
      font-style: italic;
      font-size: 22px;
      margin-right: 5px;
    }

    .todo_icons {
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
    }

    i {
      position: relative;
      width: 60px;
      height: 100%;
      float: right;
      cursor: pointer;
    }

    i.next::after {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      right: 50%;
      top: 50%;
      margin-top: -7px;
      margin-right: -7px;
      border-right: none;
      border-top: 7px transparent solid;
      border-bottom: 7px transparent solid;
      border-left: 14px var(--gray10) solid;
    }

    i.close::before, i.close::after {
      content: "";
      position: absolute;
      width: 20px;
      height: 2px;
      top: 50%;
      left: 50%;
      margin-top: -1px;
      margin-left: -10px;
      background-color: var(--gray10);
    }

    i.close::before {
      transform: rotate(45deg);
    }

    i.close::after {
      transform: rotate(-45deg);
    }
  }
}