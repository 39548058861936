.earnings_content {
  position: relative;
  width: 100%;
  height: auto;
  font-size: 14px;
  min-height: 750px;
  padding: 55px 44px;
  box-sizing: border-box;
  background-color: var(--white);
  border-radius: 8px;

  .title1 {
    font-size: 26px;
    line-height: 26px;
    color: var(--black4);
    font-family: "Black";
    text-align: left;
  }

  .question {
    display: inline-block;
    width: 25px;
    height: 25px;
    color: var(--white);
    font-size: 18px;
    line-height: 25px;
    font-family: "Black";
    margin: 0 15px;
    background-color: var(--yellow13);
    text-align: center;
    vertical-align: middle;
    border-radius: 50%;
    font-style: normal;
    cursor: pointer;
  }


  .hide {
    display: inline-block;
    width: 28px;
    height: 28px;
    vertical-align: middle;
    cursor: pointer;
    background: url(~@/assets/images/eyes.png) no-repeat top/cover;
    background-position-y: 8px;
  }

  .hide.active {
    background-position-y: bottom;
  }

  ul {
    width: 1085px;
    height: auto;
    min-height: 165px;
    margin-top: 25px;
    margin-left: -44px;
    padding: 45px 44px 30px;
    display: flex;
    justify-content: space-between;
    background-color: var(--gray27);
  }

  li {
    width: 33%;
    height: auto;
    flex: 1;
    margin-right: 38px;
    border-right: 1px var(--gray16) solid;
    word-wrap: break-word;
  }

  li:last-child {
    border-right: none;
    margin: 0;
  }

  .total_earnings_subtitle {
    font-size: 18px;
    line-height: 26px;
    color: var(--black4);
    font-family: "Black";
    text-transform: uppercase;
  }

  .num {
    font-size: 40px;
    line-height: 56px;
    color: var(--yellow13);
    font-family: "Bold";

    .subtxt1 {
      font-size: 22px;
      color: var(--black4);
      font-family: "Bold";
    }

    .subtxt2 {
      font-size: 22px;
      font-family: "Black";
    }
  }

  .none {
    width: 100%;
    font-size: 22px;
    line-height: 24px;
    padding-top: 16px;
    color: var(--gray15);
    font-family: "Black";
  }

  .tip {
    width: 100%;
    text-align: right;
    font-size: 22px;
    line-height: 22px;
    color: var(--yellow3);
    font-family: "Black";
    text-decoration: underline;

    span {
      cursor: pointer;
    }
  }

  .date_select {
    float: right;
  }

  .date_slice {
    font-size: 18px;
    line-height: 20px;
    padding: 0 10px;
  }
}
