.detail_content {
  position: relative;
  width: 100%;
  height: auto;
  font-size: 14px;
  min-height: 750px;
  padding: 50px 0;
  box-sizing: border-box;
  background-color: var(--white);
  border-radius: 8px;

  display: flex;
  flex-direction: column;

  .back {
    position: absolute;
    width: 52px;
    height: 52px;
    left: 25px;
    top: 25px;
    cursor: pointer;
    background: url(~@/assets/images/back.png) no-repeat center/contain;
  }

  em {
    font-family: "Bold";
  }

  .title {
    width: 100%;
    font-size: 48px;
    color: var(--yellow3);
    line-height: 60px;
    text-align: center;
    font-family: "Black";
  }

  .rewardContent {
    width: 100%;
    height: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .rewardTableBox {
    position: relative;
    width: 100%;
    height: auto;
    margin: 25px auto 0;
    padding-bottom: 25px;
    box-sizing: border-box;
    background-color: var(--white);
    border-radius: 10px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }

  .tableBoxText {
    width: 100%;
    height: 86px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 42px;
    box-sizing: border-box;

    p {
      line-height: 26px;
      font-size: 26px;
      color: var(--black4);
      font-family: "Black";
    }
  }

  .tableBoxText2 {
    width: 100%;
    height: 20px;
    text-align: right;
    padding: 0 42px;
    margin: -25px auto 10px;
    box-sizing: border-box;

    p {
      line-height: 20px;
      font-size: 20px;
      color: var(--black4);
      font-family: "Bold";
    }
  }

  .bounBox {
    width: 100%;
    height: 126px;
    padding: 0 42px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;

    p {
      font-size: 26px;
      color: var(--black4);
      line-height: 60px;
      font-family: "Black";
    }

    P:nth-child(2) {
      font-size: 25px;
      text-align: right;
    }

    span {
      color: var(--yellow3);

      &.pre {
        font-size: 26px;
      }
    }

    em {
      font-size: 42px;
      font-family: "Bold";
    }

    .pre {
      color: var(--gray20);
      font-size: 30px;
      font-family: "Bold";
    }

  }

  .question {
    width: 100%;
    padding: 0 42px;
    box-sizing: border-box;
    text-align: left;
    font: 20px "Regular";
    line-height: 24px;
    color: var(--black4);

    p:nth-child(1) {
      font-size: 26px;
      color: var(--black4);
      line-height: 60px;
      font-family: "Black";
      text-align: right;
    }
  }
  
  .rewardTable {
    width: 100%;
    height: auto;
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .table_head {
      width: 100%;
      height: 66px;
      margin-bottom: 12px;
      background-color: var(--gray27);
      font-size: 18px;
      color: var(--black4);
      line-height: 66px;
      font-family: "Black";
      text-align: center;
      align-items: center;
      display: flex;

      li:nth-child(1) {
        width: 36.6%;
      }

      li:nth-child(2) {
        flex: 1;
        width: 20%;
      }

      li:nth-child(3) {
        flex: 1;
        width: 20%;
      }

      li:nth-child(4) {
        flex: 1;
        width: 20%;
      }

      li:nth-child(5) {
        flex: 1;
        width: 20%;
      }

      // li:nth-child(1) {
      //   width: 30%;
      // }

      // li:nth-child(2) {
      //   width: 20%;
      // }

      // li:nth-child(3) {
      //   width: 15%;
      // }

      // li:nth-child(4) {
      //   width: 20%;
      // }

      // li:nth-child(5) {
      //   width: 15%;
      // }

      li {
        position: relative;
        height: 38px;
        line-height: 20px;
        border-right: 1px rgba(0, 0, 0, 0.15) solid;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;

        &:last-child {
          border: none;
        }
      }

      .sort {
        display: none;
        position: absolute;
        height: 100%;
        right: 20px;
        width: 30px;
        cursor: pointer;

        &::before, &::after {
          content: "";
          position: absolute;
          width: 0;
          height: 0;
          border-left: 5px transparent solid;
          border-right: 5px transparent solid;
        }

        &.sort1::before {
          top: 8px;
          border-bottom: 7px var(--gray23) solid;
        }

        &.sort1::after {
          bottom: 8px;
          border-top: 7px var(--dark1) solid;
        }

        &.sort2::before {
          top: 8px;
          border-bottom: 7px var(--dark1) solid;
        }

        &.sort2::after {
          bottom: 8px;
          border-top: 7px var(--gray23) solid;
        }
      }
    }

    .table_body {
      width: 97%;
      height: 100%;
      max-height: 400px;
      flex: 1;
      margin: 0 auto;
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 4px;
      }
  
      &::-webkit-scrollbar-track {
        background: none;
        border-radius: 4px;
      }
  
      &::-webkit-scrollbar-thumb {
        background: var(--gray4);
        border-radius: 4px;
      }

      ul {
        width: 100%;
        display: flex;
        border-bottom: 1px rgba(0, 0, 0, 0.08) solid;
  
        li:nth-child(1) {
          width: 36%;
        }
  
        li:nth-child(2) {
          width: 20.6%;
          flex: 1;
        }
  
        li:nth-child(3) {
          width: 20.9%;
          flex: 1;
        }
  
        li:nth-child(4) {
          width: 20.9%;
          flex: 1;
        }
  
        li:last-child {
          flex: 1;
          width: 18.7%;
          padding: 0;
        }

        // li:nth-child(1) {
        //   width: 29.4%;
        // }
  
        // li:nth-child(2) {
        //   width: 20.6%;
        // }
  
        // li:nth-child(3) {
        //   width: 15.4%;
        // }
  
        // li:nth-child(4) {
        //   width: 20.9%;
        // }
  
        // li:nth-child(5) {
        //   width: 13.7%;
        //   padding-right: 0;
        //   padding-left: 15px;
        // }
      }

      li {
        height: auto;
        font-size: 22px;
        line-height: 26px;
        color: var(--black4);
        padding: 10px;
        box-sizing: border-box;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        word-break: break-all;
        font-family: "Regular";

        &:nth-child(1) {
          text-align: left;
          justify-content: start;
        }
      }
    }
  }

  .noData {
    width: 100%;
    height: 66px;
    margin: 20px auto;
    padding: 0 42px;
    box-sizing: border-box;
    font: 20px "Regular";
    line-height: 66px;
    color: var(--black4);
    text-align: left;
    background-color: var(--gray27);
  }

  .slice {
    width: 98%;
    height: 1px;
    margin: 20px auto 30px;
    background-color: rgba(0, 0, 0, 0.2);
  }
}
