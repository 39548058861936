.guide_main {
  position: relative;
  width: 100%;
  height: 110vh;
  box-sizing: border-box;
  text-align: center;
  overflow: hidden;
  padding-top: 200px;
  box-sizing: border-box;
  background: #f6b92e linear-gradient(20deg, #eb990a, #f6ba2f);
}