.guide_main {
  position: relative;
  width: 100%;
  min-height: 100%;
  box-sizing: border-box;
  text-align: center;
  overflow: hidden;
  box-sizing: border-box;
  padding: 1.8rem 0;
  background: #f6b92e linear-gradient(20deg, #eb990a, #f6ba2f);
}