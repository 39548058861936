.table_box {
  width: 100%;
  height: auto;
  padding-bottom: 10px;
  box-sizing: border-box;

  .table_title {
    width: 100%;
    font-size: 26px;
    line-height: 40px;
    font-family: "Bold";
    padding-top: 20px;
  }

  table {
    width: 100%;
    height: auto;
    font-size: 24px;
    text-align: center;
    word-break: break-word;
  }
}

.table_box.m {
  .table_title {
    font-size: 0.22rem;
    line-height: 0.36rem;
    padding-top: 0.16rem;
  }

  table {
    font-size: 0.2rem;
  }
}