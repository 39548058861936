.upload_box {
  position: relative;
  width: 6.75rem;
  height: auto;
  padding: .4rem .44rem;
  margin: .4rem auto;
  box-sizing: border-box;
  background-color: var(--white);
  border-radius: .08rem;

  .title1 {
    font-size: .26rem;
    line-height: .26rem;
    color: var(--black4);
    font-family: "Black";
    text-align: left;
  }

  .upLoadTip {
    font-size: 0.2rem;
    color: var(--gray11);
    line-height: 0.26rem;
    font-family: "Regular";
    margin-top: .10rem;
    margin-bottom: -.20rem;
  }

  .url_upload {
    position: relative;
    width: 100%;
    margin-top: .35rem;

    input {
      display: block;
      width: 3.72rem;
      height: .66rem;
      text-align: left;
      padding: 0 .2rem;
      box-sizing: border-box;
      font-size: .24rem;
      color: var(--black4);
      line-height: .66rem;
      font-family: "Regular";
      background: var(--gray16);
      border: none;
      border-radius: .05rem;
    }

    .submit {
      position: absolute;
      right: 0;
      top: 0;
      width: 2.06rem;
      height: .68rem;
      text-align: center;
      font-size: .18rem;
      color: var(--white);
      line-height: .68rem;
      font-family: "Black";
      background-color: var(--yellow3);
      border-radius: 5px;
      cursor: pointer;
    }
  }

  .fill_chose {
    width: 100%;
    text-align: right;
    margin: .35rem 0 .45rem;
    font-size: 0;

    i {
      display: inline-block;
      width: .26rem;
      height: .26rem;
      margin-right: .10rem;
      box-sizing: border-box;
      border: .02rem #a1a1a1 solid;
      border-radius: .05rem;
      background: transparent;
      vertical-align: top;
      transition: background-color .2s, border-color .2s;
      cursor: pointer;
    }

    i.active {
      background: var(--green3) url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAALCAYAAACgR9dcAAAAqElEQVQokZ2RrQoCQRRGF8Gy1WiwWgWzTbCaxFfYYjdsEex2o8FqFPEVfAMR4xaDGGTLWQbu4Mewg+xemGHuuXMu85MAbUYOPNqIGRZNxQW/ODURZyJeHXOwC0z/iGMRb567aWPwEhGHQGl77kCq8lK6HgKxD7ytVgA9rfvFShrsjHWAp7EvMAhPpclWGqyBs+SjuiuFYC+Cj0nsIevgEfgAL2Ae/QFIKiaAGR7xXNbLAAAAAElFTkSuQmCC) no-repeat center center;
      border-color: var(--green3);
    }

    span {
      font-size: .20rem;
      line-height: .26rem;
      color: var(--black4);
      font-family: "Black";
      vertical-align: top;
    }
  }

  .total_box {
    width: 100%;
    display: flex;
    justify-content: space-between;
    text-align: left;
    flex-wrap: wrap;

    .total_item {
      width: 2.8rem;

      p {
        font-size: .2rem;
        line-height: .4rem;
        color: var(--black4);
        font-family: "Black";
      }

      input {
        display: block;
        width: 100%;
        height: .66rem;
        text-align: left;
        padding: 0 .20rem;
        box-sizing: border-box;
        font-size: .24rem;
        color: var(--black4);
        line-height: .66rem;
        font-family: "Regular";
        background: var(--gray16);
        border: none;
        border-radius: 5px;
      }
    }

    .total_item2 {
      @extend .total_item;
      width: 100%;
    }
  }

  .analyze_data {
    margin-top: .6rem;
  }

  .time {
    font-size: .22rem;
    line-height: .22rem;
    color: var(--black4);
    text-align: left;
    font-family: "Bold";
  }

  .total_num {
    width: 6.75rem;
    margin-top: .24rem;
    margin-left: -.44rem;
    padding: 0 .44rem;
    background-color: var(--gray27);
    text-align: left;

    ul {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
    }

    li {
      position: relative;
      width: 33%;
      height: 1.18rem;
      padding-left: .15rem;
      box-sizing: border-box;
      white-space: nowrap;

      p:nth-child(1) {
        font-size: .16rem;
        line-height: .3rem;
        color: var(--black4);
        font-family: "Black";
      }

      p:nth-child(2) {
        font-size: .38rem;
        line-height: .6rem;
        color: var(--yellow13);
        font-family: "Bold";
      }

      p.right {
        color: var(--gray18);
        line-height: .7rem;
      }

      &:nth-child(2)::after, &:nth-child(3)::after {
        content: "";
        position: absolute;
        right: 0.1rem;
        top: 50%;
        width: 1px;
        height: .74rem;
        margin-top: -.54rem;
        background-color: var(--gray21);
      }
    }

    li:nth-child(1) {
      width: 100%;
      height: .7rem;
    }

    li:nth-child(1), li:nth-child(2) {
      padding-left: 0;
    }
  }

  .screenshot {
    width: 100%;
    margin-top: .4rem;

    img {
      width: 100%;
      height: auto;
      margin-bottom: .2rem;
    }

    &::-webkit-scrollbar {
      width: .04rem;
    }

    &::-webkit-scrollbar-track {
      background: none;
      border-radius: .04rem;
    }

    &::-webkit-scrollbar-thumb {
      background: var(--gray4);
      border-radius: .04rem;
    }
  }
  
  .upload {
    width: 100%;
    margin-top: .3rem;
  }
}

:global {
  .ant-upload-list.ant-upload-list-picture-card > div {
    opacity: 0;
    width: 0;
    overflow: hidden;
  }

  .ant-upload-list.ant-upload-list-picture-card > div:nth-child(1) {
    opacity: 1;
    width: 104px;
    display: inline-block;
  }

  .ant-upload-list-item-actions {
    transform: scale(2) translate(-50%, -50%);

    >a {
      display: none;
    }
  }

  .ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-delete {
    margin: 0 auto;
  }
}