.task_nav {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: .52rem auto .85rem;
}

.task_num {
  position: relative;
  width: .67rem;
  height: .67rem;
  text-align: center;
  line-height: .67rem;
  font-size: .43rem;
  font-family: "Black";
  color: var(--yellow22);
  border-radius: 50%;
  border: .03rem var(--yellow22) solid;

  span {
    position: absolute;
    min-width: 1.4rem;
    top: .80rem;
    left: 50%;
    font-size: .16rem;
    line-height: .18rem;
    color: var(--yellow22);
    font-family: "Black";
    transform: translateX(-50%);
    text-align: center;
  }
}

.task_num.active {
  color: var(--white);
  border-color: var(--white);

  span {
    color: var(--white);
  }
}

.task_line {
  width: 1.15rem;
  height: .04rem;
  background-color: var(--yellow22);
}

.task_line.active {
  background-color: var(--white);
}