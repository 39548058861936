.manage_content {
  position: relative;
  width: 100%;
  height: auto;
  min-height: 700px;
  padding: 55px 44px;
  box-sizing: border-box;
  background-color: var(--white);
  border-radius: 8px;

  .title1 {
    padding-top: 6px;
    margin-bottom: 20px;

    >span {
      font-size: 26px;
      line-height: 26px;
      color: var(--black4);
      font-family: "Black";
      text-align: left;
      margin-right: 22px;
    }
  }

  .query {
    position: absolute;
    right: 67px;
    top: 55px;

    input {
      width: 346px;
      height: 40px;
      line-height: 40px;
      font-size: 14px;
      color: var(--black3);
      font-family: "Regular";
      padding: 0 56px 0 26px;
      box-sizing: border-box;
      border-radius: 40px;
      border: 1px #737373 solid;
      background: none;
    }

    i {
      position: absolute;
      width: 23px;
      height: 23px;
      right: 20px;
      top: 50%;
      margin-top: -11px;
      cursor: pointer;
      background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAXCAMAAADX9CSSAAAApVBMVEUAAAA7Ozs9PT08PDw8PDw8PDw7Ozs9PT09PT08PDwyMjI6Ojo/Pz89PT09PT0+Pj48PDw5OTk8PDw9PT09PT01NTU8PDw6Ojo+Pj4+Pj45OTk8PDw9PT06OjpAQEA8PDw9PT06OjpEREQ8PDw+Pj46Ojo5OTksLCwzMzMuLi45OTlBQUE+Pj4+Pj43Nzc4ODg+Pj4+Pj42NjY9PT0+Pj5AQEBEREROboehAAAAM3RSTlMAG/z36scY2dTDEAjoq6aak4Ytt4t8V0RANCQiFQv75uLVz8y/s7OqnJSQjHVtZFNPPSQtBXkYAAAA1UlEQVQoz3WPV3LDMBBDWUR1R8VxSWyn954A1P2PlhGlKKTHxgex80AOscJJLoyGzt5iEeiUBNAfjUfzG8t6tS3iVQXe5RNPOiPH8Tzl7R+ubOY9Tu3LeIUXhcelwvD5U9eEHezCuWZY7ozXznkpQinsekO2xyNKx5M9rpk7ziLAP4ycz/kR8HdbO1+jDHiJdhhM9+zhx+5+nDbKvk64tvp/E8X0q++wXSYEqinYGBJplgCYr68wE5OWDyCpZp9CyNIPxK79bodF4ggn4pDk8YBHAtP8AuK3E9qVr3m9AAAAAElFTkSuQmCC) no-repeat center/contain;
    }
  }

  .query_list {
    position: relative;
    width: 100%;
    margin: 10px 0;

    > div {
      margin-right: 30px;
      margin-bottom: 8px;
    }

    > div:last-child {
      margin-right: 0;
    }

    .update {
      position: absolute;
      right: 24px;
      top: 2px;
      width: 35px;
      height: 35px;
      background-color: var(--gray19);
      cursor: pointer;
      border-radius: 5px;

      &::after {
        content: '';
        position: absolute;
        width: 19px;
        height: 19px;
        top: 50%;
        left: 50%;
        margin-top: -9px;
        margin-left: -9px;
        background: url(~@/assets/images/update.png) no-repeat center/contain;
      }

      &.on::after {
        animation: router_update 2s linear infinite;
      }
    }
  }

  .content_data {
    position: relative;
    height: auto;
    width: 100%;
    margin: 5px auto 10px;

    .data_list {
      width: 100%;
      height: auto;
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
  
      li {
        width: 25%;
        height: auto;
        flex: 1;
        margin-right: 38px;
        border-right: 1px var(--gray16) solid;
      }
    
      li:last-child {
        border-right: none;
        margin: 0;
      }
    
      .content_subtitle {
        display: inline-block;
        height: 0.52rem;
        font-size: 18px;
        line-height: 26px;
        color: var(--black4);
        font-family: "Black";
        text-transform: uppercase;
      }
    
      .num {
        font-size: 40px;
        line-height: 56px;
        color: var(--yellow13);
        font-family: "Bold";
      }
    
      .bite {
        display: inline-block;
        font-size: 17px;
        color: var(--red4);
        line-height: 28px;
        font-family: "Bold";
        padding: 0 18px;
        box-sizing: border-box;
        background-color: var(--gray22);
        border-radius: 28px;
        margin-top: 5px;
      }
    }
  }

  .gamefeed_list {
    width: 100%;
    height: auto;
    margin-top: 24px;
    padding-bottom: 50px;
    box-sizing: border-box;
    text-align: left;

    li {
      position: relative;
      width: 100%;
      height: 162px;
      padding: 24px 0;
      box-sizing: border-box;
      border-bottom: 1px var(--gray19) solid;
    }

    .img_box {
      float: left;
      height: 112px;
      width: 213px;
      margin-right: 40px;
      border-radius: 10px;
      overflow: hidden;

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }

    .title {
      display: -webkit-box;
      width: auto;
      max-width: 500px;
      height: 40px;
      height: auto;
      font-size: 26px;
      line-height: 34px;
      color: var(--black3);
      font-family: "Regular";
      margin-bottom: 30px;
      box-sizing: border-box;
      overflow: hidden;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      cursor: pointer;
      word-wrap: break-word;
    }

    .desc {
      font-size: 18px;
      line-height: 20px;
      color: var(--gray11);
      font-family: "Regular";

      span {
        display: inline-block;
        min-width: 110px;
        margin-right: 10px;
      }

      .time {
        color: var(--dark3);
      }

      i {
        display: inline-block;
        margin-right: 6px;
      }

      .view {
        width: 22px;
        height: 20px;
        vertical-align: bottom;
        background: url(~@/assets/images/eyes.png) no-repeat bottom/cover;
      }

      .youtube_view {
        width: 5px;
        height: 20px;
        vertical-align: bottom;
      }

      .like {
        width: 21px;
        height: 17px;
        vertical-align: top;
        background: url(~@/assets/images/like.png) no-repeat center/contain;
      }
      
      .youtube_like {
        width: 21px;
        height: 17px;
        vertical-align: top;
        background: url(~@/assets/images/youtube_like.svg) no-repeat center/contain;
        background-size: 60px auto;
      }

      .comm {
        width: 21px;
        height: 19px;
        vertical-align: top;
        background: url(~@/assets/images/comm.png) no-repeat center/contain;
      }
    }

    .creatieTime {
      position: absolute;
      right: 70px;
      bottom: 24px;
      font-size: 21px;
      line-height: 34px;
      color: var(--black3);
      font-family: "Regular";
    }

    .more {
      position: absolute;
      right: 24px;
      bottom: 24px;
      width: 35px;
      height: 35px;
      background: url(~@/assets/images/more.png) no-repeat center/contain;
      cursor: pointer;

      &:hover {
        .more_item {
          display: block;
        }
      }
    }

    .more_item {
      display: none;
      position: absolute;
      left: 0;
      top: 30px;
      padding-top: 10px;
      box-sizing: border-box;

      >div {
        width: 85px;
        height: 34px;
        font-size: 12px;
        line-height: 34px;
        color: var(--white);
        font-family: "Black";
        text-align: center;
        border-radius: 5px;
        cursor: pointer;
        background-color: var(--gray19);
      }
    }

    .select_box {
      position: absolute;
      right: 24px;
      top: 24px;
    }
  }

  .page {
    position: absolute;
    right: 55px;
    bottom: 44px;
  }

  .upLoad_tip {
    width: 457px;
    height: 285px;
    margin: 0 auto;
    cursor: pointer;
    background: url(~@/assets/images/upload_tip.png) no-repeat center/contain;
  }
}

@keyframes router_update {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}