.upload_box {
  position: relative;
  width: 100%;
  height: auto;
  min-height: 760px;
  padding: 55px 73px;
  box-sizing: border-box;
  border-radius: 8px;
  background-color: var(--white);

  .title1 {
    font-size: 26px;
    line-height: 26px;
    color: var(--black4);
    font-family: "Black";
    text-align: left;
  }

  .upLoadTip {
    font-size: 24px;
    color: var(--gray11);
    line-height: 32px;
    font-family: "Regular";
    margin-top: 10px;
    margin-bottom: -20px;
  }

  .url_upload {
    position: relative;
    width: 100%;
    margin-top: 35px;

    input {
      display: block;
      width: 705px;
      height: 68px;
      text-align: left;
      padding: 0 40px;
      box-sizing: border-box;
      font-size: 24px;
      color: var(--black4);
      line-height: 68px;
      font-family: "Regular";
      background: var(--gray16);
      border: none;
      border-radius: 5px;
    }

    .submit {
      position: absolute;
      right: 0;
      top: 0;
      width: 218px;
      height: 68px;
      text-align: center;
      font-size: 18px;
      color: var(--white);
      line-height: 68px;
      font-family: "Black";
      background-color: var(--yellow3);
      border-radius: 5px;
      cursor: pointer;
    }
  }

  .fill_chose {
    width: 100%;
    text-align: right;
    margin: 35px 0 15px;

    i {
      display: inline-block;
      width: 26px;
      height: 26px;
      margin-right: 10px;
      box-sizing: border-box;
      border: 2px #a1a1a1 solid;
      border-radius: 5px;
      background: transparent;
      vertical-align: top;
      transition: background-color .2s, border-color .2s;
      cursor: pointer;
    }

    i.active {
      background: var(--green3) url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAALCAYAAACgR9dcAAAAqElEQVQokZ2RrQoCQRRGF8Gy1WiwWgWzTbCaxFfYYjdsEex2o8FqFPEVfAMR4xaDGGTLWQbu4Mewg+xemGHuuXMu85MAbUYOPNqIGRZNxQW/ODURZyJeHXOwC0z/iGMRb567aWPwEhGHQGl77kCq8lK6HgKxD7ytVgA9rfvFShrsjHWAp7EvMAhPpclWGqyBs+SjuiuFYC+Cj0nsIevgEfgAL2Ae/QFIKiaAGR7xXNbLAAAAAElFTkSuQmCC) no-repeat center center;
      border-color: var(--green3);
    }

    span {
      font-size: 20px;
      line-height: 26px;
      color: var(--black4);
      font-family: "Black";
      vertical-align: top;
    }
  }

  .total_box {
    width: 100%;
    display: flex;
    justify-content: space-between;
    text-align: left;

    .total_item, .total_item2 {
      width: 295px;

      p {
        font-size: 22px;
        line-height: 44px;
        color: var(--black4);
        font-family: "Black";
      }

      input {
        display: block;
        width: 100%;
        height: 68px;
        text-align: left;
        padding: 0 20px;
        box-sizing: border-box;
        font-size: 24px;
        color: var(--black4);
        line-height: 68px;
        font-family: "Regular";
        background: var(--gray16);
        border: none;
        border-radius: 5px;
      }
    }
    
    .total_item2 {
      width: 100%;
    }
  }

  .analyze_data {
    margin-top: 60px;
  }

  .time {
    font-size: 22px;
    line-height: 22px;
    color: var(--black4);
    text-align: left;
    font-family: "Bold";
  }

  .total_num {
    width: 1085px;
    height: 160px;
    margin-top: 24px;
    margin-left: -73px;
    padding: 0 73px;
    background-color: var(--gray27);
    text-align: left;

    ul {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    li {
      width: 25%;
      height: 104px;
      flex: 1;
      padding-left: 37px;
      box-sizing: border-box;
      border-right: 1px #e7e7e7 solid;

      p:nth-child(1) {
        font-size: 18px;
        line-height: 40px;
        color: var(--black4);
        font-family: "Black";
      }

      p:nth-child(2) {
        font-size: 47px;
        line-height: 64px;
        color: var(--yellow13);
        font-family: "Bold";
      }

      p.right {
        color: var(--gray18);
      }
    }

    li:first-child, li:nth-child(4) {
      border: none;
    }
  }

  .screenshot {
    width: 100%;
    height: 360px;
    margin-top: 40px;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background: none;
      border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background: var(--gray4);
      border-radius: 4px;
    }

    img {
      width: auto;
      height: auto;
      max-width: 50%;
    }
  }
  
  .upload {
    position: relative;
    width: 100%;
    min-height: 455px;
    margin: 70px auto 0;
    border-radius: 10px;
    background-color: var(--white);
    padding: 25px;
    box-sizing: border-box;
    cursor: pointer;
    text-align: left;
    background-color: var(--gray16);

    > span > div > div:last-child {
      border: none;
      background: none;
    }

    .upload_default {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
  
    .upload_icon {
      position: absolute;
      top: 50%;
      left: 50%;
      background-color: var(--gray10);
      border-radius: 14px
    }

    .upload_icon1 {
      @extend .upload_icon;
      width: 100px;
      height: 14px;
      margin-top: -47px;
      margin-left: -50px
    }

    .upload_icon2 {
      @extend .upload_icon;
      width: 14px;
      height: 100px;
      margin-top: -90px;
      margin-left: -7px
    }

    p {
      position: absolute;
      width: 100%;
      top: 350px;
      left: 0;
      font-size: 26px;
      color: var(--gray11);
      font-family: "Regular";
      text-align: center;
    }

    .upload_action {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      opacity: 0;
      z-index: 5;
      cursor: pointer;
    }
  }
}

:global {
  .upload_box {
    .ant-upload-picture-card-wrapper,
    .ant-upload-list {
      height: 100%;
      text-align: center;
    }

    .ant-upload-list-picture-card-container {
      width: 408px;
      height: 408px;
    }

    .ant-upload-list-item-info::before {
      left: 0;
    }

    .ant-upload-list-item-actions {
      transform: scale(2) translate(-50%, -50%);

      >a {
        display: none;
      }
    }

    .ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-delete {
      margin: 0 auto;
    }

    .ant-upload {
      width: 0;
    }

    .ant-upload-list-picture-card-container + .ant-upload {
      display: none;
    }
    
    .ant-upload-list-picture-card-container + .ant-upload {
      & > span > div > * {
        opacity: 0;
      }
    }
  }
}