.guide_main {
  position: relative;
  width: 100%;
  height: 110vh;
  min-height: 900px;
  box-sizing: border-box;
  text-align: center;
  overflow: hidden;
  background: #f6b92e linear-gradient(20deg, #eb990a, #f6ba2f);

  .hide {
    animation: hide .5s linear forwards;
  }

  .welcome,
  .task_box {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .cz_icon {
    width: 339px;
    height: auto;
    margin: 177px auto 40px;

    img {
      width: 100%;
      height: auto;
      opacity: 0;
      animation: show1 .6s ease forwards;
      animation-delay: .2s;
    }
  }

  .welcome_text {
    width: 100%;
    font-size: 56px;
    line-height: 66px;
    color: var(--white);
    font-family: "Black";
    text-align: center;
    text-transform: uppercase;
    opacity: 0;
    animation: show2 1.2s ease forwards;
    animation-delay: .7s;
  }

  .firework_left {
    position: absolute;
    left: 50%;
    top: 60px;
    margin-left: -700px;
    width: 199px;
    height: 331px;
    background: url(~@/assets/images/fireworks_left2.png) no-repeat center/contain;
    transform: scale(0);
    animation: show3 .5s ease forwards;
    animation-delay: .2s;
    transform-origin: right center;
  }

  .firework_right {
    position: absolute;
    right: 50%;
    top: 0;
    margin-right: -700px;
    width: 294px;
    height: 414px;
    background: url(~@/assets/images/fireworks_right2.png) no-repeat center/contain;
    transform: scale(0);
    animation: show3 .5s ease forwards;
    animation-delay: .2s;
    transform-origin: left center;
  }

  .community_rules_box {
    position: relative;
    width: 1380px;
    height: 605px;
    margin: 0 auto;
    border-radius: 10px;
    background-color: var(--white);
  }

  .community_title {
    width: 100%;
    font-size: 38px;
    line-height: 116px;
    color: var(--gray31);
    font-family: "Black";
    text-transform: uppercase;
  }

  .communit_detail {
    width: 1275px;
    height: 325px;
    padding: 0 20px;
    margin: 0 auto;
    overflow-y: auto;
    text-align: left;
    box-sizing: border-box;

    p {
      font-size: 20px;
      line-height: 25px;
      color: var(--gray32);
      font-family: "Regular";
    }

    .sub {
      font-size: 24px;
      line-height: 30px;
      font-family: "Bold";
    }

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background: none;
      border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background: var(--gray4);
      border-radius: 4px;
    }
  }

  .agree_btn {
    width: 272px;
    height: 75px;
    font-size: 19px;
    line-height: 65px;
    color: var(--white);
    text-align: center;
    margin: 20px auto 0;
    font-family: "Bold";
    background: url(~@/assets/images/btn2.png) no-repeat center center;
    background-size: 100% 100%;
    filter: grayscale(1);
    -webkit-filter: grayscale(1);
  }

  .agree_btn.active {
    filter: none;
    -webkit-filter: none;
    cursor: pointer;
  }

  .agree_tip {
    width: 100%;
    font-size: 16px;
    line-height: 24px;
    color: var(--yellow5);
    font-family: "Regular";
  }

  .creator_line {
    width: 1225px;
    font-size: 20px;
    line-height: 42px;
    color: var(--yellow21);
    font-family: "Black";
    text-align: left;
    margin: 0 auto;
    cursor: pointer;
    text-decoration: underline;
  }

  .accept {
    width: 1225px;
    font-size: 16px;
    line-height: 18px;
    color: var(--dark1);
    font-family: "Regular";
    text-align: left;
    margin: 0 auto;

    i {
      position: relative;
      display: inline-block;
      width: 16px;
      height: 16px;
      line-height: 16px;
      margin-right: 5px;
      border-radius: 50%;
      border: var(--gray31) 1px solid;
      vertical-align: top;
      cursor: pointer;
    }

    i.active::after {
      content: "";
      position: absolute;
      width: 10px;
      height: 10px;
      top: 50%;
      left: 50%;
      margin-top: -5px;
      margin-left: -5px;
      background-color: var(--yellow21);
      border-radius: 50%;
    }
  }
}

@keyframes show1 {
  0% {
    transform: scale(0);
    opacity: 0;
  }

  90% {
    transform: scale(1.05);
    opacity: 1;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes show2 {
  0% {
    transform: translateY(20px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes show3 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes show4 {
  0% {
    transform: rotateY(180deg);
  }

  100% {
    transform: rotateY(0);
  }
}

@keyframes show5 {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes hide {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(50px);
  }
}