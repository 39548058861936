.earnings_content {
  position: relative;
  width: 6.74rem;
  height: auto;
  padding: .43rem .44rem;
  margin: .4rem auto 0;
  box-sizing: border-box;
  background-color: var(--white);
  border-radius: .08rem;

  .title1, .title2 {
    font-size: .26rem;
    line-height: .26rem;
    color: var(--black4);
    font-family: "Black";
    text-align: left;
  }

  .title2 {
    font-size: .22rem;
    line-height: .22rem;
    padding-top: .6rem;
  }

  .question {
    display: inline-block;
    width: .25rem;
    height: .25rem;
    color: var(--white);
    font-size: .18rem;
    line-height: .25rem;
    font-family: "Black";
    margin: 0 .15rem;
    background-color: var(--yellow13);
    text-align: center;
    vertical-align: middle;
    border-radius: 50%;
    font-style: normal;
    cursor: pointer;
  }


  .hide {
    display: inline-block;
    width: .28rem;
    height: .28rem;
    vertical-align: middle;
    cursor: pointer;
    background: url(~@/assets/images/eyes.png) no-repeat top/cover;
    background-position-y: .08rem;
  }

  .hide.active {
    background-position-y: bottom;
  }

  ul {
    width: 6.74rem;
    height: auto;
    margin-top: .2rem;
    margin-left: -.44rem;
    padding: .38rem .44rem;
    display: flex;
    justify-content: space-between;
    background-color: var(--gray27);
    flex-wrap: wrap;
  }

  .list2 {
    margin-top: .6rem;
    margin-bottom: .6rem;
  }

  li {
    width: 49%;
    height: auto;
    margin-bottom: .4rem;
    word-wrap: break-word;
  }

  li:nth-child(2) {
    position: relative;
    padding-left: .5rem;
    box-sizing: border-box;

    &::after {
      position: absolute;
      content: "";
      width: 1px;
      height: .6rem;
      left: 0;
      top: 50%;
      margin-top: -.4rem;
      background-color: var(--gray29);
    }
  }

  li:nth-child(3) {
    margin-bottom: 0;
  }


  .total_earnings_subtitle {
    font-size: .2rem;
    line-height: .26rem;
    color: var(--black4);
    font-family: "Black";
    text-transform: uppercase;
  }

  .num {
    font-size: .42rem;
    line-height: .56rem;
    color: var(--yellow13);
    font-family: "Bold";

    .subtxt1 {
      font-size: .22rem;
      color: var(--black4);
      font-family: "Bold";
    }

    .subtxt2 {
      font-size: .22rem;
      font-family: "Black";
    }
  }

  .none {
    width: 100%;
    font-size: .22rem;
    line-height: 0.34rem;
    color: var(--gray15);
    font-family: "Black";
  }

  .tip {
    width: 100%;
    text-align: right;
    font-size: .22rem;
    line-height: .22rem;
    color: var(--yellow3);
    font-family: "Black";
    text-decoration: underline;

    span {
      cursor: pointer;
    }
  }

  .date_select {
    width: 100%;
    margin: .1rem auto 0.2rem;
    
    > div {
      display: flex;
      align-items: center;

      span {
        margin: 0 .1rem;
        font-size: .3rem;
      }
    }
  }
}