.admin_contain {
  position: relative;
  width: 100%;
  height: auto;
  min-height: calc(115vh - 100px);
  background-color: var(--gray16);
  border-top: 1px transparent solid;
}

.admin_main {
  position: relative;
  width: 1460px;
  margin: 80px auto 0;
  display: flex;
  justify-content: space-between;
}

.admin_wrap {
  position: relative;
  width: 1085px;
  height: auto;
  padding-bottom: 40px;
  box-sizing: border-box;
}