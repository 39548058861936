.growth_content {
  position: relative;
  width: 6.75rem;
  margin: .4rem auto 0;
}

.growth_nav {
  width: 6.75rem;
  height: .9rem;
  font-size: 0;
  line-height: 0;
  white-space: nowrap;
  background-color: var(--white);
  border-radius: .08rem;
  overflow: hidden;

  a {
    position: relative;
    display: inline-block;
    width: 50%;
    height: 100%;
    font-size: .26rem;
    line-height: .9rem;
    color: var(--black4);
    text-align: center;
    font-family: "Black";
  }

  i {
    position: absolute;
    width: 0.08rem;
    height: 0.08rem;
    right: 0.15rem;
    top: 0.15rem;
    border-radius: 0.08rem;
    background-color: #F00;
  }


  :global {
    a.active {
      color: var(--white);
      background-color: var(--yellow3);
    }
  }
}

.growth_page {
  margin-top: .21rem;
}