.message_box {
  width: 100%;
  height: auto;
  min-height: 215px;
  font-size: 0;
  line-height: 0;
  margin-top: .4rem;
  padding: .55rem .45rem;
  box-sizing: border-box;
  border-radius: .08rem;
  background-color: var(--white);
  overflow: hidden;
  white-space: nowrap;
  text-align: left;

  .message_item {
    display: block;
    width: 100%;
    height: auto;
    min-height: 2.15rem;
    box-sizing: border-box;
    overflow: hidden;

    &:nth-child(2) {
      margin-top: .32rem;
      padding-top: .32rem;
      border-top: var(--gray23) solid 1px;
    }
  }

  .message_title {
    position: relative;
    width: 100%;
    margin-bottom: .34rem;

    p {
      font-size: .26rem;
      color: var(--black4);
      line-height: .26rem;
      font-family: "Black";
    }

    span {
      position: absolute;
      right: 0;
      top: 0;
      font-size: .2rem;
      line-height: .36rem;
      color: var(--yellow15);
      font-family: "Black";
      cursor: pointer;
    }
  }

  ul {
    position: relative;
    width: 100%;
    height: auto;

    li {
      position: relative;
      width: 100%;
      font-size: .22rem;
      line-height: .5rem;
      font-family: "Regular";

      p {
        display: inline-block;
        max-width: 4.4rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        vertical-align: bottom;
      }

      p > span:nth-child(1) {
        font-size: .2rem;
        color: var(--black4);
        font-family: "Black";
      }

      p > span:nth-child(2) {
        display: inline-block;
        max-width: 15em;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        vertical-align: middle;
      }

      p > span:nth-child(3) {
        display: inline-block;
        height: .23rem;
        min-width: .64rem;
        text-align: center;
        color: var(--white);
        font-size: .16rem;
        line-height: .23rem;
        font-family: "Black";
        padding: 0 .12rem;
        margin-left: .2rem;
        box-sizing: border-box;
        border-radius: .23rem;
        text-transform: uppercase;
      }

      > span {
        position: absolute;
        font-size: .18rem;
        color: var(--gray11);
        right: 0;
        top: 0;
      }

      .apk {
        background-color: var(--red3);
      }

      .psd {
        background-color: var(--pruple1);
      }

      .jpg {
        background-color: var(--blue3);
      }

      .mp4 {
        background-color: var(--green2);
      }
    }

    

    > li:nth-child(1),> li:nth-child(2), > li:nth-child(3) {
      p > span:nth-child(1) {
        color: var(--yellow3);
      }
    }

    .none_message {
      width: 100%;
      max-width: 100%;
      font-size: 40px;
      line-height: 80px;
      margin-top: 0px;
      text-align: center;
      color: var(--gray11);
      font-family: "Bold";
    }

  }
}