.detail_box {
  position: relative;
  width: 100%;
  height: auto;
  min-height: 670px;
  padding: 55px 44px;
  box-sizing: border-box;
  background-color: var(--white);
  border-radius: 8px;
  word-break: break-word;
  
  .back {
    position: absolute;
    width: 52px;
    height: 52px;
    left: 25px;
    top: 25px;
    cursor: pointer;
    background: url(~@/assets/images/back.png) no-repeat center/contain;
  }

  .title {
    width: 80%;
    margin: 0 auto;
    font-size: 28px;
    line-height: 32px;
    color: var(--black3);
    font-family: "Black";
    text-align: center;
  }

  .time {
    font-size: 18px;
    color: var(--gray20);
    line-height: 50px;
    font-family: "Regular";
    text-align: center;
  }

  .contain {
    width: 100%;
    min-height: 460px;
    height: auto;
    padding: 0 10px;
    box-sizing: border-box;
    overflow-y: auto;
    text-align: left;
    font-size: 18px;
    line-height: 24px;
    color: var(--black3);
    font-family: "Regular";

    img {
      width: auto;
      height: auto;
      max-width: 100%;
      display: block;
      margin: 10px auto;
    }

    video, iframe {
      max-width: 100%;
      width: auto;
      height: auto;
    }

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background: none;
      border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background: var(--gray4);
      border-radius: 4px;
    }
  }
}
