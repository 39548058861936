.information_content {
  position: relative;
  width: 100%;
  height: auto;
  padding-bottom: 1rem;
  box-sizing: border-box;
  background-color: var(--gray16);
  overflow: hidden;
  text-align: center;

  .title {
    width: 100%;
    font-size: .38rem;
    color: var(--black4);
    line-height: .38rem;
    font-family: "Black";
    margin: .7rem auto .4rem;
    text-transform: uppercase;
  }

  .content_1 {
    width: 6.84rem;
    height: auto;
    margin: 0 auto;
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    border-radius: .08rem;
    overflow: hidden;
    background-color: var(--gray5);
    text-align: left;
    font-size: 0;
  }

  .base_information {
    width: 100%;
    min-height: 8rem;
    height: auto;
    padding: .38rem .4rem;
    box-sizing: border-box;
    background-color: var(--white);
  }

  .sub_title {
    font-size: .26rem;
    color: var(--black4);
    margin-bottom: .36rem;
    font-family: "Black";
    text-transform: uppercase;
  }

  .edit {
    display: inline-block;
    width: .22rem;
    height: .22rem;
    cursor: pointer;
    margin-left: .20rem;
    vertical-align: middle;
    background: url(~@/assets/images/xiugai.png) no-repeat top/cover;
  }

  .input_box {
    position: relative;
    width: 100%;
    height: .64rem;
    font-size: 0;
    margin-bottom: .25rem;
    border-radius: .08rem;
    background: var(--gray5);
    overflow: hidden;

    input {
      width: 100%;
      height: .64rem;
      font-size: .2rem;
      color: var(--dark1);
      line-height: .64rem;
      padding: 0 .32rem;
      box-sizing: border-box;
      border: none;
      border-radius: .08rem;
      background: none;
      font-family: "Regular";
      cursor: auto;
    }

    .next {
      position: absolute;
      width: .85rem;
      height: .65rem;
      right: 0;
      top: 0;

      &::after {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        right: .35rem;
        top: 50%;
        margin-top: -.07rem;
        border-top: .07rem transparent solid;
        border-bottom: .07rem transparent solid;
        border-left: .13rem var(--gray10) solid;
      }
    }
  }

  .slide1 {
    width: 100%;
    height: .6rem;
  }

  .input_box2 {
    font-size: .2rem;
    line-height: .64rem;
    color: var(--dark1);
    font-family: "Regular";
    padding: 0 .32rem;
    box-sizing: border-box;

    .name {
      float: right;
    }
  }

  i.youtube {
    display: inline-block;
    width: .41rem;
    height: .29rem;
    vertical-align: middle;
    margin-right: .10rem;
    background: url(~@/assets/images/video_logo.png) top/cover;
  }

  i.tiktok {
    display: inline-block;
    width: .45rem;
    height: .45rem;
    vertical-align: middle;
    margin-right: .10rem;
    background: url(~@/assets/images/video_logo.png) top/cover;
    background-position-y: -.32rem;
  }

  i.twitch {
    display: inline-block;
    width: .45rem;
    height: .45rem;
    vertical-align: middle;
    margin-right: .10rem;
    background: url(~@/assets/images/video_logo.png) no-repeat top/cover;
    background-position-y: -.75rem;
  }

  .mediaList, .gameList {
    margin-top: -.20rem;
  }

  hr {
    margin-bottom: 0.3rem;

    &:last-child {
      display: none;
    }
  }

  .subTitle {
    display: inline-block;
    font-size: .22rem;
    line-height: .34rem;
    color: var(--black4);
    margin-bottom: .03rem;
    font-family: "Bold";
    text-transform: uppercase;
    text-indent: .05rem;

    .edit {
      margin-bottom: 0.06rem;
    }
  }

  .tip {
    display: inline-block;
    width: .12rem;
    height: .12rem;
    background-color: var(--red1);
    border-radius: 100%;
    vertical-align: top;
  }

  .level_box {
    position: relative;
    width: 100%;
    height: 3.14rem;
    background-color: var(--gray24);

    .level {
      position: absolute;
      width: 100%;
      height: 2.28rem;
      top: 50%;
      left: 0;
      margin-top: -1.14rem;
    }

    .level3 {
      background: url(~@/assets/images/lv1.png) no-repeat center center;
      background-size: auto 2rem;
    }

    .level2 {
      background: url(~@/assets/images/lv2.png) no-repeat center center;
      background-size: auto 2rem;
    }

    .level1 {
      background: url(~@/assets/images/lv3.png) no-repeat center center;
      background-size: auto 2rem;
    }

    .lv_name {
      position: absolute;
      bottom: 0.24rem;
      width: 100%;
      text-align: center;
      font: .4rem "Black";
      line-height: .40rem;
      color: var(--yellow3);
    }

    .lv_name+.level {
      margin-top: -1.36rem;
    }
  }

  .content_2 {
    width: 6.84rem;
    margin: .4rem auto 0;
    padding: .38rem .4rem;
    text-align: left;
    box-sizing: border-box;
    background-color: var(--white);
    overflow: hidden;
    border-radius: .08rem;
    font-size: 0;
  }

  .game_list {
    width: 100%;
    font-size: 0;
    line-height: 0;
  }

  .game_item {
    position: relative;
    width: 6.05rem;
    height: 2.45rem;
    border-radius: .08rem;
    margin: 0 auto .4rem;
    background-color: var(--gray5);
  }

  .game_logo {
    position: absolute;
    top: .3rem;
    left: .33rem;

    img {
      width: auto;
      height: .56rem;
      object-fit: contain;
    }
  }

  .user_desc {
    position: absolute;
    right: .30rem;
    top: .20rem;

    p {
      font-size: .20rem;
      color: var(--dark1);
      line-height: .30rem;
      text-align: center;
      font-family: "Regular";
      text-align: right;
    }
  }

  .tab_title {
    position: absolute;
    left: .33rem;
    top: 1.35rem;
    font-size: .21rem;
    line-height: .22rem;
    color: var(--black4);
    font-family: "Black";
  }

  .tab_list {
    position: absolute;
    left: .33rem;
    top: 1.78rem;
    text-align: left;
    font-size: 0;

    li {
      display: inline-block;
      height: .40rem;
      font-size: .16rem;
      color: var(--white);
      line-height: .40rem;
      font-family: "Black";
      border-radius: .40rem;
      padding: 0 .24rem;
      margin-right: .10rem;
      margin-bottom: .10rem;
      box-sizing: border-box;
      background-color: var(--yellow13);
    }
  }

  .content_3 {
    width: 6.84rem;
    height: auto;
    min-height: 9.3rem;
    margin: .4rem auto 0;
    padding: .38rem .4rem 0.6rem;
    text-align: left;
    box-sizing: border-box;
    background-color: var(--white);
    overflow: hidden;
    border-radius: .08rem;
    font-size: 0;

    .sub_title {
      font-size: .26rem;
      line-height: .26rem;
      text-align: center;
    }

    ul {
      width: 100%;
      margin-top: .34rem;
      text-align: center;
      font-size: 0;
      line-height: 0;
    }

    .module_desc {
      font-size: .20rem;
      line-height: .40rem;
      color: var(--dark1);
      font-family: "Regular";
      margin-top: -.30rem;
    }

    li {
      position: relative;
      display: inline-block;
      width: 1.92rem;
      height: .65rem;
      font-size: .2rem;
      height: .65rem;
      font-family: "Bold";
      border-radius: .08rem;
      cursor: pointer;
      background-color: var(--gray5);

      &:nth-child(2) {
        margin: 0 .14rem;
      }

      &.down::after {
        position: absolute;
        content: '';
        right: .35rem;
        top: .28rem;
        width: 0;
        height: 0;
        border-top: .16rem #acacac solid;
        border-left: .08rem transparent solid;
        border-right: .08rem transparent solid;
        border-bottom: none;
      }
    }

    select {
      display: block;
      width: 100%;
      height: 100%;
      text-align: center;
      font-size: .2rem;
      color: var(--dark1);
      font-family: "Regular";
      line-height: 0.65rem;
      background: none;
      border: none;
      outline: none;
      appearance: none;
      padding: 0 .4rem;
      box-sizing: border-box;
    }

    .real_name {
      margin-top: .75rem;
      display: flex;
      flex-wrap: wrap;

      .sub_title {
        width: 100%;
      }

      li {
        height: auto;
        width: 50%;
        flex: 1;
        background-color: transparent;
        text-align: left;

        &:nth-child(1) {
          margin-left: 0;
        }

        p:nth-child(1) {
          font-size: .19rem;
          line-height: .30rem;
          color: var(--dark1);
          font-family: "Regular";
          text-indent: .10rem;
        }

        p:nth-child(2) {
          width: 100%;
          height: 0.65rem;
          font-size: .20rem;
          line-height: .65rem;
          color: var(--dark1);
          font-family: "Regular";
          padding: 0 .40rem;
          border-radius: .1rem;
          background-color: var(--gray5);
          box-sizing: border-box;
          display: block;
        }
      }
    }

    .country {
      margin-top: .75rem;

      >p {
        font-size: .30rem;
        line-height: 1.26rem;
        color: var(--white);
        font-family: "Regular";
      }

      li {
        width: 100%;
        margin: 0;
      }
    }

    .language {
      margin-top: .75rem;

      >p {
        width: 100%;
        font-size: 0.2rem;
        line-height: 0.65rem;
        margin: 0 auto;
        color: var(--dark1);
        font-family: "Regular";
        background-color: var(--gray5);
        border-radius: 0.08rem;
      }
    }
  }

  .discord {
    margin-top: .9rem;
    text-align: center;

    input {
      display: block;
      width: 100%;
      height: .70rem;
      margin: 0 auto 0;
      padding: 0 .90rem;
      font-size: .20rem;
      line-height: .70rem;
      color: var(--dark1);
      text-align: left;
      font-family: "Regular";
      border: .03rem transparent solid;
      border-radius: .10rem;
      box-sizing: border-box;
      background: none;
      outline: none;
      background: var(--gray5);
    }

    input.active {
      border: .03rem var(--yellow13) solid;
    }

    .discode_input {
      position: relative;
      width: 5.60rem;
      height: .70rem;
      margin: .40rem auto 0;
    }

    .discode_icon {
      position: absolute;
      width: .33rem;
      height: .24rem;
      left: .37rem;
      top: 50%;
      margin-top: -.10rem;
      background: url(~@/assets/images/discord_icon.png) no-repeat center/contain;
    }

    .gou {
      display: none;
      position: absolute;
      width: .35rem;
      height: .35rem;
      border-radius: 50%;
      top: 50%;
      right: .37rem;
      margin-top: -.18rem;
      background: var(--yellow13) url(~@/assets/images/dagou.png) no-repeat center center;
      background-size: .18rem auto;
    }

    .active+.gou {
      display: block;
    }
  }

  .submit {
    position: relative;
    width: 3.65rem;
    height: 1.00rem;
    margin: .60rem auto 0;
    font-size: .26rem;
    line-height: 1.02rem;
    color: var(--white);
    font-family: "Bold";
    background: url(~@/assets/images/btn7.png) no-repeat top/contain;
    background-size: 100% 100%;
    cursor: pointer;
    text-align: center;
  }
}