:root {
  --white: #fff;
  --black1: #000;
  --black2: #252525;
  --black5: #232323;
  --black7: #2b2b2b;
  --black14: #333;
  --black3: #424242;
  --black4: #5b5b5b;
  --black6: #595959;
  --black8: #615650;
  --black9: #635757;
  --black10: #585753;
  --black11: #454545;
  --black12: #525252;
  --black13: #5d4436;
  --black15: #716561;
  --black16: #624f47;
  --red1: #f00;
  --red2: #f15455;
  --red3: #e55d5d;
  --red4: #f6632f;
  --red5: #d73e3a;
  --red6: #f33636;
  --red7: #ff5c2f;
  --green1: #52c41a;
  --green2: #7ad64c;
  --green3: #3dcc80;
  --green4: #51ba45;
  --green5: #86e155;
  --green6: #5bd3b7;
  --green7: #4fd588;
  --green8: #0a7d7b;
  --green9: #09efc1;
  --green10: #71a977;
  --dark1: #444444;
  --dark2: #2e2e2e;
  --dark3: #4d4d4d;
  --yellow1: #f0a32e;
  --yellow2: #ff7c00;
  --yellow3: #f0a22e;
  --yellow4: #ed952e;
  --yellow5: #be7a15;
  --yellow6: #f6b92e;
  --yellow7: #f0a71a;
  --yellow8: #faad14;
  --yellow9: #c7813f;
  --yellow10: #a86a2f;
  --yellow11: #ffde71;
  --yellow12: #efb537;
  --yellow13: #f6ba2f;
  --yellow14: #b75208;
  --yellow15: #e09923;
  --yellow16: #f6b92f;
  --yellow17: #f7a934;
  --yellow18: #f1a32e;
  --yellow19: #ec902e;
  --yellow20: #efa03c;
  --yellow21: #ed942e;
  --yellow22: #cf8607;
  --yellow23: #bf966e;
  --yellow24: #955e2a;
  --yellow25: #f0a932;
  --yellow26: #ffcf74;
  --yellow27: #fdc251;
  --yellow28: #ffc75c;
  --yellow29: #9e7423;
  --yellow30: #fdda4a;
  --yellow31: #ffe17f;
  --yellow32: #cba972;
  --yellow33: #c4a473;
  --yellow34: #ceb381;
  --yellow35: #fb9e39;
  --yellow36: #9e622a;
  --yellow37: #9c773a;
  --yellow38: #c29e68;
  --yellow39: #ccaa73;
  --yellow40: #efa518;
  --yellow41: #f2d56d;
  --yellow42: #da6527;
  --pruple1: #9886f2;
  --pruple2: #7a56dc;
  --pruple3: #9578e3;
  --gray1: #919191;
  --gray2: #c8c8c8;
  --gray3: #585858;
  --gray4: #d7d7d7;
  --gray5: #f2f2f2;
  --gray6: #cfcfcf;
  --gray7: #464646;
  --gray8: #a3a3a3;
  --gray9: #8b8b8b;
  --gray10: #ababab;
  --gray11: #b1b1b1;
  --gray12: #a9a9a9;
  --gray13: #636363;
  --gray14: #828282;
  --gray15: #9f9f9f;
  --gray16: #e5e5e5;
  --gray17: #b9b9b9;
  --gray18: #aeaeae;
  --gray19: #dedede;
  --gray20: #b6b6b6;
  --gray21: #bfbfbf;
  --gray22: #eaeaea;
  --gray23: #bbbbbb;
  --gray24: #dadada;
  --gray25: #bebebe;
  --gray26: #f1f1f1;
  --gray27: #f6f6f6;
  --gray28: #e3e3e3;
  --gray29: #c9c9c9;
  --gray30: #aaaaaa;
  --gray31: #5e5e5e;
  --gray32: #999999;
  --gray33: #ebe4d2;
  --gray34: #bfb1a1;
  --gray35: #8b8685;
  --gray36: #e2dfdb;
  --gray37: #6b6b6b;
  --gray38: #686868;
  --gray39: #757575;
  --gray40: #6b6961;
  --gray41: #f6e1c8;
  --gray42: #9e9e9e;
  --gray43: #e4e4e4;
  --gray44: #635955;
  --gray45: #7c7978;
  --gray46: #606060;
  --gray47: #848282;
  --gray48: #757589;
  --gray49: #f2f1e2;
  --gray50: #ffffee;
  --gray51: #dfdec8;
  --gray52: #b1ae9e;
  --gray53: #342c2a;
  --blue1: #50c4e1;
  --blue2: #54a9cc;
  --blue3: #57ace8;
  --blue4: #5ebfd7;
  --blue5: #1890ff;
  --blue6: #73aee6;
}