.gamefeed_box {
  position: relative;
  width: 100%;
  height: auto;
  padding: .4rem .44rem;
  margin: .4rem auto;
  box-sizing: border-box;
  background-color: var(--white);
  border-radius: .08rem;

  .title1 {
    font-size: .26rem;
    line-height: .26rem;
    color: var(--black4);
    font-family: "Black";
    text-align: left;

    &.vi {

      &+.query {
        width: 2.8rem;
      }
    }
  }

  .query {
    position: absolute;
    right: .44rem;
    top: .3rem;
    width: 3.2rem;

    input {
      display: block;
      width: 100%;
      height: .46rem;
      line-height: .46rem;
      font-size: .18rem;
      color: var(--black3);
      font-family: "Regular";
      padding: 0 .56rem 0 .2rem;
      box-sizing: border-box;
      border-radius: .46rem;
      border: 1px #737373 solid;
      background: none;
    }

    i {
      position: absolute;
      width: .23rem;
      height: .23rem;
      right: .20rem;
      top: 50%;
      margin-top: -.11rem;
      cursor: pointer;
      background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAXCAMAAADX9CSSAAAApVBMVEUAAAA7Ozs9PT08PDw8PDw8PDw7Ozs9PT09PT08PDwyMjI6Ojo/Pz89PT09PT0+Pj48PDw5OTk8PDw9PT09PT01NTU8PDw6Ojo+Pj4+Pj45OTk8PDw9PT06OjpAQEA8PDw9PT06OjpEREQ8PDw+Pj46Ojo5OTksLCwzMzMuLi45OTlBQUE+Pj4+Pj43Nzc4ODg+Pj4+Pj42NjY9PT0+Pj5AQEBEREROboehAAAAM3RSTlMAG/z36scY2dTDEAjoq6aak4Ytt4t8V0RANCQiFQv75uLVz8y/s7OqnJSQjHVtZFNPPSQtBXkYAAAA1UlEQVQoz3WPV3LDMBBDWUR1R8VxSWyn954A1P2PlhGlKKTHxgex80AOscJJLoyGzt5iEeiUBNAfjUfzG8t6tS3iVQXe5RNPOiPH8Tzl7R+ubOY9Tu3LeIUXhcelwvD5U9eEHezCuWZY7ozXznkpQinsekO2xyNKx5M9rpk7ziLAP4ycz/kR8HdbO1+jDHiJdhhM9+zhx+5+nDbKvk64tvp/E8X0q++wXSYEqinYGBJplgCYr68wE5OWDyCpZp9CyNIPxK79bodF4ggn4pDk8YBHAtP8AuK3E9qVr3m9AAAAAElFTkSuQmCC) no-repeat center/contain;
    }
  }

  .gamefeed_list {
    width: 100%;
    height: auto;
    margin-top: .24rem;
    padding-bottom: .50rem;
    box-sizing: border-box;
    text-align: left;

    li {
      position: relative;
      width: 100%;
      height: 1.56rem;
      padding: .24rem 0;
      box-sizing: border-box;
      border-bottom: 1px var(--gray19) solid;
    }

    .img_box {
      float: left;
      height: 1.1rem;
      width: 2.07rem;
      margin-right: .36rem;
      border-radius: .1rem;
      overflow: hidden;
      border: 1px var(--black1) solid;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .title {
      display: inline-block;
      width: 3.25rem;
      height: .4rem;
      height: auto;
      font-size: .2rem;
      line-height: .24rem;
      color: var(--black3);
      font-family: "Regular";
      padding-top: .08rem;
      margin-bottom: .3rem;
      box-sizing: border-box;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }

    .time {
      font-size: .18rem;
      line-height: .2rem;
      color: var(--gray11);
      font-family: "Regular";
    }

    .more {
      position: absolute;
      right: .24rem;
      bottom: .2rem;
      width: .35rem;
      height: .35rem;
      background-color: var(--gray19);
      border-radius: .05rem;
      cursor: pointer;

      &::before,
      &::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        background-color: var(--white);
      }

      &::before {
        width: .02rem;
        height: .20rem;
        margin-top: -.10rem;
        margin-left: -.01rem;
      }

      &::after {
        width: .20rem;
        height: .02rem;
        margin-top: -.01rem;
        margin-left: -.10rem;
      }
    }

    .none_tip {
      width: 100%;
      font-size: .50rem;
      line-height: 1rem;
      margin-top: .60rem;
      text-align: center;
      color: var(--gray11);
      font-family: "Bold";
      user-select: none;
    }
  }

  .page {
    position: relative;
    width: 100%;
    text-align: center;
    font-size: 0;

    span {
      font-size: .22rem;
      line-height: .22rem;
      color: var(--black4);
      font-family: "Bold";
      margin: 0 .18rem;
      cursor: pointer;
    }

    span.active {
      color: var(--yellow3);
      text-decoration: underline;
      pointer-events: none;
    }

    li {
      width: auto;
      border: none !important;
    }
  }
}