.module_title {
  font-size: .50rem;
  line-height: .90rem;
  color: var(--yellow12);
  text-transform: uppercase;
  font-family: "Black";
}

.module_desc {
  font-size: .30rem;
  line-height: .64rem;
  color: var(--dark1);
  font-family: "Regular";

  span {
    color: var(--yellow12);
    font-family: "Black";
  }
}

.main_box {
  width: 15.74rem;
  border-radius: .10rem;
  box-sizing: border-box;
  margin: .85rem auto 0;
  padding: .60rem 0;
  background-color: var(--gray26);

  ul {
    width: 100%;
    margin-top: .45rem;
    text-align: center;
    font-size: 0;
    line-height: 0;
  }

  li {
    display: inline-block;
    width: 3.64rem;
    height: .88rem;
    margin: 0 .15rem;
    font-size: .26rem;
    line-height: .88rem;
    font-family: "Bold";
    border-radius: .15rem;
    cursor: pointer;
  }
}

.video_platform {
  @extend .main_box;
  height: 9.75rem;
  margin: .25rem auto 0;
  user-select: none;

  li {
    position: relative;
    color: var(--black1);
    border: .03rem var(--blue2) solid;
  }

  li.active {
    border-color: var(--yellow19);
  }

  .icon {
    display: inline-block;
    width: .56rem;
    height: .43rem;
    line-height: .88rem;
    margin-right: .10rem;
    vertical-align: middle;
    background: url(~@/assets/images/video_logo.png) no-repeat top/cover;
  }

  .icon1 .media_logo {
    @extend .icon;
    background-position: top;
    margin-right: .20rem;
  }

  .icon2 .media_logo {
    @extend .icon;
    background-position: center;
  }

  .icon3 .media_logo {
    @extend .icon;
    background-position: bottom;
  }

  .check {
    position: absolute;
    width: .30rem;
    height: .30rem;
    right: .30rem;
    top: 50%;
    margin-top: -.15rem;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB8AAAAfCAMAAAAocOYLAAAAw1BMVEUAAACfn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5+fn5////+dnZ2WlpaYmJicnJybm5v9/f309PSSkpLKysrAwMC1tbWlpaX4+Pjv7+/n5+fOzs7FxcWtra2hoaHh4eHY2NjU1NSurq6qqqrq6uq5ubn2Hls3AAAAJXRSTlMAyePU9vC1p2UzKw/fnIwmC/nrgXFfPATb2c24l4lqThgWxHZGOe+0BQAAAVdJREFUKM91k+d6gjAUQC9DQBRwb6u2zQ0BFMQ92/d/qhYCiILnD4HzcVcSyKj12sqwVREFo2tBgcaHQDKak+qL/v4kTzTNWl53muQVQX/oKSmhnuX4IqVIc657MilH4X1J5B1a5DVSgDqUxiU0AGqjgnZdf3+icYA+9AraWfmIRxZXoEO7ENw+eLg7OdFa7ICSfKWpj/RPyPiLCsNEUyf5O/Bwe7fTKUKL68M1dGP9u8HtjaXRBlCJHuy4wYvr/Ov1EpdrRlJEEOOS6QXx6jrsxnWGBAJv2Tkj7u37FjfrFX34MRh8wU47RP+MXpDXsgldwmHhDj3EwOaaI83AktO+w2WUguQxFtCfZIM5+oFD81qOjkg12362ynKn04swyRsqFj8gwhvfBY4uluo2pOhSiZ7Cg4X6alsa5Olr9afKVKt4ARUxkSOjCkUaekcVBnVpbM7mkPEHyu1r7tVxUG4AAAAASUVORK5CYII=) no-repeat center/contain;
  }

  .check.active {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB8AAAAfCAMAAAAocOYLAAAA7VBMVEUAAAAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wAA/wD/+v8j/yMO/w4G/wby+/Lq+urI/Mhh/WE5/jkd/x3b+9u6+7qR/ZGA/YB6/Xpl/WXi++Li+uLT+9Ou+66d/J2b/JuC/YJv/W9u/G5a/VpZ/VlQ/lBC/UIu/i4Y/xi4JdcUAAAAL3RSTlMA1LfiymX08N+mijImDfnrmnFfPCoQ9tvZzcedjYBqLBgWCgTkxK+slIR2UE1GNw6xnh0AAAE0SURBVCjPddKHboNADIBhMwMJZDd7p3vZCdnN6N7t+z9OkzugjOOT0Fn6AYFk8B2V2nItl1IazaIFMXZHRV/2/DaSb04wJJu2g7mQxSi1C740ChgZL1+ikNTjuaSj2Cn/LwmTaIeuYSLDBrDrgvC2cfgLhlAS5C3RiA1yGdrxvCOajdmkFECO5TnLnAm1aP4gelyiS4UcO53d2n3ka0KrH/RUIcXO0YSe2bCY0nSBPgUUdo5fiNaH++5DGSVoIOM8Eb3ickWTbwyQoYmcc0e03V+fway3oIiu3xntzTFIugKrgq7lA9EGQ/J9gDx6Ru/zcK4cViSjYxLT2y6xlMUXREWxInDlY2G+AE9XEuQ0/OufRWtOg6ChZoS+zLQgYtCRFR71ej4DcYNywVSrhiS3rnvg+wMubYWnXJT5ZQAAAABJRU5ErkJggg==) no-repeat center/contain;
  }

  .platform_upload {
    position: relative;
    width: 11.50rem;
    height: 4.55rem;
    margin: .70rem auto 0;
    border-radius: .10rem;
    background-color: var(--white);
    padding: .25rem;
    box-sizing: border-box;
    text-align: left;

    >span>div>div:last-child {
      border: none;
      background: none;
    }

    .upload_default {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }

    .upload_icon {
      position: absolute;
      top: 50%;
      left: 50%;
      background-color: var(--gray10);
      border-radius: .14rem
    }

    .upload_icon1 {
      @extend .upload_icon;
      width: 1.00rem;
      height: .14rem;
      margin-top: -.47rem;
      margin-left: 1rem
    }

    .upload_icon1.center {
      margin-left: -0.5rem
    }

    .upload_icon2 {
      @extend .upload_icon;
      width: .14rem;
      height: 1.00rem;
      margin-top: -.90rem;
      margin-left: 1.43rem
    }

    .upload_icon2.center {
      margin-left: -0.07rem
    }

    .img_box {
      position: absolute;
      width: 3rem;
      height: 1.6rem;
      top: 50%;
      left: 50%;
      margin-top: -1.3rem;
      margin-left: -3rem;
      box-sizing: border-box;
      padding: 0.16rem 0.2rem;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: transform 0.7s;
      }

      .border1, .border2 {
        position: absolute;
        top: 0;
        width: 0.25rem;
        height: 0.2rem;
        border-top: 2px var(--gray10) solid;
      }

      .border3, .border4 {
        position: absolute;
        bottom: 0;
        width: 0.25rem;
        height: 0.2rem;
        border-bottom: 2px var(--gray10) solid;
      }

      .border1, .border3 {
        left: 0;
        border-left: 2px var(--gray10) solid;
      }

      .border2, .border4 {
        right: 0;
        border-right: 2px var(--gray10) solid;
      }
    }

    .upload_media_icon {
      @extend .icon;
      position: absolute;
      top: 2.8rem;
      left: 50%;
      margin-left: -.28rem;
      white-space: nowrap;
      line-height: .43rem;
      text-indent: .7rem;
      margin-left: -.8rem
    }
    
    .youtubeIcon {
      @extend .upload_media_icon;
      background-position: top;
    }
    
    .tiktokIcon {
      @extend .upload_media_icon;
      background-position: center;
    }
    
    .twitchIcon {
      @extend .upload_media_icon;
      background-position: bottom;
    }
    
    p {
      position: absolute;
      width: 100%;
      top: 3.50rem;
      left: 0;
      font-size: .26rem;
      color: var(--gray11);
      font-family: "Regular";
      text-align: center;
    }

    .upload_action {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      opacity: 0;
      z-index: 5;
      cursor: pointer;
    }
  }
}

:global {
  .application-media {

    .ant-upload-picture-card-wrapper,
    .ant-upload-list {
      height: 100%;
      text-align: center;
    }

    .ant-upload-list-picture-card-container {
      width: 4.08rem;
      height: 4.08rem;
      margin: 0.1rem 0;
    }

    .ant-upload-list-item-info::before {
      left: 0;
    }

    .ant-upload-list-item-actions {
      transform: scale(2) translate(-50%, -50%);

      >a {
        display: none;
      }
    }

    .ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-delete {
      margin: 0 auto;
    }

    .ant-upload {
      width: 0;
    }

    .ant-upload-list-picture-card-container+.ant-upload {
      display: none;
    }
  }
}